import React from 'react';
import { App } from '../../app';
import { ProductSubcategory, ProductCategory, ProductTab } from '../../sdk/product.sdk';
import { Button } from '../Button';
import { List } from '../List';
import { MProductSubcategory } from '../modals/MProductSubcategory';
import { sleep } from '../../lib/utils.lib';

interface P {
  app: App;
  match: any;
}

interface S {
  items: ProductSubcategory[];
  categories: ProductCategory[];
  productTabs: ProductTab[];
  category?: ProductCategory;
  search: string;
}

export class PProductSubcategories extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      categories: [],
      productTabs: [],
      category: props.app.loadFromCache('category'),
      search: '',
    };
  }

  render() {
    return (
      <div className="PProductSubcategories">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    this.setupPage();
    await this.refreshData();
    await sleep();
    this.setupPage();
  }

  // event handlers

  async onAdd() {
    const { app } = this.props;
    const { categories, productTabs } = this.state;
    await app.showModal(MProductSubcategory, { categoryId: this.getCategoryId(), categories, productTabs });
    await this.refreshData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(subcategory: ProductSubcategory) {
    const { app } = this.props;
    const { categories, productTabs } = this.state;
    const categoryId = this.getCategoryId();
    return [
      {
        name: 'Перейти к товарам',
        action: () => this.runPrimaryAction(subcategory),
      },
      {
        name: 'Редактировать',
        action: async () => {
          await app.showModal(MProductSubcategory, { categoryId, subcategory, categories, productTabs });
          await this.refreshData();
        },
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить подкатегорию "${subcategory.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            const sdk = app.getSdk();
            await sdk.deleteProductSubcategory(subcategory.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(subcategory: ProductSubcategory) {
    await this.runPrimaryAction(subcategory);
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        buttons={this.getMoveButtons()}
        onGetItemMenu={(item) => this.onGetItemMenu(item)}
        onGetItemClassName={(item) => this.getItemClassName(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'Подкатегория',
        value: (item: ProductSubcategory) => item.name,
      },
    ];
  }

  getMoveButtons() {
    const { app } = this.props;
    const sdk = app.getSdk();
    return [
      {
        icon: 'arrow-up',
        action: async (subcategory: ProductSubcategory) => {
          try {
            await sdk.moveProductSubcategoryUp(subcategory.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
      {
        icon: 'arrow-down',
        action: async (subcategory: ProductSubcategory) => {
          try {
            await sdk.moveProductSubcategoryDown(subcategory.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  getItemClassName(subcategory: ProductSubcategory) {
    return subcategory.is_hidden ? 'text-black-50' : '';
  }

  async runPrimaryAction(subcategory: ProductSubcategory) {
    const { app } = this.props;
    app.getHistory().push(`/catalog/${this.getCategoryId()}/subcategories/${subcategory.id}/products`);
    app.saveToCache('subcategory', subcategory);
  }

  getCategoryId() {
    const { match } = this.props;
    return Number(match.params.categoryId);
  }

  setupPage() {
    const { app } = this.props;
    const { category } = this.state;
    const pageTitle = category ? category.name : '';
    app.setupPage(pageTitle, 'catalog');
    app.updateUI();
  }

  async refreshData() {
    const { app } = this.props;
    const categoryId = this.getCategoryId();
    const sdk = app.getSdk();
    try {
      const items = await sdk.getProductSubcategories({ category_id: categoryId });
      const category = await sdk.getProductCategory(categoryId);
      const categories = await sdk.getProductCategories();
      const productTabs = await sdk.getProductTabs();
      this.setState({ items, category, categories, productTabs });
    } catch (err) {
      app.handleError(err);
    }
  }
}
