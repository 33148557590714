import { Api } from '../services/api.service';

export interface Product {
  id: number;
  name: string;
  price: number | null;
  prices_by_qty: { [key: string]: number };
  prices_by_execution_time: { [key: string]: number };
  production_days: number;
  redirect_url: string;
  color_name: string | null;
  color_value: string | null;
  is_hidden: boolean;
  is_special_price: boolean;
  is_prepayment: boolean;
  is_yandex_market: boolean;
  is_google_market: boolean;
  details: ProductDetails;
  yandex_market: ProductYandexMarket;
  google_market: ProductGoogleMarket;
  image_media_id: number | null;
  image_media_name: string | null;
  subcategory_id: number;
  subcategory_name: string;
}

export interface ProductCategory {
  id: number;
  name: string;
  slug: string;
  redirect_url: string;
  is_hidden: boolean;
  is_major: boolean;
  details: ProductCategoryDetails;
  image_id: number | null;
  image_name: string | null;
  image_size: number | null;
}

export interface ProductSubcategory {
  id: number;
  name: string;
  slug: string;
  unit: string;
  price_type: PriceType;
  price_scale: PriceScale;
  redirect_url: string;
  display_price: number | null;
  display_price_crossed: number | null;
  is_hidden: boolean;
  is_major: boolean;
  is_nova: boolean;
  is_leader: boolean;
  in_stock: boolean;
  details: ProductSubcategoryDetails;
  texts: { [key: string]: string };
  category_id: number;
  category_name: string;
}

export interface ProductImage {
  id: number;
  subcategory_id: number;
  alt: string;
  caption: string;
  dimensions: string;
  is_primary: boolean;
  media_id: number;
  media_hash: string;
  media_name: string;
  media_size: number;
}

export interface ProductRef {
  id: number;
  subcategory_id: number;
  ref_subcategory_id: number;
  ref_subcategory_name: string;
}

export enum PriceType {
  simple = 'simple',
  by_qty = 'by_qty',
  by_execution_time = 'by_execution_time',
}

export enum PriceScale {
  empty = '',
  scale_100 = 'scale_100',
  scale_1000 = 'scale_1000',
  scale_10000 = 'scale_10000',
}

export interface ProductDetails {
  cart_prompt?: string;
  cart_text?: string;
  cart_files?: string;
}

export interface ProductYandexMarket {
  name?: string;
  description?: string;
  pickup_days?: number;
  store?: boolean;
}

export interface ProductGoogleMarket {
  name?: string;
  description?: string;
}

export interface ProductCategoryDetails {
  description?: string;
  seo_title?: string;
  seo_h1?: string;
  seo_description?: string;
}

export interface ProductSubcategoryDetails {
  seo_title?: string;
  seo_h1?: string;
  seo_description?: string;
}

export interface ProductTab {
  id: number;
  code: string;
  name: string;
  sort_index: number;
  created_at: string;
  updated_at: string;
}

export interface ProductSdk {
  getProducts(query?: ProductQuery): Promise<Product[]>;
  getProduct(id: number): Promise<Product>;
  createProduct(data: CreateProductData): Promise<Product>;
  updateProduct(id: number, data: UpdateProductData): Promise<Product>;
  deleteProduct(id: number): Promise<void>;
  moveProductUp(id: number): Promise<void>;
  moveProductDown(id: number): Promise<void>;
  getProductCategories(): Promise<ProductCategory[]>;
  getProductCategory(id: number): Promise<ProductCategory>;
  createProductCategory(data: CreateProductCategoryData): Promise<ProductCategory>;
  updateProductCategory(id: number, data: UpdateProductCategoryData): Promise<ProductCategory>;
  deleteProductCategory(id: number): Promise<void>;
  moveProductCategoryUp(id: number): Promise<void>;
  moveProductCategoryDown(id: number): Promise<void>;
  updateProductCategoryImage(id: number, data: UpdateProductCategoryData): Promise<ProductCategory>;
  deleteProductCategoryImage(id: number): Promise<ProductCategory>;
  getProductSubcategories(query?: ProductSubcategoryQuery): Promise<ProductSubcategory[]>;
  getProductSubcategory(id: number): Promise<ProductSubcategory>;
  createProductSubcategory(data: CreateProductSubcategoryData): Promise<ProductSubcategory>;
  updateProductSubcategory(id: number, data: UpdateProductSubcategoryData): Promise<ProductSubcategory>;
  deleteProductSubcategory(id: number): Promise<void>;
  moveProductSubcategoryUp(id: number): Promise<void>;
  moveProductSubcategoryDown(id: number): Promise<void>;
  getProductImages(query?: ProductImageQuery): Promise<ProductImage[]>;
  getProductImage(id: number): Promise<ProductImage>;
  updateProductImages(subcategoryId: number, data: UpdateProductImageData[]): Promise<void>;
  getProductRefs(query?: ProductRefQuery): Promise<ProductRef[]>;
  updateProductRefs(subcategoryId: number, data: UpdateProductRefData): Promise<void>;
  getProductTabs(): Promise<ProductTab[]>;
  getProductTab(id: number): Promise<ProductTab>;
  createProductTab(data: CreateProductTabData): Promise<ProductTab>;
  updateProductTab(id: number, data: UpdateProductTabData): Promise<ProductTab>;
  deleteProductTab(id: number): Promise<void>;
  moveProductTabUp(id: number): Promise<void>;
  moveProductTabDown(id: number): Promise<void>;
}

interface CreateProductData {
  name: string;
  price?: number | null;
  prices_by_qty?: { [key: string]: number };
  prices_by_execution_time?: { [key: string]: number };
  production_days?: number;
  redirect_url?: string;
  color_name?: string | null;
  color_value?: string | null;
  is_hidden?: boolean;
  is_special_price?: boolean;
  is_yandex_market?: boolean;
  details?: ProductDetails;
  yandex_market?: ProductYandexMarket;
  image_media_id?: number | null;
  subcategory_id: number;
}

interface UpdateProductData {
  name?: string;
  price?: number | null;
  prices_by_qty?: { [key: string]: number };
  prices_by_execution_time?: { [key: string]: number };
  production_days?: number;
  redirect_url?: string;
  color_name?: string | null;
  color_value?: string | null;
  is_hidden?: boolean;
  is_special_price?: boolean;
  is_yandex_market?: boolean;
  details?: ProductDetails;
  yandex_market?: ProductYandexMarket;
  image_media_id?: number | null;
  subcategory_id?: number;
}

interface CreateProductCategoryData {
  name: string;
  slug: string;
  redirect_url?: string;
  is_hidden?: boolean;
  is_major?: boolean;
  details?: ProductCategoryDetails;
}

interface UpdateProductCategoryData {
  name?: string;
  slug?: string;
  redirect_url?: string;
  is_hidden?: boolean;
  is_major?: boolean;
  media_id?: number;
  media_hash?: string;
  details?: ProductCategoryDetails;
}

interface ProductQuery {
  id?: number;
  subcategory_id?: number;
  is_hidden?: boolean;
}

interface ProductSubcategoryQuery {
  category_id?: number;
  is_hidden?: boolean;
}

interface ProductRefQuery {
  subcategory_id?: number;
}

interface CreateProductSubcategoryData {
  name: string;
  slug: string;
  unit: string;
  price_type: PriceType;
  redirect_url?: string;
  display_price?: number | null;
  display_price_crossed?: number | null;
  is_hidden?: boolean;
  is_major?: boolean;
  is_nova?: boolean;
  is_leader?: boolean;
  in_stock?: boolean;
  details?: ProductSubcategoryDetails;
  category_id: number;
}

interface UpdateProductSubcategoryData {
  name?: string;
  slug?: string;
  unit?: string;
  price_type?: PriceType;
  redirect_url?: string;
  display_price?: number | null;
  display_price_crossed?: number | null;
  is_hidden?: boolean;
  is_major?: boolean;
  is_nova?: boolean;
  is_leader?: boolean;
  in_stock?: boolean;
  details?: ProductSubcategoryDetails;
  category_id?: number;
}

interface ProductImageQuery {
  subcategory_id?: number;
  is_primary?: boolean;
}

interface UpdateProductImageData {
  alt: string;
  caption: string;
  dimensions: string;
  media_id: number;
  media_hash: string;
  is_primary: boolean;
}

interface UpdateProductRefData {
  ids: number[];
}

interface CreateProductTabData {
  code?: string;
  name: string;
}

interface UpdateProductTabData {
  name?: string;
}

export function createProductSdk(api: Api): ProductSdk {
  return {
    async getProducts(query) {
      const data = await api.get('/product', query);
      return data.products;
    },
    async getProduct(id) {
      const data = await api.get(`/product/${id}`);
      return data.product;
    },
    async createProduct(data) {
      const result = await api.post('/product', data);
      return result.product;
    },
    async updateProduct(id, data) {
      const result = await api.patch(`/product/${id}`, data);
      return result.product;
    },
    async deleteProduct(id) {
      await api.delete(`/product/${id}`);
    },
    async moveProductUp(id) {
      await api.post(`/product/${id}/up`);
    },
    async moveProductDown(id) {
      await api.post(`/product/${id}/down`);
    },
    async getProductCategories() {
      const data = await api.get('/product/category');
      return data.product_categories;
    },
    async getProductCategory(id) {
      const data = await api.get(`/product/category/${id}`);
      return data.product_category;
    },
    async createProductCategory(data) {
      const result = await api.post('/product/category', data);
      return result.product_category;
    },
    async updateProductCategory(id, data) {
      const result = await api.patch(`/product/category/${id}`, data);
      return result.product_category;
    },
    async updateProductCategoryImage(id, data) {
      const result = await api.put(`/product/category/${id}/image`, data);
      return result.product_category;
    },
    async deleteProductCategory(id) {
      await api.delete(`/product/category/${id}`);
    },
    async moveProductCategoryUp(id) {
      await api.post(`/product/category/${id}/up`);
    },
    async moveProductCategoryDown(id) {
      await api.post(`/product/category/${id}/down`);
    },
    async deleteProductCategoryImage(id) {
      const result = await api.delete(`/product/category/${id}/image`);
      return result.product_category;
    },
    async getProductSubcategories(query) {
      const data = await api.get('/product/subcategory', query);
      return data.product_subcategories;
    },
    async getProductSubcategory(id) {
      const data = await api.get(`/product/subcategory/${id}`);
      return data.product_subcategory;
    },
    async createProductSubcategory(data) {
      const result = await api.post('/product/subcategory', data);
      return result.product_subcategory;
    },
    async updateProductSubcategory(id, data) {
      const result = await api.patch(`/product/subcategory/${id}`, data);
      return result.product_subcategory;
    },
    async deleteProductSubcategory(id) {
      await api.delete(`/product/subcategory/${id}`);
    },
    async moveProductSubcategoryUp(id) {
      await api.post(`/product/subcategory/${id}/up`);
    },
    async moveProductSubcategoryDown(id) {
      await api.post(`/product/subcategory/${id}/down`);
    },
    async getProductImages(query) {
      const data = await api.get('/product/image', query);
      return data.product_images;
    },
    async getProductImage(id) {
      const data = await api.get(`/product/image/${id}`);
      return data.product_image;
    },
    async updateProductImages(subcategoryId, data) {
      await api.put(`/product/subcategory/${subcategoryId}/images`, data);
    },
    async getProductRefs(query) {
      const data = await api.get('/product/ref', query);
      return data.product_refs;
    },
    async updateProductRefs(subcategoryId, data) {
      await api.put(`/product/subcategory/${subcategoryId}/refs`, data);
    },
    async getProductTabs() {
      const data = await api.get('/product/tab');
      return data.product_tabs;
    },
    async getProductTab(id) {
      const data = await api.get(`/product/tab/${id}`);
      return data.product_tabs;
    },
    async createProductTab(data) {
      const result = await api.post('/product/tab', data);
      return result.product_tabs;
    },
    async updateProductTab(id, data) {
      const result = await api.patch(`/product/tab/${id}`, data);
      return result.product_tabs;
    },
    async deleteProductTab(id) {
      await api.delete(`/product/tab/${id}`);
    },
    async moveProductTabUp(id) {
      await api.post(`/product/tab/${id}/up`);
    },
    async moveProductTabDown(id) {
      await api.post(`/product/tab/${id}/down`);
    },
  };
}
