import React from 'react';

import { App } from '../../app';
import { cn } from '../../lib/utils.lib';
import { Order, OrderType, PaymentType } from '../../sdk/order.sdk';
import { createForm, Form } from '../../services/form.service';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

import {
  formatDate,
  formatDeliveryTypeShort,
  formatMoney,
  formatPaymentStatus,
  formatPaymentTypeShort,
  formatTime,
} from '../../services/fmt.service';

import './MOrder.css';

interface P {
  app: App;
  order: Order;
  close: () => void;
}

interface S {
  form: Form;
}

export class MOrder extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      form: this.initForm(props.order),
    };
  }

  render() {
    const { order } = this.props;
    const className = cn(
      'MOrder modal-dialog modal-lg',
      order.payment_type === PaymentType.online && '_withPaymentUrl',
    );
    return (
      <div className={className}>
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
        </div>
      </div>
    );
  }

  // render helpers

  renderHeader() {
    const { close, order } = this.props;
    const title =
      order.type === OrderType.regular
        ? `Заказ # ${order.display_id}`
        : `Коммерческое предложение # ${order.display_id}`;
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { order } = this.props;
    const { form } = this.state;
    return (
      <div className="modal-body">
        <div className="row">
          <FormGroup className="col-4" type="text" name="date" label="Дата" form={form} readOnly />
          <FormGroup className="col-4" type="text" name="time" label="Время" form={form} readOnly />
          <FormGroup className="col-4" type="text" name="total" label="Сумма, ₽" form={form} readOnly />
        </div>
        <div className="row">
          <FormGroup
            className={order.type === OrderType.regular ? 'col-4' : 'col-12'}
            type="text"
            name="delivery_type"
            label="Способ доставки"
            form={form}
            readOnly
          />
          {order.type === OrderType.regular && (
            <React.Fragment>
              <FormGroup className="col-4" type="text" name="payment_type" label="Способ оплаты" form={form} readOnly />
              <FormGroup
                className="col-4"
                type="text"
                name="payment_status"
                label="Статус оплаты"
                form={form}
                readOnly
              />
            </React.Fragment>
          )}
        </div>
        <FormGroup className="mb-0" type="textarea" name="text" form={form} readOnly />
        {order.payment_type === PaymentType.online && (
          <div className="mt-3">
            <a target="_blank" rel="noreferrer noopener" href={order.payment_url}>
              Ссылка на страницу оплаты для покупателя
            </a>
          </div>
        )}
      </div>
    );
  }

  // other helpers

  initForm(order: Order) {
    return createForm({
      date: formatDate(order.sent_at),
      time: formatTime(order.sent_at),
      total: formatMoney(order.total, true),
      delivery_type: formatDeliveryTypeShort(order.delivery_type),
      payment_type: formatPaymentTypeShort(order.payment_type),
      payment_status: formatPaymentStatus(order.payment_status) || '—',
      text: order.text,
    });
  }
}
