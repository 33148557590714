import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { cn } from '../lib/utils.lib';

interface P {
  type: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link';
  text?: string;
  icon?: IconProp;
  className?: string;
  disabled?: boolean;
  dataDismiss?: string;
  title?: string;
  onClick?: () => void;
}

export class Button extends React.Component<P> {
  render() {
    const { type, text, icon, className, disabled, dataDismiss, title } = this.props;
    const btnClassName = cn(className, `btn btn-${type}`);
    return (
      <button
        className={btnClassName}
        type="button"
        disabled={disabled}
        data-dismiss={dataDismiss}
        title={title}
        onClick={() => this.onClick()}
      >
        {text || (icon && <FontAwesomeIcon icon={icon} />)}
      </button>
    );
  }

  // event handlers

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }
}
