import { Api } from '../services/api.service';

export interface StaticPage {
  id: number;
  name: string;
  slug: string;
  text: string;
  redirect_url: string;
  is_hidden: boolean;
  details: StaticPageDetails;
}

export interface StaticPageDetails {
  seo_title?: string;
  seo_h1?: string;
  seo_description?: string;
}

export interface StaticPageSdk {
  getStaticPages(): Promise<StaticPage[]>;
  getStaticPage(id: number): Promise<StaticPage>;
  createStaticPage(data: CreateStaticPageData): Promise<StaticPage>;
  updateStaticPage(id: number, data: UpdateStaticPageData): Promise<StaticPage>;
  deleteStaticPage(id: number): Promise<void>;
}

interface CreateStaticPageData {
  name: string;
  slug: string;
  text?: string;
  redirect_url?: string;
  is_hidden?: boolean;
  details?: StaticPageDetails;
}

interface UpdateStaticPageData {
  name?: string;
  slug?: string;
  text?: string;
  redirect_url?: string;
  is_hidden?: boolean;
  details?: StaticPageDetails;
}

export function createStaticPageSdk(api: Api): StaticPageSdk {
  return {
    async getStaticPages() {
      const data = await api.get('/static_page');
      return data.static_pages;
    },
    async getStaticPage(id) {
      const data = await api.get(`/static_page/${id}`);
      return data.static_page;
    },
    async createStaticPage(data) {
      const result = await api.post('/static_page', data);
      return result.static_page;
    },
    async updateStaticPage(id, data) {
      const result = await api.patch(`/static_page/${id}`, data);
      return result.static_page;
    },
    async deleteStaticPage(id) {
      await api.delete(`/static_page/${id}`);
    },
  };
}
