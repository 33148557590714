import React from 'react';
import { App } from '../../app';
import { createForm, Form } from '../../services/form.service';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { Feedback } from '../../sdk/feedback.sdk';
import { formatDate, formatTime } from '../../services/fmt.service';

import './MFeedback.css';

interface P {
  app: App;
  feedback: Feedback;
  close: () => void;
}

interface S {
  form: Form;
}

export class MFeedback extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      form: this.initForm(props.feedback),
    };
  }

  render() {
    return (
      <div className="MFeedback modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
        </div>
      </div>
    );
  }

  // render helpers

  renderHeader() {
    const { close, feedback } = this.props;
    const title = `Сообщение # ${feedback.id} из формы обратной связи`;
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form } = this.state;
    return (
      <div className="modal-body">
        <div className="row">
          <FormGroup className="col-6" type="text" name="date" label="Дата" form={form} readOnly />
          <FormGroup className="col-6" type="text" name="time" label="Время" form={form} readOnly />
        </div>
        <div className="row">
          <FormGroup className="col-6" type="text" name="name" label="ФИО" form={form} readOnly />
          <FormGroup className="col-6" type="text" name="email" label="Email" form={form} readOnly />
        </div>
        <FormGroup type="textarea" name="text" label="Текст" form={form} readOnly />
      </div>
    );
  }

  // other helpers

  initForm(feedback: Feedback) {
    return createForm({
      date: formatDate(feedback.created_at),
      time: formatTime(feedback.created_at),
      name: feedback.name,
      email: feedback.email,
      text: feedback.text,
    });
  }
}
