import React from 'react';
import { App } from '../../app';
import { StaticPage } from '../../sdk/staticPage.sdk';
import { Button } from '../Button';
import { List } from '../List';
import { MStaticPage } from '../modals/MStaticPage';
import { config } from '../../config';

interface P {
  app: App;
}

interface S {
  items: StaticPage[];
  search: string;
}

export class PStaticPages extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      search: '',
    };
  }

  render() {
    return (
      <div className="PStaticPages">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Статический контент', undefined, config.tabs.static);
    app.updateUI();
    await this.refreshData();
  }

  // event handlers

  async onAdd() {
    const { app } = this.props;
    await app.showModal(MStaticPage);
    await this.refreshData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(staticPage: StaticPage) {
    const { app } = this.props;
    const menuItems = [
      {
        name: 'Редактировать',
        action: () => this.runPrimaryAction(staticPage),
      },
    ];
    if (staticPage.id !== config.homePageId) {
      menuItems.push({
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить страницу "${staticPage.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            const sdk = app.getSdk();
            await sdk.deleteStaticPage(staticPage.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      });
    }

    return menuItems;
  }

  async onItemSelect(staticPage: StaticPage) {
    await this.runPrimaryAction(staticPage);
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        onGetItemMenu={(item) => this.onGetItemMenu(item)}
        onGetItemClassName={(item) => this.getItemClassName(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'URL',
        headClassName: 'w-250px',
        value: (item: StaticPage) => item.slug,
      },
      {
        name: 'Название',
        value: (item: StaticPage) => item.name,
      },
    ];
  }

  getItemClassName(staticPage: StaticPage) {
    return staticPage.is_hidden ? 'text-black-50' : '';
  }

  async runPrimaryAction(staticPage: StaticPage) {
    const { app } = this.props;
    await app.showModal(MStaticPage, { staticPage });
    await this.refreshData();
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const items = await app.getSdk().getStaticPages();
      this.setState({ items });
    } catch (err) {
      app.handleError(err);
    }
  }
}
