import { createBrowserHistory, History } from 'history';

import { config } from './config';
import { Account, AccountSdk, createAccountSdk } from './sdk/account.sdk';
import { ArticleSdk, createArticleSdk } from './sdk/article.sdk';
import { AuthSdk, createAuthSdk } from './sdk/auth.sdk';
import { BannerSdk, createBannerSdk } from './sdk/banner.sdk';
import { CallbackSdk, createCallbackSdk } from './sdk/callback.sdk';
import { createDeliverySdk, DeliverySdk } from './sdk/delivery.sdk';
import { createExternalReviewSdk, ExternalReviewSdk } from './sdk/externalReview.sdk';
import { createFeedbackSdk, FeedbackSdk } from './sdk/feedback.sdk';
import { createHolidaySdk, HolidaySdk } from './sdk/holiday.sdk';
import { createMediaSdk, MediaSdk } from './sdk/media.sdk';
import { createNewsSdk, NewsSdk } from './sdk/news.sdk';
import { createObjSdk, ObjSdk } from './sdk/obj.sdk';
import { createOrderSdk, OrderSdk } from './sdk/order.sdk';
import { createProductSdk, ProductSdk } from './sdk/product.sdk';
import { createRedirectSdk, RedirectSdk } from './sdk/redirect.sdk';
import { createReviewSdk, ReviewSdk } from './sdk/review.sdk';
import { createSearchPageSdk, SearchPageSdk } from './sdk/search.sdk';
import { createSettingsSdk, SettingsSdk } from './sdk/settings.sdk';
import { createStaticPageSdk, StaticPageSdk } from './sdk/staticPage.sdk';
import { Api, createApi } from './services/api.service';
import { formatUniqueConstraintError } from './services/fmt.service';

export interface App {
  setRootComponent(rootComponent: React.Component): void;
  updateUI(): void;
  handleError(error: any): void;
  getHistory(): History;
  getSdk(): Sdk;
  getUser(): Account | undefined;
  setToken(token?: string): Promise<void>;
  unsetToken(): void;
  setupPage(title: string, backUrl?: string, tabs?: PageTab[]): void;
  getPageTitle(): string | undefined;
  getPageBackUrl(): string | undefined;
  getPageTabs(): PageTab[] | undefined;
  getImageUrl(name: string, maxWidth?: number, maxHeight?: number): string;
  showModal<T = any>(component: any, props?: any): Promise<T | undefined>;
  getModals(): Modal[];
  getStartUrl(): string;
  loadFromCache(key: string): any;
  saveToCache(key: string, value: any): void;
  scrollToTop(): void;
}

export type Sdk = AccountSdk &
  ArticleSdk &
  AuthSdk &
  BannerSdk &
  CallbackSdk &
  DeliverySdk &
  ExternalReviewSdk &
  FeedbackSdk &
  HolidaySdk &
  MediaSdk &
  NewsSdk &
  ObjSdk &
  OrderSdk &
  ProductSdk &
  RedirectSdk &
  ReviewSdk &
  SearchPageSdk &
  SettingsSdk &
  StaticPageSdk;

export interface PageTab {
  title: string;
  url: string;
}

export interface Modal {
  component: any;
  props: any;
  key: number;
}

interface AppState {
  history: History;
  rootComponent?: React.Component;
  api: Api;
  sdk: Sdk;
  token?: string;
  user?: Account;
  pageTitle?: string;
  pageTabs?: PageTab[];
  modals: Modal[];
  pageBackUrl?: string;
  startUrl: string;
}

export function createApp(): App {
  const state = initAppState();
  return {
    setRootComponent(rootComponent) {
      state.rootComponent = rootComponent;
    },
    updateUI() {
      updateUI(state);
    },
    handleError(error) {
      if (error.status === 401) {
        state.history.push('/login');
      } else if (error.status === 403) {
        alert('Недостаточно прав для выполнения действия');
      } else if (error.status === 422 && error.data && error.data.code === 'foreign_constraint_error') {
        alert('Невозможно удалить объект, так как на него есть ссылки');
      } else if (error.status === 422 && error.data && error.data.code === 'unique_constraint_error') {
        alert(formatUniqueConstraintError(error.data.constraint));
      } else {
        alert(`Ошибка: ${error.message}`);
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    getHistory() {
      return state.history;
    },
    getSdk() {
      return state.sdk;
    },
    async setToken(token) {
      const api = createApi(token);
      const sdk = createSdk(api);
      const user = await sdk.getUser();
      state.api = api;
      state.sdk = sdk;
      state.token = token;
      state.user = user;
      setLsItem('token', token);
      setLsItem('user', JSON.stringify(state.user));
    },
    unsetToken() {
      state.api = createApi();
      state.sdk = createSdk(state.api);
      state.token = undefined;
      state.user = undefined;
      setLsItem('token', undefined);
      setLsItem('user', undefined);
    },
    getUser() {
      return state.user;
    },
    setupPage(title, backUrl, tabs) {
      state.pageTitle = title;
      state.pageBackUrl = backUrl;
      state.pageTabs = tabs;
    },
    getPageTitle() {
      return state.pageTitle;
    },
    getPageBackUrl() {
      return state.pageBackUrl;
    },
    getPageTabs() {
      return state.pageTabs;
    },
    getImageUrl(name) {
      return `${config.fs}/files/${name}`;
    },
    showModal(component, props = {}) {
      return new Promise((resolve) => {
        props.app = this;
        props.close = (result: any) => {
          state.modals.pop();
          updateUI(state);
          resolve(result);
        };
        state.modals.push({ component, props, key: Date.now() });
        updateUI(state);
      });
    },
    getModals() {
      return state.modals;
    },
    getStartUrl() {
      return state.startUrl;
    },
    loadFromCache(key: string) {
      const json = getLsItem(`cache_${key}`);
      return json ? JSON.parse(json) : undefined;
    },
    saveToCache(key: string, value: any) {
      if (value === undefined) {
        setLsItem(`cache_${key}`, undefined);
      } else {
        setLsItem(`cache_${key}`, JSON.stringify(value));
      }
    },
    scrollToTop() {
      document.querySelector('.PageWrapper')!.scrollTo(0, 0);
    },
  };
}

// private

function initAppState(): AppState {
  const history = createBrowserHistory();
  const token = getLsItem('token');
  const api = createApi(token);
  const sdk = createSdk(api);
  const userJson = getLsItem('user');
  const user = userJson ? JSON.parse(userJson) : undefined;
  const startUrl = window.location.pathname + window.location.search;
  return { history, api, sdk, token, user, startUrl, modals: [] };
}

function updateUI(state: AppState) {
  if (state.rootComponent) {
    state.rootComponent.forceUpdate();
  }
}

function createSdk(api: Api): Sdk {
  return {
    ...createAccountSdk(api),
    ...createArticleSdk(api),
    ...createAuthSdk(api),
    ...createBannerSdk(api),
    ...createCallbackSdk(api),
    ...createDeliverySdk(api),
    ...createExternalReviewSdk(api),
    ...createFeedbackSdk(api),
    ...createHolidaySdk(api),
    ...createMediaSdk(api),
    ...createNewsSdk(api),
    ...createObjSdk(api),
    ...createOrderSdk(api),
    ...createProductSdk(api),
    ...createRedirectSdk(api),
    ...createReviewSdk(api),
    ...createSearchPageSdk(api),
    ...createSettingsSdk(api),
    ...createStaticPageSdk(api),
  };
}

function getLsItem(key: string) {
  const lsKey = getLsKey(key);
  const value = localStorage.getItem(lsKey);
  return value === null ? undefined : value;
}

function setLsItem(key: string, value: any) {
  const lsKey = getLsKey(key);
  if (value === undefined) {
    localStorage.removeItem(lsKey);
  } else {
    localStorage.setItem(lsKey, value);
  }
}

function getLsKey(key: string) {
  return `gravirovshik_admin_${key}`;
}
