import React from 'react';

import { App } from '../../app';
import { config } from '../../config';
import { getImageDimensions, resetFileInput } from '../../lib/utils.lib';
import { ProductCategory } from '../../sdk/product.sdk';
import { createForm, Form } from '../../services/form.service';
import { checkImageDimensions } from '../../services/validate.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { GmEditor } from '../GmEditor';
import { ModalHeader } from '../ModalHeader';
import { ModalNav } from '../ModalNav';

import './MProductCategory.css';

interface P {
  app: App;
  category?: ProductCategory;
  close: (category?: ProductCategory) => void;
}

interface S {
  isLoading: boolean;
  isUploading: boolean;
  form: Form;
  nav: string;
  image?: { id: number; name: string; hash?: string } | null;
  delImage: boolean;
  isEditorPreview: boolean;
}

const CHEKS = [
  { name: 'hidden', title: 'Временно скрыть' },
  { name: 'major', title: 'Показывать на главной' },
];

const NAV_GENERAL = 'general';
const NAV_DESCRIPTION = 'description';
const NAV_IMAGE = 'image';
const NAV_SEO = 'seo';

const NAVS = [
  { code: NAV_GENERAL, title: 'Основное' },
  { code: NAV_DESCRIPTION, title: 'Описание' },
  { code: NAV_IMAGE, title: 'Фото' },
  { code: NAV_SEO, title: 'SEO' },
];

export class MProductCategory extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      isUploading: false,
      form: this.initForm(props.category),
      nav: NAV_GENERAL,
      delImage: false,
      image: this.initImage(props.category),
      isEditorPreview: false,
    };
  }

  render() {
    return (
      <div className="MProductCategory modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderNav()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  onNavChange(nav: string) {
    this.setState({ nav });
  }

  async onFileChange(e: any) {
    const { app } = this.props;
    const image = e.target.files[0] as File;
    const imageDimension = await getImageDimensions(image);
    const error = checkImageDimensions(imageDimension, config.validImageDimensions.product);
    if (error) {
      resetFileInput('MProductCategory_fileInput');
      alert(error);
      return;
    }
    this.setState({ isUploading: true });
    try {
      const sdk = app.getSdk();
      const { id, hash, name } = await sdk.uploadMedia(image);
      this.setState({
        image: { id, hash, name },
        delImage: false,
        isUploading: false,
      });
    } catch (err) {
      this.setState({ isUploading: false });
      app.handleError(err);
    }
  }

  onEditorChange(text: string) {
    const { form } = this.state;
    const newForm = form.setValue('description', text);
    this.setState({ form: newForm });
  }

  onEditorPreview(isEditorPreview: boolean) {
    this.setState({ isEditorPreview });
  }

  async onDeleteImage() {
    this.setState({
      image: null,
      delImage: true,
    });
  }

  async onSave() {
    const { app, close, category } = this.props;
    let { form } = this.state;
    const { image, delImage } = this.state;
    form = form.trimValues();
    form = form.validateRequired('name');
    form = form.validateRequired('slug');
    form = form.validateUrl('redirect_url');
    form = form.validateSlug('slug');
    if (form.hasError()) {
      this.setState({ form, nav: NAV_GENERAL });
      alert(config.messages.invalidForm);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const sdk = app.getSdk();
      const pd = this.getPostData(form);
      let freshCategory: ProductCategory;
      if (category) {
        freshCategory = await sdk.updateProductCategory(category.id, pd);
        if (delImage) {
          await sdk.deleteProductCategoryImage(category.id);
        }
      } else {
        freshCategory = await sdk.createProductCategory(pd);
      }
      if (image && image.hash) {
        freshCategory = await sdk.updateProductCategoryImage(freshCategory.id, {
          media_id: image.id,
          media_hash: image.hash,
        });
      }
      close(freshCategory);
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, category } = this.props;
    const title = category ? category.name : ' Новая категория';
    return <ModalHeader title={title} close={close} />;
  }

  renderNav() {
    const { nav } = this.state;
    return <ModalNav items={NAVS} nav={nav} onChange={(x) => this.onNavChange(x)} />;
  }

  renderBody() {
    return <div className="modal-body">{this.renderContent()}</div>;
  }

  renderContent() {
    switch (this.state.nav) {
      case NAV_GENERAL:
        return this.renderGeneral();
      case NAV_DESCRIPTION:
        return this.renderDescription();
      case NAV_IMAGE:
        return this.renderImage();
      case NAV_SEO:
        return this.renderSeo();
      default:
        return null;
    }
  }

  renderGeneral() {
    const { form } = this.state;
    return (
      <div className="row">
        <div className="col-8">
          <FormGroup
            type="text"
            name="name"
            label="Название"
            required
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup type="text" name="slug" label="ЧПУ" required form={form} onChange={(x) => this.onFormChange(x)} />
          <FormGroup
            type="text"
            name="redirect_url"
            label="Редирект (URL)"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="col-4">
          <FormGroup
            type="checks"
            name="check"
            label="Параметры"
            form={form}
            checks={CHEKS}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
      </div>
    );
  }

  renderDescription() {
    const { app } = this.props;
    const { form, isEditorPreview, isLoading, isUploading } = this.state;
    return (
      <GmEditor
        app={app}
        text={form.getValue('description')}
        disabled={isLoading || isUploading}
        isPreview={isEditorPreview}
        onChange={(x) => this.onEditorChange(x)}
        onPreview={(x) => this.onEditorPreview(x)}
      />
    );
  }

  renderImage() {
    const { image } = this.state;
    return <div>{image ? this.renderPhotoImage() : this.renderPhotoInput()}</div>;
  }

  renderSeo() {
    const { form } = this.state;
    return (
      <div className="MProductCategory_seo">
        <div className="row">
          <FormGroup
            type="text"
            className="col"
            name="seo_title"
            label="Тег Title"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="text"
            className="col"
            name="seo_h1"
            label="Тег H1"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <FormGroup
          type="textarea"
          name="seo_description"
          className="m-0"
          label="Тег Meta"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderPhotoInput() {
    const { isUploading } = this.state;
    return (
      <div className="form-group">
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input"
            id="MProductCategory_fileInput"
            accept="image/*"
            onChange={(e) => this.onFileChange(e)}
          />
          <label className="custom-file-label" htmlFor="MProductCategory_fileInput">
            {isUploading ? 'Загрузка...' : 'Загрузить фото'}
          </label>
        </div>
      </div>
    );
  }

  renderPhotoImage() {
    const { app } = this.props;
    const { image } = this.state;
    if (!image) {
      return;
    }
    const url = app.getImageUrl(image.name);
    return <img src={url} alt="" className="MProductCategory_image border img-fluid" />;
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading, isUploading, nav, image } = this.state;
    return (
      <div className="modal-footer">
        {nav === NAV_IMAGE && image && (
          <Button type="warning" className="mr-auto" icon="trash" onClick={() => this.onDeleteImage()} />
        )}
        <Button type="secondary" text="Отмена" disabled={isLoading || isUploading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading || isUploading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(category?: ProductCategory) {
    if (!category) {
      return createForm();
    }
    return createForm({
      name: category.name,
      slug: category.slug,
      redirect_url: category.redirect_url,
      check_major: category.is_major ? 'on' : '',
      check_hidden: category.is_hidden ? 'on' : '',
      seo_h1: category.details.seo_h1,
      seo_title: category.details.seo_title,
      seo_description: category.details.seo_description,
      description: category.details.description,
    });
  }

  initImage(category?: ProductCategory) {
    if (!category) {
      return null;
    }
    if (!category.image_id || !category.image_name) {
      return null;
    }
    return { id: category.image_id, name: category.image_name };
  }

  getPostData(form: Form) {
    return {
      name: form.getValue('name'),
      slug: form.getValue('slug'),
      redirect_url: form.getValue('redirect_url'),
      is_major: form.getValue('check_major') === 'on',
      is_hidden: form.getValue('check_hidden') === 'on',
      details: {
        seo_h1: form.getValue('seo_h1') || undefined,
        seo_title: form.getValue('seo_title') || undefined,
        seo_description: form.getValue('seo_description') || undefined,
        description: form.getValue('description') || undefined,
      },
    };
  }
}
