import React from 'react';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { config } from '../../config';

interface P {
  title: string;
  label: string;
  buttonText?: string;
  defaultValue?: string;
  isRequired?: boolean;
  isUrl?: boolean;
  close: (value?: string) => void;
}

interface S {
  isLoading: boolean;
  form: Form;
}

export class MPrompt extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: createForm({ value: props.defaultValue }),
    };
  }

  render() {
    return (
      <div className="MPrompt modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onSave() {
    const { close, isRequired, isUrl } = this.props;
    let { form } = this.state;
    if (isRequired) {
      form = form.validateRequired('value');
    }
    if (isUrl) {
      form = form.validateUrl('value');
    }
    if (form.hasError()) {
      this.setState({ form });
      alert(config.messages.invalidForm);
      return;
    }
    close(form.getValue('value'));
  }

  // render helpers

  renderHeader() {
    const { close, title } = this.props;
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form } = this.state;
    const { label, isRequired } = this.props;
    return (
      <div className="modal-body">
        <FormGroup
          type="text"
          name="value"
          label={label}
          required={Boolean(isRequired)}
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close, buttonText } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text={buttonText || 'Сохранить'} disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }
}
