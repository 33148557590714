import React from 'react';

import { App } from '../../app';
import { formatDate, formatPhone, formatTime } from '../../services/fmt.service';
import { Callback } from '../../sdk/callback.sdk';
import { getPeriodFilterOptions, filterItemsByPeriod } from '../../services/filter.service';
import { List } from '../List';
import { Select } from '../Select';
import { config } from '../../config';

interface P {
  app: App;
}

interface S {
  items: Callback[];
  search: string;
  filter: string;
  page: number;
}

export class PCallbacks extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      search: '',
      filter: 'all',
      page: 1,
    };
  }

  render() {
    return (
      <div className="PCallbacks">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Заявки', undefined, config.tabs.requests);
    app.updateUI();
    await this.refreshData();
  }

  // event handlers

  onSearchChange(e: any) {
    this.setState({ search: e.target.value, page: 1 });
  }

  onFilterChange(value: string) {
    this.setState({ filter: value, page: 1 });
  }

  onPageChange(page: number) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <input
          className="form-control"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
        {this.renderFilter()}
      </div>
    );
  }

  renderFilter() {
    const { items } = this.state;
    return (
      <Select
        items={getPeriodFilterOptions(items, 'created_at')}
        className="ml-3"
        value={this.state.filter}
        onChange={(value) => this.onFilterChange(value)}
      />
    );
  }

  renderList() {
    const { items, search, filter, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={filterItemsByPeriod(filter, items, 'created_at')}
        search={search}
        buttons={this.getDeleteButton()}
        pageNumber={page}
        pageSize={config.itemsPerPage}
        onPageChange={(x) => this.onPageChange(x)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: '#',
        value: (item: Callback) => item.id,
        headClassName: 'w-100px',
      },
      {
        name: 'Дата',
        value: (item: Callback) => formatDate(item.created_at),
        headClassName: 'w-120px',
      },
      {
        name: 'Время',
        value: (item: Callback) => formatTime(item.created_at),
        headClassName: 'w-100px',
      },
      {
        name: 'Имя',
        value: (item: Callback) => item.name,
      },
      {
        name: 'Телефон',
        value: (item: Callback) => formatPhone(item.phone),
      },
    ];
  }

  getDeleteButton() {
    const { app } = this.props;
    return [
      {
        icon: 'times',
        action: async (callback: Callback) => {
          const msg = `Удалить заказ звонка # ${callback.id}?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await app.getSdk().deleteCallback(callback.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const items = await app.getSdk().getCallbacks();
      this.setState({ items });
    } catch (err) {
      app.handleError(err);
    }
  }
}
