import React from 'react';
import { App, PageTab } from '../app';
import { Link } from 'react-router-dom';
import { cn } from '../lib/utils.lib';

import './PageWrapper.css';

interface P {
  app: App;
}

export class PageWrapper extends React.Component<P> {
  render() {
    return (
      <div className={this.getPageWrapperClassName()}>
        {this.renderTabs()}
        <div className="card PageWrapper_card">
          <div className="card-body">{this.props.children}</div>
        </div>
      </div>
    );
  }

  // render helpers

  renderTabs() {
    const { app } = this.props;
    const tabs = app.getPageTabs();
    if (!tabs) {
      return null;
    }
    return <ul className="nav nav-tabs PageWrapper_tabs">{tabs.map((item) => this.renderTabItem(item))}</ul>;
  }

  renderTabItem(tab: PageTab) {
    const isActive = window.location.pathname.startsWith(tab.url);
    const linkClassName = cn('nav-link', isActive && 'active');
    return (
      <li className="nav-item" key={tab.title}>
        <Link to={tab.url} className={linkClassName}>
          {tab.title}
        </Link>
      </li>
    );
  }

  // other helpers

  getPageWrapperClassName() {
    const { app } = this.props;
    const tabs = app.getPageTabs();
    return cn('PageWrapper flex-fill p-3', tabs && 'PageWrapper_withTabs');
  }
}
