import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Link } from 'react-router-dom';
import { App } from '../app';
import { Dropdown } from './Dropdown';

interface P {
  app: App;
}

export class Topbar extends React.Component<P> {
  render() {
    const { app } = this.props;
    const user = app.getUser();
    if (!user) {
      return null;
    }
    const items = ['Выход'];
    return (
      <div className="Topbar bg-white border-bottom d-flex px-3 py-2">
        <div className="align-items-center d-flex flex-fill">
          <h3 className="text-muted m-0">
            {this.renderBack()}
            {app.getPageTitle()}
          </h3>
        </div>
        <Dropdown type="link" text={user.email} items={items} right onSelect={(index) => this.onSelect(index)} />
      </div>
    );
  }

  // event handlers

  async onSelect(index: number) {
    const { app } = this.props;
    if (index === 0) {
      app.unsetToken();
      app.getHistory().push('/login');
    }
  }

  // render helpers

  renderBack() {
    const { app } = this.props;
    const url = app.getPageBackUrl();
    if (!url) {
      return null;
    }
    return (
      <Link to={url} className="mr-3">
        <FontAwesomeIcon icon="chevron-left" />
      </Link>
    );
  }
}
