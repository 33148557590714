import { format } from 'date-fns';
import React from 'react';
import { App } from '../../app';
import { config } from '../../config';
import { getImageDimensions, resetFileInput } from '../../lib/utils.lib';
import { NewsItem } from '../../sdk/news.sdk';
import { createForm, Form } from '../../services/form.service';
import { checkImageDimensions } from '../../services/validate.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { GmEditor } from '../GmEditor';
import { ModalHeader } from '../ModalHeader';
import { ModalNav } from '../ModalNav';

import './MNews.css';

interface P {
  app: App;
  newsItem?: NewsItem;
  close: (newsItem?: NewsItem) => void;
}

interface S {
  isLoading: boolean;
  isUploading: boolean;
  form: Form;
  nav: string;
  image: { id: number; name: string; hash?: string } | null;
  delImage: boolean;
  isEditorPreview: boolean;
}

const CHEKS = [
  { name: 'hidden', title: 'Временно скрыть' },
  { name: 'major', title: 'Показывать на главной' },
];

const NAV_GENERAL = 'general';
const NAV_TEXT = 'text';
const NAV_IMAGE = 'image';

const NAVS = [
  { code: NAV_GENERAL, title: 'Основное' },
  { code: NAV_TEXT, title: 'Текст' },
  { code: NAV_IMAGE, title: 'Изображение' },
];

export class MNews extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      isUploading: false,
      form: this.initForm(props.newsItem),
      nav: NAV_GENERAL,
      delImage: false,
      image: this.initImage(props.newsItem),
      isEditorPreview: false,
    };
  }

  render() {
    return (
      <div className="MNews modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderNav()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  onNavChange(nav: string) {
    this.setState({ nav });
  }

  async onFileChange(e: any) {
    const { app } = this.props;
    const image = e.target.files[0] as File;
    const imageDimension = await getImageDimensions(image);
    const error = checkImageDimensions(imageDimension, config.validImageDimensions.news);
    if (error) {
      resetFileInput('MNews_fileInput');
      alert(error);
      return;
    }
    this.setState({ isUploading: true });
    try {
      const sdk = app.getSdk();
      const { id, hash, name } = await sdk.uploadMedia(image);
      this.setState({
        image: { id, hash, name },
        delImage: false,
        isUploading: false,
      });
    } catch (err) {
      this.setState({ isUploading: false });
      app.handleError(err);
    }
  }

  async onDeleteImage() {
    this.setState({
      image: null,
      delImage: true,
    });
  }

  onEditorChange(text: string) {
    const { form } = this.state;
    const newForm = form.setValue('text', text);
    this.setState({ form: newForm });
  }

  onEditorPreview(isEditorPreview: boolean) {
    this.setState({ isEditorPreview });
  }

  async onSave() {
    const { app, close, newsItem } = this.props;
    let { form } = this.state;
    const { image, delImage } = this.state;
    form = form.trimValues();
    form = form.validateRequired('title');
    form = form.validateRequired('date');
    form = form.validateDate('date');
    if (form.hasError()) {
      this.setState({ form, nav: NAV_GENERAL });
      alert(config.messages.invalidForm);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const sdk = app.getSdk();
      const pd = this.getPostData(form);
      let freshNewsItem: NewsItem;
      if (newsItem) {
        freshNewsItem = await sdk.updateNewsItem(newsItem.id, pd);
        if (delImage) {
          await sdk.deleteNewsItemImage(newsItem.id);
        }
      } else {
        freshNewsItem = await sdk.createNewsItem(pd);
      }
      if (image && image.hash) {
        freshNewsItem = await sdk.updateNewsItemImage(freshNewsItem.id, {
          media_id: image.id,
          media_hash: image.hash,
        });
      }
      close(freshNewsItem);
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, newsItem } = this.props;
    const title = newsItem ? newsItem.title : 'Добавить новость';
    return <ModalHeader title={title} close={close} />;
  }

  renderNav() {
    const { nav } = this.state;
    return <ModalNav items={NAVS} nav={nav} onChange={(x) => this.onNavChange(x)} />;
  }

  renderBody() {
    return <div className="modal-body">{this.renderContent()}</div>;
  }

  renderContent() {
    switch (this.state.nav) {
      case NAV_GENERAL:
        return this.renderGeneral();
      case NAV_TEXT:
        return this.renderText();
      case NAV_IMAGE:
        return this.renderImage();
      default:
        return null;
    }
  }

  renderGeneral() {
    const { form } = this.state;
    return (
      <div className="row">
        <div className="col-8">
          <FormGroup type="date" name="date" label="Дата" required form={form} onChange={(x) => this.onFormChange(x)} />
          <FormGroup
            type="text"
            name="title"
            label="Заголовок"
            required
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="col-4">
          <FormGroup
            type="checks"
            name="check"
            label="Параметры"
            form={form}
            checks={CHEKS}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
      </div>
    );
  }

  renderText() {
    const { app } = this.props;
    const { form, isLoading, isUploading, isEditorPreview } = this.state;
    return (
      <GmEditor
        app={app}
        text={form.getValue('text')}
        disabled={isLoading || isUploading}
        isPreview={isEditorPreview}
        onChange={(x) => this.onEditorChange(x)}
        onPreview={(x) => this.onEditorPreview(x)}
      />
    );
  }

  renderImage() {
    const { image } = this.state;
    return <div>{image ? this.renderPhotoImage() : this.renderPhotoInput()}</div>;
  }

  renderSeo() {
    const { form } = this.state;
    return (
      <div className="MNews_seo">
        <div className="row">
          <FormGroup
            type="text"
            className="col"
            name="seo_title"
            label="Тег Title"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="text"
            className="col"
            name="seo_h1"
            label="Тег H1"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <FormGroup
          type="textarea"
          className="m-0"
          name="seo_description"
          label="Тег Meta"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderPhotoInput() {
    const { isUploading } = this.state;
    return (
      <div className="form-group">
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input"
            id="MNews_fileInput"
            accept="image/*"
            onChange={(e) => this.onFileChange(e)}
          />
          <label className="custom-file-label" htmlFor="MNews_fileInput">
            {isUploading ? 'Загрузка...' : 'Загрузить изображение'}
          </label>
        </div>
      </div>
    );
  }

  renderPhotoImage() {
    const { app } = this.props;
    const { image } = this.state;
    if (!image) {
      return;
    }
    const url = app.getImageUrl(image.name);
    return (
      <div className="MNews_image">
        <img src={url} alt="" className="border img-fluid" />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading, isUploading, nav, image } = this.state;
    return (
      <div className="modal-footer">
        {nav === NAV_IMAGE && image && (
          <Button type="warning" className="mr-auto" icon="trash" onClick={() => this.onDeleteImage()} />
        )}
        <Button type="secondary" text="Отмена" disabled={isLoading || isUploading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading || isUploading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(newsItem?: NewsItem) {
    if (!newsItem) {
      return createForm({
        date: format(new Date(), 'yyyy-MM-dd'),
      });
    }
    return createForm({
      title: newsItem.title,
      date: newsItem.date,
      text: newsItem.text,
      check_major: newsItem.is_major ? 'on' : '',
      check_hidden: newsItem.is_hidden ? 'on' : '',
    });
  }

  initImage(newsItem?: NewsItem) {
    if (!newsItem) {
      return null;
    }
    if (!newsItem.image_id || !newsItem.image_name) {
      return null;
    }
    return { id: newsItem.image_id, name: newsItem.image_name };
  }

  getPostData(form: Form) {
    return {
      title: form.getValue('title'),
      date: form.getValue('date'),
      text: form.getValue('text'),
      is_major: form.getValue('check_major') === 'on',
      is_hidden: form.getValue('check_hidden') === 'on',
    };
  }
}
