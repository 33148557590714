import React from 'react';
import { App } from '../../app';
import { formatMoney } from '../../services/fmt.service';
import { City, TransportCompany, TransportCompanyWarehouse } from '../../sdk/delivery.sdk';
import { Button } from '../Button';
import { List } from '../List';
import { MWarehouse } from '../modals/MWarehouse';

interface P {
  app: App;
}

interface S {
  items: TransportCompanyWarehouse[];
  city: City;
  companiesWithoutWarehouse: TransportCompany[];
  search: string;
}

export class PWarehouses extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      city: props.app.loadFromCache('city'),
      companiesWithoutWarehouse: [],
      search: '',
    };
  }

  render() {
    return (
      <div className="PWarehouses">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    const { city } = this.state;
    const url = `/delivery/cities/${city.region}`;
    app.setupPage(`Склады транспортных компаний (${city.name})`, url);
    app.updateUI();
    await this.refreshData();
  }

  // event handlers

  async onAdd() {
    const { app } = this.props;
    const { city, companiesWithoutWarehouse } = this.state;
    await app.showModal(MWarehouse, { city, companies: companiesWithoutWarehouse });
    await this.refreshData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(warehouse: TransportCompanyWarehouse) {
    const { app } = this.props;
    return [
      {
        name: 'Редактировать',
        action: async () => {
          await this.runPrimaryAction(warehouse);
        },
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить склад компании "${warehouse.transport_company_name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            const sdk = app.getSdk();
            await sdk.deleteWarehouse(warehouse.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(warehouse: TransportCompanyWarehouse) {
    await this.runPrimaryAction(warehouse);
  }

  // render helpers

  renderTop() {
    const { companiesWithoutWarehouse, search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button
          type="primary"
          text="Добавить"
          disabled={companiesWithoutWarehouse.length === 0}
          onClick={() => this.onAdd()}
        />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        onGetItemMenu={(item) => this.onGetItemMenu(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'Название',
        value: (item: TransportCompanyWarehouse) => `Склад компании "${item.transport_company_name}"`,
      },
      {
        name: 'Срок доставки',
        value: (item: TransportCompanyWarehouse) => item.details.delivery_time,
        headClassName: 'w-250px',
      },
      {
        name: 'Стоимость доставки',
        value: (item: TransportCompanyWarehouse) => formatMoney(item.details.delivery_cost, false, true),
        headClassName: 'w-250px',
      },
    ];
  }

  async runPrimaryAction(warehouse: TransportCompanyWarehouse) {
    const { app } = this.props;
    const { city } = this.state;
    try {
      const company = await app.getSdk().getTransportCompany(warehouse.transport_company_id);
      await app.showModal(MWarehouse, { city, warehouse, companies: [company] });
      await this.refreshData();
    } catch (err) {
      app.handleError(err);
    }
  }

  async refreshData() {
    const { app } = this.props;
    const { city } = this.state;
    try {
      const sdk = app.getSdk();
      const items = await sdk.getWarehouses({ city_id: city.id });
      const companies = await sdk.getTransportCompanies();
      this.setState({
        items,
        companiesWithoutWarehouse: companies.filter((x) => !items.find((i) => i.transport_company_id === x.id)),
      });
    } catch (err) {
      app.handleError(err);
    }
  }
}
