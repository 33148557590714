import { Api } from '../services/api.service';

export interface Account {
  id: number;
  email: string;
  name: string;
  is_active: boolean;
}

export interface AccountSdk {
  getAccounts(): Promise<Account[]>;
  getAccount(id: number): Promise<Account>;
  createAccount(data: CreateAccountData): Promise<Account>;
  updateAccount(id: number, data: UpdateAccountData): Promise<Account>;
  deleteAccount(id: number): Promise<void>;
}

interface CreateAccountData {
  name: string;
  email: string;
}

interface UpdateAccountData {
  name?: string;
  email?: string;
  password?: string;
  is_active?: boolean;
}

export function createAccountSdk(api: Api): AccountSdk {
  return {
    async getAccounts() {
      const data = await api.get('/account');
      return data.accounts;
    },
    async getAccount(id) {
      const data = await api.get(`/account/${id}`);
      return data.accounts;
    },
    async createAccount(data) {
      const result = await api.post('/account', data);
      return result.account;
    },
    async updateAccount(id, data) {
      const result = await api.patch(`/account/${id}`, data);
      return result.account;
    },
    async deleteAccount(id) {
      await api.delete(`/account/${id}`);
    },
  };
}
