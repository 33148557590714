import React from 'react';
import { App } from '../../app';
import { config } from '../../config';
import { City } from '../../sdk/delivery.sdk';
import { isMo, sortCities } from '../../services/delivery.service';
import { formatMoney } from '../../services/fmt.service';
import { Button } from '../Button';
import { List } from '../List';
import { MCity } from '../modals/MCity';

interface P {
  app: App;
  match: any;
}

interface S {
  items: City[];
  search: string;
}

export class PCities extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      search: '',
    };
  }

  render() {
    return (
      <div className="PCities">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Доставка', undefined, config.tabs.delivery);
    app.updateUI();
    await this.refreshData();
  }

  async componentDidUpdate(prevProps: P) {
    const region = this.getRegion();
    const prevRegion = prevProps.match.params.region;
    if (region !== prevRegion) {
      await this.refreshData();
    }
  }

  // event handlers

  async onAdd() {
    const { app } = this.props;
    const region = this.getRegion();
    await app.showModal(MCity, { region });
    await this.refreshData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(city: City) {
    const { app } = this.props;
    const region = this.getRegion();
    const menuItems = [
      {
        name: 'Редактировать',
        action: async () => {
          await app.showModal(MCity, { city, region });
          await this.refreshData();
        },
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить город "${city.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            const sdk = app.getSdk();
            await sdk.deleteCity(city.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
    if (!isMo(region)) {
      menuItems.unshift({
        name: 'Перейти к складам',
        action: async () => {
          app.saveToCache('city', city);
          app.getHistory().push(`/delivery/cities/${region}/${city.id}/warehouses`);
        },
      });
    }
    return menuItems;
  }

  async onItemSelect(city: City) {
    await this.runPrimaryAction(city);
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        onGetItemMenu={(item) => this.onGetItemMenu(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
      />
    );
  }

  // other helpers

  getColumns() {
    const region = this.getRegion();
    const columns = [
      {
        name: 'Город',
        value: (item: City) => item.name,
        headClassName: '',
      },
    ];
    if (isMo(region)) {
      columns.push({
        name: 'Стоимость доставки',
        value: (item: City) => formatMoney(item.delivery_cost, false, true),
        headClassName: 'w-250px',
      });
    }
    return columns;
  }

  getRegion() {
    const { match } = this.props;
    return match.params.region;
  }

  async runPrimaryAction(city: City) {
    const { app } = this.props;
    const region = this.getRegion();
    if (isMo(region)) {
      await app.showModal(MCity, { city, region });
      await this.refreshData();
    } else {
      app.saveToCache('city', city);
      app.getHistory().push(`/delivery/cities/${region}/${city.id}/warehouses`);
    }
  }

  async refreshData() {
    const { app } = this.props;
    const region = this.getRegion();
    try {
      const items = await app.getSdk().getCities({ region });
      this.setState({ items: sortCities(items) });
    } catch (err) {
      app.handleError(err);
    }
  }
}
