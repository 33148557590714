import React from 'react';

import { App } from '../../app';
import { config } from '../../config';
import { ProductTab } from '../../sdk/product.sdk';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

interface P {
  app: App;
  productTab?: ProductTab;
  close: (productTab?: ProductTab) => void;
}

interface S {
  isLoading: boolean;
  form: Form;
}

export class MProductTab extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.productTab),
    };
  }

  render() {
    return (
      <div className="MProductTab modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onSave() {
    const { app, close, productTab } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('name');
    if (form.hasError()) {
      this.setState({ form });
      alert(config.messages.invalidForm);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const sdk = app.getSdk();
      const pd = this.getPostData(form);
      const freshProductTab = await (productTab ? sdk.updateProductTab(productTab.id, pd) : sdk.createProductTab(pd));
      close(freshProductTab);
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, productTab } = this.props;
    const title = productTab ? `Вкладка` : 'Новая вкладка';
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { productTab } = this.props;
    const { form } = this.state;
    return (
      <div className="modal-body">
        <FormGroup
          type="text"
          name="name"
          label="Название"
          required
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
        <FormGroup
          type="text"
          name="code"
          label="Код"
          placeholder="Будет сгенерирован автоматически"
          disabled={Boolean(productTab)}
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(productTab?: ProductTab) {
    if (!productTab) {
      return createForm();
    }
    return createForm({
      code: productTab.code,
      name: productTab.name,
    });
  }

  getPostData(form: Form) {
    return {
      code: form.getValue('code'),
      name: form.getValue('name'),
    };
  }
}
