import React from 'react';
import { cn } from '../lib/utils.lib';
import { VoidLink } from './VoidLink';

import './ModalNav.css';

interface P {
  items: NavItem[];
  nav: string;
  className?: string;
  onChange?: (nav: string) => void;
}

interface NavItem {
  code: string;
  title: string;
}

export class ModalNav extends React.Component<P> {
  render() {
    const { items, className } = this.props;
    return <ul className={cn(className, 'ModalNav nav')}>{items.map((item) => this.renderItem(item))}</ul>;
  }

  // event handlers

  onItemClick(nav: string) {
    if (this.props.onChange) {
      this.props.onChange(nav);
    }
  }

  // render helpers

  renderItem(item: NavItem) {
    const { nav } = this.props;
    const { code, title } = item;
    const className = cn('nav-link', nav === code ? 'disabled' : '');
    return (
      <li className="nav-item" key={code}>
        <VoidLink className={className} onClick={() => this.onItemClick(code)} text={title} />
      </li>
    );
  }
}
