import React from 'react';

import { App } from '../../app';
import { config } from '../../config';
import { getImageDimensions, resetFileInput } from '../../lib/utils.lib';
import { PriceType, ProductCategory, ProductImage, ProductSubcategory, ProductTab } from '../../sdk/product.sdk';
import { formatMoney, formatPriceType } from '../../services/fmt.service';
import { createForm, Form } from '../../services/form.service';
import { checkImageDimensions } from '../../services/validate.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { GmEditor } from '../GmEditor';
import { Image, ImageList } from '../ImageList';
import { ModalHeader } from '../ModalHeader';
import { ModalNav } from '../ModalNav';
import { ProductRefEditor } from '../ProductRefEditor';

import './MProductSubcategory.css';

interface P {
  app: App;
  categoryId: number;
  categories: ProductCategory[];
  productTabs: ProductTab[];
  subcategory?: ProductSubcategory;
  close: (subcategory?: ProductSubcategory) => void;
}

interface S {
  isLoading: boolean;
  isUploading: boolean;
  form: Form;
  images: Image[];
  productImages: ProductImage[];
  subcategories: ProductSubcategory[];
  refSubcategoryIds: number[];
  productTabs: ProductTab[];
  nav: string;
  isEditorPreview: boolean;
}

const CHEKS = [
  { name: 'hidden', title: 'Временно скрыть' },
  { name: 'major', title: 'Показывать на главной' },
  { name: 'nova', title: 'Новинка' },
  { name: 'leader', title: 'Лидер продаж' },
  // { name: 'stock', title: 'На складе' },
];

const NAV_GENERAL = 'general';
const NAV_PRICES = 'prices';
const NAV_TEXTS = 'texts';
const NAV_IMAGE = 'image';
const NAV_REF = 'ref';
const NAV_SEO = 'seo';

const NAVS = [
  { code: NAV_GENERAL, title: 'Основное' },
  { code: NAV_PRICES, title: 'Цены' },
  { code: NAV_TEXTS, title: 'Тексты' },
  { code: NAV_IMAGE, title: 'Фото' },
  { code: NAV_REF, title: 'Сопутствующие товары' },
  { code: NAV_SEO, title: 'SEO' },
];

export class MProductSubcategory extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: true,
      isUploading: false,
      form: this.initForm(props.subcategory),
      nav: NAV_GENERAL,
      productImages: [],
      subcategories: [],
      refSubcategoryIds: [],
      productTabs: [],
      images: [],
      isEditorPreview: false,
    };
  }

  render() {
    return (
      <div className="MProductSubcategory modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderNav()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
    this.setState({ isLoading: false, images: this.initImages() });
  }

  // event handlers

  onFormChange(form: Form) {
    let newForm = form;
    if (form.getValue('price_type') !== PriceType.by_qty) {
      newForm = form.setValue('price_scale', '').resetError('price_scale');
    }
    this.setState({ form: newForm });
  }

  onNavChange(nav: string) {
    this.setState({ nav });
  }

  onImageListChange(images: Image[]) {
    this.setState({ images });
  }

  async onImageListAdd(files: FileList) {
    const { app } = this.props;
    const { images } = this.state;
    const fileList = Array.from(files);
    for (const file of fileList) {
      const imageDimensions = await getImageDimensions(file);
      const error = checkImageDimensions(imageDimensions, config.validImageDimensions.product);
      if (error) {
        resetFileInput('imageInput');
        alert(error);
        return;
      }
    }
    this.setState({ isUploading: true });
    try {
      const sdk = app.getSdk();
      let imageList = [...images];
      for (const file of fileList) {
        const { id, hash, name } = await sdk.uploadMedia(file);
        const imageDimensions = await getImageDimensions(file);
        const newImage = {
          alt: '',
          caption: '',
          dimensions: `${imageDimensions.width}х${imageDimensions.height}`,
          media_id: id,
          media_name: name,
          media_hash: hash,
          media_size: file.size,
          is_primary: imageList.length === 0,
        };
        imageList = [...imageList, newImage];
        this.setState({ isUploading: false, images: imageList });
      }
    } catch (err) {
      app.handleError(err);
      this.setState({ isUploading: false });
    }
  }

  onRefSubcategoryIdsChange(refSubcategoryIds: number[]) {
    this.setState({ refSubcategoryIds });
  }

  onEditorChange(text: string) {
    const { form } = this.state;
    const textsKey = form.getValue('texts');
    const newForm = form.setValue(`texts_${textsKey}`, text);
    this.setState({ form: newForm });
  }

  onEditorPreview(isEditorPreview: boolean) {
    this.setState({ isEditorPreview });
  }

  async onSave() {
    const { app, close, subcategory } = this.props;
    let { form } = this.state;
    const { refSubcategoryIds } = this.state;
    form = form.trimValues();
    form = form.validateRequired('name');
    form = form.validateRequired('category');
    form = form.validateRequired('slug');
    form = form.validateUrl('redirect_url');
    form = form.validateSlug('slug');
    if (form.hasError()) {
      this.setState({ form, nav: NAV_GENERAL });
      alert(config.messages.invalidForm);
      return;
    }
    form = form.validateRequired('price_type');
    form = form.validateMoney('display_price', true);
    form = form.validateMoney('display_price_crossed', true);
    if (this.isPriceTypeByQty()) {
      form = form.validateRequired('price_scale');
    }
    if (form.hasError()) {
      this.setState({ form, nav: NAV_PRICES });
      alert(config.messages.invalidForm);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const sdk = app.getSdk();
      const pd = this.getPostSubcategoryData(form);
      const freshSubcategory = await (subcategory
        ? sdk.updateProductSubcategory(subcategory.id, pd)
        : sdk.createProductSubcategory(pd));
      const images = this.getPostImageData();
      if (images) {
        await sdk.updateProductImages(freshSubcategory.id, images);
      }
      await sdk.updateProductRefs(freshSubcategory.id, { ids: refSubcategoryIds });
      close(freshSubcategory);
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, subcategory } = this.props;
    const title = subcategory ? subcategory.name : ' Новая подкатегория';
    return <ModalHeader title={title} close={close} />;
  }

  renderNav() {
    const { nav } = this.state;
    return <ModalNav items={NAVS} nav={nav} onChange={(x) => this.onNavChange(x)} />;
  }

  renderBody() {
    return <div className="modal-body">{this.renderContent()}</div>;
  }

  renderContent() {
    switch (this.state.nav) {
      case NAV_GENERAL:
        return this.renderGeneral();
      case NAV_PRICES:
        return this.renderPrices();
      case NAV_TEXTS:
        return this.renderTexts();
      case NAV_IMAGE:
        return this.renderImage();
      case NAV_REF:
        return this.renderRef();
      case NAV_SEO:
        return this.renderSeo();
      default:
        return null;
    }
  }

  renderGeneral() {
    const { form } = this.state;
    const { subcategory } = this.props;
    return (
      <div className="row">
        <div className="col-8">
          <FormGroup
            type="select"
            name="category"
            label="Категория"
            required
            disabled={!Boolean(subcategory)}
            options={this.getCategoryOptions()}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="text"
            name="name"
            label="Название"
            required
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <div className="row">
            <div className="col-8">
              <FormGroup
                type="text"
                name="slug"
                label="ЧПУ"
                required
                form={form}
                onChange={(x) => this.onFormChange(x)}
              />
            </div>
            <div className="col-4">
              <FormGroup
                type="select"
                name="unit"
                label="Ед. измерения"
                required
                options={this.getUnitOptions()}
                form={form}
                onChange={(x) => this.onFormChange(x)}
              />
            </div>
          </div>
          <FormGroup
            type="text"
            name="redirect_url"
            label="Редирект (URL)"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="col-4">
          <FormGroup
            type="checks"
            name="check"
            label="Параметры"
            form={form}
            checks={CHEKS}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
      </div>
    );
  }

  renderPrices() {
    const { form } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <FormGroup
            type="select"
            className="col"
            name="price_type"
            required
            options={this.getPriceTypeOptions()}
            label="Тип цены"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="select"
            className="col"
            name="price_scale"
            required={this.isPriceTypeByQty()}
            disabled={!this.isPriceTypeByQty()}
            options={this.getScaleOptions()}
            label="Шкала"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="row">
          <FormGroup
            type="text"
            className="col"
            name="display_price"
            label="Цена в списке, ₽"
            disabled
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="text"
            className="col"
            name="display_price_crossed"
            label="Зачёркнутая цена в списке, ₽"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
      </React.Fragment>
    );
  }

  renderTexts() {
    const { app } = this.props;
    const { form, isLoading, isUploading, isEditorPreview } = this.state;
    const textsKey = form.getValue('texts');
    return (
      <React.Fragment>
        <FormGroup
          type="select"
          name="texts"
          options={this.getTextsOptions()}
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
        <GmEditor
          app={app}
          text={form.getValue(`texts_${textsKey}`)}
          disabled={isLoading || isUploading}
          isPreview={isEditorPreview}
          onChange={(x) => this.onEditorChange(x)}
          onPreview={(x) => this.onEditorPreview(x)}
        />
      </React.Fragment>
    );
  }

  renderImage() {
    const { app } = this.props;
    const { images, isUploading } = this.state;
    return (
      <ImageList
        app={app}
        images={images}
        isUploading={isUploading}
        onChange={(x) => this.onImageListChange(x)}
        onAdd={(x) => this.onImageListAdd(x)}
      />
    );
  }

  renderRef() {
    const { subcategory, categories } = this.props;
    const { isLoading, subcategories, refSubcategoryIds } = this.state;
    return (
      <ProductRefEditor
        app={this.props.app}
        isLoading={isLoading}
        subcategoryId={subcategory && subcategory.id}
        categories={categories}
        subcategories={subcategories}
        refSubcategoryIds={refSubcategoryIds}
        onChange={(refSubcategoryIds) => this.onRefSubcategoryIdsChange(refSubcategoryIds)}
      />
    );
  }

  renderSeo() {
    const { form } = this.state;
    return (
      <div className="MProductSubcategory_seo h-100">
        <div className="row">
          <FormGroup
            type="text"
            className="col"
            name="seo_title"
            label="Тег Title"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="text"
            className="col"
            name="seo_h1"
            label="Тег H1"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <FormGroup
          type="textarea"
          className="m-0 h-75"
          name="seo_description"
          label="Тег Meta"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading, isUploading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading || isUploading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading || isUploading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(subcategory?: ProductSubcategory) {
    const { categoryId, productTabs } = this.props;
    if (!subcategory) {
      return createForm({
        unit: config.units[0],
        texts: productTabs[0]?.code ?? '',
        price_type: PriceType.simple,
        category: categoryId,
      });
    }
    const init: any = {
      name: subcategory.name,
      category: subcategory.category_id,
      slug: subcategory.slug,
      unit: subcategory.unit,
      texts: productTabs[0]?.code ?? '',
      price_type: subcategory.price_type,
      price_scale: subcategory.price_scale,
      redirect_url: subcategory.redirect_url,
      display_price: formatMoney(subcategory.display_price, true),
      display_price_crossed: formatMoney(subcategory.display_price_crossed, true),
      check_major: subcategory.is_major ? 'on' : '',
      check_hidden: subcategory.is_hidden ? 'on' : '',
      check_stock: subcategory.in_stock ? 'on' : '',
      check_leader: subcategory.is_leader ? 'on' : '',
      check_nova: subcategory.is_nova ? 'on' : '',
      seo_h1: subcategory.details.seo_h1,
      seo_title: subcategory.details.seo_title,
      seo_description: subcategory.details.seo_description,
    };
    Object.keys(subcategory.texts).forEach((x) => (init[`texts_${x}`] = subcategory.texts[x]));
    return createForm(init);
  }

  initImages() {
    const { productImages } = this.state;
    if (!productImages) {
      return [];
    }
    const items = productImages.map((x) => ({
      id: x.id,
      alt: x.alt,
      caption: x.caption,
      dimensions: x.dimensions,
      media_id: x.media_id,
      media_name: x.media_name,
      media_hash: x.media_hash,
      media_size: x.media_size,
      is_primary: x.is_primary,
    }));
    return items as Image[];
  }

  isPriceTypeByQty() {
    const { form } = this.state;
    return form.getValue('price_type') === PriceType.by_qty;
  }

  getCategoryOptions() {
    const { categories } = this.props;
    const activeCategories = [];
    const hiddenCategories = [];
    for (const x of categories) {
      x.is_hidden ? hiddenCategories.push(x) : activeCategories.push(x);
    }
    if (hiddenCategories.length === 0) {
      return [...activeCategories.map((x) => ({ value: String(x.id), title: x.name }))];
    }
    return [
      ...activeCategories.map((x) => ({ value: String(x.id), title: x.name })),
      { value: '-', title: '—', disabled: true },
      ...hiddenCategories.map((x) => ({ value: String(x.id), title: x.name })),
    ];
  }

  getUnitOptions() {
    return [...config.units.map((x) => ({ value: x, title: x }))];
  }

  getScaleOptions() {
    return [
      { value: '', title: 'Выбрать', disabled: true },
      ...config.priceScales.map((x) => ({ value: x.code, title: x.name })),
    ];
  }

  getPriceTypeOptions() {
    return [
      { value: PriceType.simple, title: formatPriceType(PriceType.simple) },
      { value: PriceType.by_qty, title: formatPriceType(PriceType.by_qty) },
      { value: PriceType.by_execution_time, title: formatPriceType(PriceType.by_execution_time) },
    ];
  }

  getTextsOptions() {
    const { productTabs } = this.props;
    return productTabs.map((x) => ({ value: x.code, title: this.getTextOptionName(x) }));
  }

  getTexts() {
    const { form } = this.state;
    const { productTabs } = this.props;
    const result: { [key: string]: string } = {};
    for (const x of productTabs) {
      result[x.code] = form.getValue(`texts_${x.code}`);
    }
    return result;
  }

  getTextOptionName(item: { code: string; name: string }) {
    const { form } = this.state;
    let name = item.name;
    if (form.getValue(`texts_${item.code}`)) {
      name += ' \u270F';
    }
    return name;
  }

  getPostImageData() {
    const { images } = this.state;
    return images.map((y) => ({
      alt: y.alt,
      caption: y.caption,
      dimensions: y.dimensions,
      media_id: y.media_id,
      media_hash: y.media_hash,
      is_primary: y.is_primary,
    }));
  }

  getPostSubcategoryData(form: Form) {
    const { categoryId } = this.props;
    return {
      category_id: form.getNumericValue('category') || categoryId,
      name: form.getValue('name'),
      slug: form.getValue('slug'),
      unit: form.getValue('unit'),
      redirect_url: form.getValue('redirect_url'),
      display_price: form.getMoneyValue('display_price') || null,
      display_price_crossed: form.getMoneyValue('display_price_crossed') || null,
      price_type: form.getValue('price_type') as PriceType,
      price_scale: form.getValue('price_scale'),
      texts: this.getTexts(),
      is_major: form.getValue('check_major') === 'on',
      is_hidden: form.getValue('check_hidden') === 'on',
      in_stock: form.getValue('check_stock') === 'on',
      is_nova: form.getValue('check_nova') === 'on',
      is_leader: form.getValue('check_leader') === 'on',
      details: {
        seo_title: form.getValue('seo_title') || undefined,
        seo_h1: form.getValue('seo_h1') || undefined,
        seo_description: form.getValue('seo_description') || undefined,
      },
    };
  }

  async loadData() {
    const { subcategory, app } = this.props;
    const sdk = app.getSdk();
    if (!subcategory) {
      return;
    }
    try {
      const subcategories = await sdk.getProductSubcategories();
      const productRefs = await sdk.getProductRefs({ subcategory_id: subcategory.id });
      const refSubcategoryIds = productRefs.map((x) => x.ref_subcategory_id);
      const productImages = await sdk.getProductImages({ subcategory_id: subcategory.id });
      this.setState({ productImages, subcategories, refSubcategoryIds });
    } catch (err) {
      app.handleError(err);
    }
  }
}
