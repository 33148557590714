import React from 'react';
import { App } from '../../app';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';

interface P {
  app: App;
}

interface S {
  isLoading: boolean;
  form: Form;
}

export class PLogin extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: createForm(),
    };
  }

  render() {
    return <div className="PLogin align-items-center d-flex h-100 justify-content-center">{this.renderContent()}</div>;
  }

  // event helpers

  async onLogin() {
    const { app } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    this.setState({ isLoading: true });
    try {
      const pd = this.getPostData(form);
      const token = await app.getSdk().setUser(pd);
      await app.setToken(token);
      this.goToApp();
    } catch (err) {
      this.setState({ isLoading: false });
      if (err.status === 401) {
        form = form.setError('email', '');
        form = form.setError('password', '');
        this.setState({ form });
      }
      app.handleError(err);
    }
  }

  onFormChange(form: Form) {
    form = form.resetErrors();
    this.setState({ form });
  }

  // render helpers

  renderContent() {
    const { form, isLoading } = this.state;
    return (
      <div className="card w-25">
        <h4 className="card-header">Гравировщик</h4>
        <div className="card-body">
          <FormGroup type="text" name="email" label="Email" form={form} onChange={(x) => this.onFormChange(x)} />
          <FormGroup
            type="password"
            name="password"
            label="Пароль"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <Button
            className="w-100 mb-2"
            type="primary"
            text="Вход"
            disabled={!this.isFilled(form) || isLoading}
            onClick={() => this.onLogin()}
          />
        </div>
      </div>
    );
  }

  // other helpers

  getPostData(form: Form) {
    return {
      email: form.getValue('email'),
      password: form.getValue('password'),
    };
  }

  isFilled(form: Form) {
    return Boolean(form.getValue('email')) && Boolean(form.getValue('password'));
  }

  goToApp() {
    const { app } = this.props;
    const history = app.getHistory();
    const startUrl = app.getStartUrl();
    const user = app.getUser();
    if (!user) {
      history.push('/');
      return;
    }
    if (startUrl.startsWith('/login')) {
      history.push('/');
      return;
    }
    history.push(startUrl);
  }
}
