import React from 'react';

import { App } from '../../app';
import { config } from '../../config';
import { DeliverySource } from '../../sdk/delivery.sdk';
import { Settings } from '../../sdk/settings.sdk';
import { formatList, formatMoney } from '../../services/fmt.service';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { ModalNav } from '../ModalNav';

import './MSettings.css';

interface P {
  app: App;
  settings: Settings;
  close: () => void;
}

interface S {
  isLoading: boolean;
  form: Form;
  nav: string;
}

const NAV_DELIVERY = 'delivery';
const NAV_EMAILS = 'emails';
const NAV_OTHER = 'other';

const NAVS = [
  { code: NAV_DELIVERY, title: 'Доставка' },
  { code: NAV_EMAILS, title: 'Уведомления' },
  { code: NAV_OTHER, title: 'Прочее' },
];

export class MSettings extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.settings),
      nav: NAV_DELIVERY,
    };
  }

  render() {
    return (
      <div className="MSettings modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderNav()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  onNavChange(nav: string) {
    this.setState({ nav });
  }

  async onSave() {
    const { app, close } = this.props;
    let { form } = this.state;
    form = form.resetErrors();
    form = form.trimValues();
    form = form.validateMoney('delivery_express_cost');
    form = form.validateMoney('delivery_mc_cost');
    form = form.validateMoney('delivery_mc_free_from');
    form = form.validateMoney('delivery_mo_free_from');
    if (form.hasError()) {
      this.setState({ form, nav: NAV_DELIVERY });
      alert(config.messages.invalidForm);
      return;
    }
    form = form.validateEmailList('emails_ord');
    form = form.validateEmailList('emails_cp');
    form = form.validateEmailList('emails_callback');
    form = form.validateEmailList('emails_review');
    if (form.hasError()) {
      this.setState({ form, nav: NAV_EMAILS });
      alert(config.messages.invalidForm);
      return;
    }
    form = form.validateMoney('ord_min_product_cost');
    if (form.hasError()) {
      this.setState({ form, nav: NAV_OTHER });
      alert(config.messages.invalidForm);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const pd = this.getPostData(form);
      await app.getSdk().updateSettings(pd);
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    const title = 'Настройки';
    return <ModalHeader title={title} close={close} />;
  }

  renderNav() {
    const { nav } = this.state;
    return <ModalNav items={NAVS} nav={nav} onChange={(x) => this.onNavChange(x)} />;
  }

  renderBody() {
    return <div className="modal-body">{this.renderContent()}</div>;
  }

  renderContent() {
    switch (this.state.nav) {
      case NAV_DELIVERY:
        return this.renderDelivery();
      case NAV_EMAILS:
        return this.renderEmails();
      case NAV_OTHER:
        return this.renderOther();
      default:
        return null;
    }
  }

  renderDelivery() {
    const { form } = this.state;
    return (
      <div>
        <div className="row">
          <FormGroup
            type="text"
            className="col-7"
            name="delivery_express_cost"
            label="Стоимость срочной доставки, ₽"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="text"
            className="col-5"
            name="delivery_express_time"
            label="Срок доставки"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="row MSettings_msk pt-2">
          <FormGroup
            type="text"
            className="col-7"
            name="delivery_mc_cost"
            label="Стоимость по Москве, ₽"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="text"
            className="col-5"
            name="delivery_mc_time"
            label="Срок доставки"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="row MSettings_msk mb-2">
          <FormGroup
            type="text"
            className="col"
            name="delivery_mc_free_from"
            label="Сумма заказа для бесплатной доставки по Москве, ₽"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <FormGroup
          type="text"
          name="delivery_mo_time"
          label="Срок доставки по МО"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
        <FormGroup
          type="text"
          name="delivery_mo_free_from"
          label="Сумма заказа для бесплатной доставки по МО, ₽"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
        <FormGroup
          type="select"
          name="delivery_source"
          label="Калькулятор доставки по Росcии"
          form={form}
          options={this.getDeliverySourceOptions()}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderEmails() {
    const { form } = this.state;
    return (
      <div>
        <FormGroup
          type="text"
          name="emails_ord"
          label="Email для заказов"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
        <FormGroup
          type="text"
          name="emails_cp"
          label="Email для КП"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
        <FormGroup
          type="text"
          name="emails_callback"
          label="Email для заказов обратного звонка"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
        <FormGroup
          type="text"
          name="emails_review"
          label="Email для новых отзывов"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderOther() {
    const { form } = this.state;
    return (
      <div>
        <FormGroup
          type="text"
          name="ord_min_product_cost"
          label="Минимальная сумма заказа, ₽"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
        <FormGroup
          type="text"
          name="sale_page_title"
          label="Акционная страница (в главном меню)"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(settings: Settings) {
    const values = settings.values;
    return createForm({
      delivery_express_cost: formatMoney(values.delivery_express_cost),
      delivery_express_time: values.delivery_express_time,
      delivery_mc_cost: formatMoney(values.delivery_mc_cost),
      delivery_mc_time: values.delivery_mc_time,
      delivery_mc_free_from: formatMoney(values.delivery_mc_free_from),
      delivery_mo_time: values.delivery_mo_time,
      delivery_mo_free_from: formatMoney(values.delivery_mo_free_from),
      delivery_source: values.delivery_source,
      ord_min_product_cost: formatMoney(values.ord_min_product_cost),
      sale_page_title: values.sale_page_title,
      emails_ord: formatList(values.emails_ord),
      emails_cp: formatList(values.emails_cp),
      emails_callback: formatList(values.emails_callback),
      emails_review: formatList(values.emails_review),
    });
  }

  getPostData(form: Form) {
    return {
      values: {
        delivery_express_cost: form.getMoneyValue('delivery_express_cost') || null,
        delivery_express_time: form.getValue('delivery_express_time'),
        delivery_mc_cost: form.getMoneyValue('delivery_mc_cost') || null,
        delivery_mc_time: form.getValue('delivery_mc_time'),
        delivery_mc_free_from: form.getMoneyValue('delivery_mc_free_from') || null,
        delivery_mo_time: form.getValue('delivery_mo_time'),
        delivery_mo_free_from: form.getMoneyValue('delivery_mo_free_from') || null,
        delivery_source: form.getValue('delivery_source'),
        ord_min_product_cost: form.getMoneyValue('ord_min_product_cost') || null,
        sale_page_title: form.getValue('sale_page_title'),
        emails_ord: form.getListValue('emails_ord'),
        emails_cp: form.getListValue('emails_cp'),
        emails_feedback: [],
        emails_callback: form.getListValue('emails_callback'),
        emails_review: form.getListValue('emails_review'),
      },
    };
  }

  getDeliverySourceOptions() {
    return [
      { value: DeliverySource.gr, title: 'Гравировщик' },
      { value: DeliverySource.c6v, title: 'С6V' },
      { value: DeliverySource.v3, title: 'V3' },
    ];
  }
}
