import React from 'react';

import { App } from '../../app';
import { config } from '../../config';
import { cn } from '../../lib/utils.lib';
import { PriceType, Product, ProductImage, ProductSubcategory } from '../../sdk/product.sdk';
import { formatMoney } from '../../services/fmt.service';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { ModalNav } from '../ModalNav';
import { PriceTable } from '../PriceTable';

import './MProduct.css';

interface P {
  app: App;
  product?: Product;
  subcategory: ProductSubcategory;
  close: (product?: Product) => void;
}

interface S {
  isLoading: boolean;
  form: Form;
  nav: string;
  productImages: ProductImage[];
}

const CHEKS = [
  { name: 'hidden', title: 'Временно скрыть' },
  { name: 'special_price', title: 'Специальная цена' },
  { name: 'prepayment', title: 'Предоплата' },
  { name: 'yandex_market', title: 'Яндекс.Маркет' },
  { name: 'google_market', title: 'Гугл.Маркет' },
];

const NAV_GENERAL = 'general';
const NAV_PRICES = 'prices';
const NAV_PHOTO_AND_COLOR = 'photo_and_color';
const NAV_YANDEX_MARKET = 'yandex_market';
const NAV_GOOGLE_MARKET = 'google_market';

export class MProduct extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.product),
      nav: NAV_GENERAL,
      productImages: [],
    };
  }

  render() {
    return (
      <div className="MProduct modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderNav()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  onNavChange(nav: string) {
    this.setState({ nav });
  }

  async onSave() {
    const { app, close, product, subcategory } = this.props;
    let { form } = this.state;
    form = form.resetErrors();
    form = form.trimValues();
    form = form.validateRequired('name');
    form = form.validateInteger('production_days');
    form = form.validateMoney('price', true);
    form = form.validateUrl('redirect_url');
    if (form.hasError()) {
      this.setState({ form, nav: NAV_GENERAL });
      alert(config.messages.invalidForm);
      return;
    }
    if (subcategory.price_type === PriceType.by_qty) {
      for (const x of this.getPriceScale()) {
        form = form.validateMoney(`price_${x}`, true);
      }
    }
    if (subcategory.price_type === PriceType.by_execution_time) {
      for (const x of config.executionTime) {
        form = form.validateMoney(`price_${x}`, true);
      }
    }
    if (form.hasError()) {
      this.setState({ form, nav: NAV_PRICES });
      alert(config.messages.invalidForm);
      return;
    }
    if (form.getValue('color_name') || form.getValue('color_value')) {
      form = form.validateRequired('color_value');
      form = form.validateHexColor('color_value');
    }
    if (form.getValue('color_value')) {
      form = form.validateRequired('color_name');
    }
    if (this.isYandexMarket(form) || this.isGoogleMarket(form)) {
      form = form.validateRequired('image_media_id');
    }
    if (form.hasError()) {
      this.setState({ form, nav: NAV_PHOTO_AND_COLOR });
      alert(config.messages.invalidForm);
      return;
    }
    if (this.isYandexMarket(form)) {
      form = form.validateRequired('yandex_market_name');
      form = form.validateRequired('yandex_market_description');
      form = form.validateRequired('yandex_market_pickup_days');
      form = form.validateInteger('yandex_market_pickup_days', 1, 9);
      if (form.hasError()) {
        this.setState({ form, nav: NAV_YANDEX_MARKET });
        alert(config.messages.invalidForm);
        return;
      }
    }
    if (this.isGoogleMarket(form)) {
      form = form.validateRequired('google_market_name');
      form = form.validateRequired('google_market_description');
      if (form.hasError()) {
        this.setState({ form, nav: NAV_GOOGLE_MARKET });
        alert(config.messages.invalidForm);
        return;
      }
    }
    this.setState({ isLoading: true });
    try {
      const sdk = app.getSdk();
      const pd = this.getPostData(form);
      const freshProduct = await (product ? sdk.updateProduct(product.id, pd) : sdk.createProduct(pd));
      close(freshProduct);
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, product } = this.props;
    const title = product ? product.name : 'Новый товар';
    return <ModalHeader title={title} close={close} />;
  }

  renderNav() {
    const { nav } = this.state;
    const navs = this.getNavs();
    if (navs.length === 1) {
      return null;
    }
    return <ModalNav items={navs} nav={nav} onChange={(x) => this.onNavChange(x)} />;
  }

  renderBody() {
    const className = this.getModalBodyModifier();
    return <div className={cn('modal-body', className)}>{this.renderContent()}</div>;
  }

  renderContent() {
    switch (this.state.nav) {
      case NAV_GENERAL:
        return this.renderGeneral();
      case NAV_PRICES:
        return this.renderPrices();
      case NAV_PHOTO_AND_COLOR:
        return this.renderPhotoAndColor();
      case NAV_YANDEX_MARKET:
        return this.renderYandexMarket();
      case NAV_GOOGLE_MARKET:
        return this.renderGoogleMarket();
      default:
        return null;
    }
  }

  renderGeneral() {
    const { form } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-8">
            <FormGroup
              type="text"
              name="name"
              label="Название"
              required
              form={form}
              onChange={(x) => this.onFormChange(x)}
            />
            <FormGroup
              type="text"
              name="cart_prompt"
              label="Пояснение для поля и файлов в корзине"
              form={form}
              onChange={(x) => this.onFormChange(x)}
            />
            <FormGroup
              type="text"
              name="cart_text"
              label="Поле в корзине"
              form={form}
              onChange={(x) => this.onFormChange(x)}
            />
            <FormGroup
              type="text"
              name="cart_files"
              label="Файлы в корзине"
              form={form}
              onChange={(x) => this.onFormChange(x)}
            />
            <div className="row">
              <FormGroup
                type="number"
                min={0}
                className={`col-${this.isSimplePrice() ? 6 : 12}`}
                name="production_days"
                label="Срок изготовления, дни"
                form={form}
                onChange={(x) => this.onFormChange(x)}
              />
              {this.isSimplePrice() && (
                <FormGroup
                  type="text"
                  className="col-6"
                  name="price"
                  label="Цена, ₽"
                  form={form}
                  onChange={(x) => this.onFormChange(x)}
                />
              )}
            </div>
          </div>
          <div className="col-4">
            <FormGroup
              type="checks"
              name="check"
              label="Параметры"
              form={form}
              checks={CHEKS}
              onChange={(x) => this.onFormChange(x)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderPrices() {
    const { subcategory } = this.props;
    const { form } = this.state;
    return (
      <PriceTable
        form={form}
        unit={subcategory.unit}
        priceType={subcategory.price_type}
        priceScale={subcategory.price_scale}
        onChange={(x) => this.onFormChange(x)}
      />
    );
  }

  renderPhotoAndColor() {
    const { form } = this.state;
    return (
      <div>
        <div className="row">
          <FormGroup
            className="col-12"
            type="select"
            options={this.getProductImageOptions()}
            name="image_media_id"
            label="Фото"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col-6"
            type="text"
            name="color_name"
            label="Название цвета"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col-6"
            type="color"
            name="color_value"
            label="HEX цвета"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
      </div>
    );
  }

  renderYandexMarket() {
    const { form } = this.state;
    return (
      <div className="MProduct_yandexMarket">
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="yandex_market_name"
            label="Название"
            required
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="yandex_market_pickup_days"
            label="Срок самовывоза, дни"
            required
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col"
            type="select"
            options={this.getYandexMarketStoreOptions()}
            name="yandex_market_store"
            label="Купить в магазине без заказа"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <FormGroup
          type="textarea"
          name="yandex_market_description"
          label="Описание"
          required
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderGoogleMarket() {
    const { form } = this.state;
    return (
      <div className="MProduct_googleMarket">
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="google_market_name"
            label="Название"
            required
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <FormGroup
          type="textarea"
          name="google_market_description"
          label="Описание"
          required
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(product?: Product) {
    const { subcategory } = this.props;
    if (!product) {
      return createForm({
        yandex_market_store: 'true',
      });
    }
    const init: any = {
      name: product.name,
      price: formatMoney(product.price, true),
      production_days: product.production_days,
      redirect_url: product.redirect_url,
      color_name: product.color_name,
      color_value: product.color_value,
      check_hidden: product.is_hidden ? 'on' : '',
      check_special_price: product.is_special_price ? 'on' : '',
      check_prepayment: product.is_prepayment ? 'on' : '',
      check_yandex_market: product.is_yandex_market ? 'on' : '',
      check_google_market: product.is_google_market ? 'on' : '',
      cart_prompt: product.details.cart_prompt,
      cart_text: product.details.cart_text,
      cart_files: product.details.cart_files,
      yandex_market_name: product.yandex_market.name,
      yandex_market_description: product.yandex_market.description,
      yandex_market_pickup_days: product.yandex_market.pickup_days,
      yandex_market_store: String(product.yandex_market.store ?? true),
      google_market_name: product.google_market.name,
      google_market_description: product.google_market.description,
      image_media_id: product.image_media_id,
    };
    if (subcategory.price_type === PriceType.by_qty) {
      this.getPriceScale().forEach((x) => (init[`price_${x}`] = formatMoney(product.prices_by_qty[x], true)));
    } else if (subcategory.price_type === PriceType.by_execution_time) {
      config.executionTime.forEach(
        (x) => (init[`price_${x}`] = formatMoney(product.prices_by_execution_time[x], true)),
      );
    }
    return createForm(init);
  }

  isSimplePrice() {
    const { subcategory } = this.props;
    return subcategory.price_type === PriceType.simple;
  }

  isYandexMarket(form: Form) {
    return form.getValue('check_yandex_market') === 'on';
  }

  isGoogleMarket(form: Form) {
    return form.getValue('check_google_market') === 'on';
  }

  getNavs() {
    const { form } = this.state;
    const navs = [{ code: NAV_GENERAL, title: 'Основное' }];
    if (!this.isSimplePrice()) {
      navs.push({ code: NAV_PRICES, title: 'Цены' });
    }
    navs.push({ code: NAV_PHOTO_AND_COLOR, title: 'Фото и цвет' });
    if (this.isYandexMarket(form)) {
      navs.push({ code: NAV_YANDEX_MARKET, title: 'Яндекс.Маркет' });
    }
    if (this.isGoogleMarket(form)) {
      navs.push({ code: NAV_GOOGLE_MARKET, title: 'Гугл.Маркет' });
    }
    return navs;
  }

  getProductImageOptions() {
    const { productImages } = this.state;
    return [
      { value: '', title: 'Выбрать', disabled: true },
      ...productImages.map((x) => ({ value: String(x.media_id), title: x.caption || x.alt || `Фото ${x.id}` })),
    ];
  }

  getYandexMarketStoreOptions() {
    return [
      { value: 'true', title: 'Можно' },
      { value: 'false', title: 'Нельзя' },
    ];
  }

  getModalBodyModifier() {
    const { subcategory } = this.props;
    switch (subcategory.price_type) {
      case PriceType.simple:
        return '';
      case PriceType.by_execution_time:
        return '_byExecutionTime';
      case PriceType.by_qty:
        return '_byQty';
    }
  }

  getPricesByQty() {
    const { form } = this.state;
    const { subcategory } = this.props;
    if (subcategory.price_type !== PriceType.by_qty) {
      return undefined;
    }
    const pricesByQty: { [key: string]: number } = {};
    for (const x of this.getPriceScale()) {
      const price = form.getMoneyValue(`price_${x}`);
      if (price) {
        pricesByQty[x] = price;
      }
    }
    return pricesByQty;
  }

  getPriceScale() {
    const { subcategory } = this.props;
    return config.priceScales.find((x) => x.code === subcategory.price_scale)!.values;
  }

  getPricesByExecutionTime() {
    const { form } = this.state;
    const pricesByExecutionTime: { [key: string]: number } = {};
    for (const x of config.executionTime) {
      const price = form.getMoneyValue(`price_${x}`);
      if (price) {
        pricesByExecutionTime[x] = price;
      }
    }
    return pricesByExecutionTime;
  }

  getPostData(form: Form) {
    const { subcategory } = this.props;
    return {
      subcategory_id: subcategory.id,
      name: form.getValue('name'),
      redirect_url: form.getValue('redirect_url'),
      price: form.getMoneyValue('price') || null,
      prices_by_qty: this.getPricesByQty(),
      prices_by_execution_time: this.getPricesByExecutionTime(),
      production_days: form.getNumericValue('production_days'),
      color_name: form.getValue('color_name'),
      color_value: form.getValue('color_value'),
      is_special_price: form.getValue('check_special_price') === 'on',
      is_prepayment: form.getValue('check_prepayment') === 'on',
      is_hidden: form.getValue('check_hidden') === 'on',
      is_yandex_market: this.isYandexMarket(form),
      is_google_market: this.isGoogleMarket(form),
      details: {
        cart_prompt: form.getValue('cart_prompt') || undefined,
        cart_text: form.getValue('cart_text') || undefined,
        cart_files: form.getValue('cart_files') || undefined,
      },
      yandex_market: this.getYandexMarketData(form),
      google_market: this.getGoogleMarketData(form),
      image_media_id: form.getNumericValue('image_media_id'),
    };
  }

  getYandexMarketData(form: Form) {
    const { product } = this.props;
    if (this.isYandexMarket(form)) {
      return {
        name: form.getValue('yandex_market_name'),
        description: form.getValue('yandex_market_description'),
        pickup_days: form.getNumericValue('yandex_market_pickup_days'),
        store: form.getValue('yandex_market_store') === 'true',
      };
    }
    return product ? product.yandex_market : {};
  }

  getGoogleMarketData(form: Form) {
    const { product } = this.props;
    if (this.isGoogleMarket(form)) {
      return {
        name: form.getValue('google_market_name'),
        description: form.getValue('google_market_description'),
      };
    }
    return product ? product.google_market : {};
  }

  async loadData() {
    const { subcategory, app } = this.props;
    const sdk = app.getSdk();
    try {
      const productImages = await sdk.getProductImages({ subcategory_id: subcategory.id });
      this.setState({ productImages });
    } catch (err) {
      app.handleError(err);
    }
  }
}
