import React from 'react';
import { format, getYear, isSameDay } from 'date-fns';

import { App } from '../../app';
import { config } from '../../config';
import { Holiday } from '../../sdk/holiday.sdk';
import { HolidayCalendar } from '../HolidayCalendar';
import { Button } from '../Button';

interface P {
  app: App;
}

interface S {
  items: Holiday[];
  year: number;
  isLoading: boolean;
}

export class PHolidays extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      year: getYear(new Date()),
      isLoading: false,
    };
  }

  render() {
    return (
      <div className="PHolidays">
        {this.renderTop()}
        {this.renderCalendars()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Настройки', undefined, config.tabs.settings);
    app.updateUI();
    await this.refreshData();
  }

  async componentDidUpdate(_prevProps: P, prevState: S) {
    const { year } = this.state;
    const { year: prevYear } = prevState;
    if (year !== prevYear) {
      await this.refreshData();
    }
  }

  // event handlers

  async onHolidayChange(day: Date) {
    const { app } = this.props;
    const { items } = this.state;
    const sdk = app.getSdk();
    try {
      const isHoliday = items.some((x) => isSameDay(new Date(x.date), day));
      if (isHoliday) {
        await sdk.deleteHoliday(format(day, 'yyyy-MM-dd'));
      } else {
        await sdk.createHoliday(format(day, 'yyyy-MM-dd'));
      }
      await this.refreshData();
    } catch (err) {
      app.handleError(err);
    }
  }

  async onYearChange(year: number) {
    this.setState({ year });
    await this.refreshData();
  }

  async onSync() {
    const { app } = this.props;
    const { year } = this.state;
    const sdk = app.getSdk();
    this.setState({ isLoading: true });
    try {
      const items = await sdk.autofillHolidays(year);
      this.setState({ items, isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderTop() {
    const { year, isLoading } = this.state;
    const currentYear = getYear(new Date());
    return (
      <div className="d-flex mb-3">
        <Button
          type={year === currentYear ? 'primary' : 'secondary'}
          text={`${currentYear}`}
          disabled={isLoading}
          className="mr-2"
          onClick={() => this.onYearChange(currentYear)}
        />
        <Button
          type={year === currentYear ? 'secondary' : 'primary'}
          text={`${currentYear + 1}`}
          disabled={isLoading}
          onClick={() => this.onYearChange(currentYear + 1)}
        />
        <div className="flex-fill text-right">
          <Button type="success" icon="sync-alt" disabled={isLoading} onClick={() => this.onSync()} />
        </div>
      </div>
    );
  }

  renderCalendars() {
    const { year } = this.state;
    return (
      <div>
        <div className="row">
          {this.renderCalendar(new Date(year, 0, 1))}
          {this.renderCalendar(new Date(year, 1, 1))}
          {this.renderCalendar(new Date(year, 2, 1))}
        </div>
        <div className="row">
          {this.renderCalendar(new Date(year, 3, 1))}
          {this.renderCalendar(new Date(year, 4, 1))}
          {this.renderCalendar(new Date(year, 5, 1))}
        </div>
        <div className="row">
          {this.renderCalendar(new Date(year, 6, 1))}
          {this.renderCalendar(new Date(year, 7, 1))}
          {this.renderCalendar(new Date(year, 8, 1))}
        </div>
        <div className="row">
          {this.renderCalendar(new Date(year, 9, 1))}
          {this.renderCalendar(new Date(year, 10, 1))}
          {this.renderCalendar(new Date(year, 11, 1))}
        </div>
      </div>
    );
  }

  renderCalendar(date: Date) {
    const { items } = this.state;
    return (
      <HolidayCalendar className="col" date={date} holidays={items} onChange={(day) => this.onHolidayChange(day)} />
    );
  }

  // other helpers

  getYearOptions() {
    const currentYear = getYear(new Date());
    const options = [];
    for (let y = config.holidayFirstYear; y <= currentYear + 1; y++) {
      options.push({ value: String(y), title: String(y) });
    }
    return options;
  }

  async refreshData() {
    const { app } = this.props;
    const { year } = this.state;
    const sdk = app.getSdk();
    try {
      const items = await sdk.getHolidays({ year });
      this.setState({ items });
    } catch (err) {
      app.handleError(err);
    }
  }
}
