import { Api } from '../services/api.service';

export interface Article {
  id: number;
  name: string;
  slug: string;
  text: string;
  redirect_url: string;
  is_hidden: boolean;
  details: ArticleDetails;
  category_id: number;
  category_name: string;
}

export interface ArticleCategory {
  id: number;
  name: string;
  is_hidden: boolean;
}

export interface ArticleDetails {
  short_name?: string;
  seo_title?: string;
  seo_h1?: string;
  seo_description?: string;
}

export interface ArticleSdk {
  getArticles(query: ArticleQuery): Promise<Article[]>;
  getArticle(id: number): Promise<Article>;
  createArticle(data: CreateArticleData): Promise<Article>;
  updateArticle(id: number, data: UpdateArticleData): Promise<Article>;
  deleteArticle(id: number): Promise<void>;
  moveArticleUp(id: number): Promise<void>;
  moveArticleDown(id: number): Promise<void>;
  getArticleCategories(): Promise<ArticleCategory[]>;
  getArticleCategory(id: number): Promise<ArticleCategory>;
  createArticleCategory(data: CreateArticleCategoryData): Promise<ArticleCategory>;
  updateArticleCategory(id: number, data: UpdateArticleCategoryData): Promise<ArticleCategory>;
  deleteArticleCategory(id: number): Promise<void>;
  moveArticleCategoryUp(id: number): Promise<void>;
  moveArticleCategoryDown(id: number): Promise<void>;
  updateArticleCategoryImage(id: number, data: UpdateArticleCategoryData): Promise<ArticleCategory>;
  deleteArticleCategoryImage(id: number): Promise<ArticleCategory>;
}

interface CreateArticleData {
  name: string;
  slug: string;
  text?: string;
  redirect_url?: string;
  is_hidden?: boolean;
  details?: ArticleDetails;
  category_id: number;
}

interface UpdateArticleData {
  name?: string;
  slug?: string;
  text?: string;
  redirect_url?: string;
  is_hidden?: boolean;
  details?: ArticleDetails;
  category_id?: number;
}

interface CreateArticleCategoryData {
  name: string;
  is_hidden?: boolean;
}

interface UpdateArticleCategoryData {
  name?: string;
  is_hidden?: boolean;
}

interface ArticleQuery {
  category_id?: number;
}

export function createArticleSdk(api: Api): ArticleSdk {
  return {
    async getArticles(query) {
      const data = await api.get('/article', query);
      return data.articles;
    },
    async getArticle(id) {
      const data = await api.get(`/article/${id}`);
      return data.article;
    },
    async createArticle(data) {
      const result = await api.post('/article', data);
      return result.article;
    },
    async updateArticle(id, data) {
      const result = await api.patch(`/article/${id}`, data);
      return result.article;
    },
    async deleteArticle(id) {
      await api.delete(`/article/${id}`);
    },
    async moveArticleUp(id) {
      await api.post(`/article/${id}/up`);
    },
    async moveArticleDown(id) {
      await api.post(`/article/${id}/down`);
    },
    async getArticleCategories() {
      const data = await api.get('/article/category');
      return data.article_categories;
    },
    async getArticleCategory(id) {
      const data = await api.get(`/article/category/${id}`);
      return data.article_category;
    },
    async createArticleCategory(data) {
      const result = await api.post('/article/category', data);
      return result.article_category;
    },
    async updateArticleCategory(id, data) {
      const result = await api.patch(`/article/category/${id}`, data);
      return result.article_category;
    },
    async deleteArticleCategory(id) {
      await api.delete(`/article/category/${id}`);
    },
    async moveArticleCategoryUp(id) {
      await api.post(`/article/category/${id}/up`);
    },
    async moveArticleCategoryDown(id) {
      await api.post(`/article/category/${id}/down`);
    },
    async updateArticleCategoryImage(id, data) {
      const result = await api.put(`/article/category/${id}/image`, data);
      return result.article_category;
    },
    async deleteArticleCategoryImage(id) {
      const result = await api.delete(`/article/category/${id}/image`);
      return result.article_category;
    },
  };
}
