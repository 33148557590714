import { Api } from '../services/api.service';

export interface Callback {
  id: number;
  name: string;
  phone: string;
  created_at: string;
}

export interface CallbackSdk {
  getCallbacks(): Promise<Callback[]>;
  deleteCallback(id: number): Promise<void>;
}

export function createCallbackSdk(api: Api): CallbackSdk {
  return {
    async getCallbacks() {
      const data = await api.get('/callback');
      return data.callbacks;
    },
    async deleteCallback(id) {
      await api.delete(`/callback/${id}`);
    },
  };
}
