import { Api } from '../services/api.service';

export interface Settings {
  values: SettingsValues;
  updated_at: string;
}

export interface SettingsValues {
  delivery_express_cost: number | null;
  delivery_express_time: string;
  delivery_mc_cost: number | null;
  delivery_mc_time: string;
  delivery_mc_free_from: number | null;
  delivery_mo_time: string;
  delivery_mo_free_from: number | null;
  delivery_source: string;
  ord_min_product_cost: number | null;
  sale_page_title: string;
  emails_ord: string[];
  emails_cp: string[];
  emails_feedback: string[];
  emails_callback: string[];
  emails_review: string[];
}

export interface SettingsSdk {
  getSettings(): Promise<Settings>;
  updateSettings(data: UpdateSettingsData): Promise<void>;
}

interface UpdateSettingsData {
  values: SettingsValues;
}

export function createSettingsSdk(api: Api): SettingsSdk {
  return {
    async getSettings() {
      const data = await api.get('/settings');
      return data.settings;
    },
    async updateSettings(data) {
      await api.put('/settings', data);
    },
  };
}
