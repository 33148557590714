import { City, Region } from '../sdk/delivery.sdk';

export function sortCities(cities: City[]) {
  const moscowIndex = cities.findIndex(isMoscow);
  if (moscowIndex <= 0) {
    return cities;
  }
  const sortedCities = [...cities];
  const moscow = sortedCities[moscowIndex];
  sortedCities.splice(moscowIndex, 1);
  sortedCities.unshift(moscow);
  return sortedCities;
}

export function isMo(region: Region) {
  return region === Region.mo;
}

// private

function isMoscow(city: City) {
  return city.name === 'Москва';
}
