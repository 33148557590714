import React from 'react';
import { App } from '../app';
import { Button } from './Button';
import { List } from './List';
import { ProductSubcategory, ProductCategory } from '../sdk/product.sdk';

interface P {
  app: App;
  isLoading: boolean;
  subcategoryId?: number;
  categories: ProductCategory[];
  subcategories: ProductSubcategory[];
  refSubcategoryIds: number[];
  onChange: (refSubcategoryIds: number[]) => void;
}

interface S {
  refCategoryId: string;
  refSubcategoryId: string;
}

export class ProductRefEditor extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      refCategoryId: '',
      refSubcategoryId: '',
    };
  }

  render() {
    return (
      <div className="ProductRefEditor">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  // event handlers

  onAdd() {
    const { refSubcategoryIds, onChange } = this.props;
    const { refSubcategoryId } = this.state;
    const newRefSubcategoryIds = [...refSubcategoryIds, Number(refSubcategoryId)];
    onChange(newRefSubcategoryIds);
    this.setState({ refSubcategoryId: '' });
  }

  onMoveUp(item: ProductSubcategory) {
    const { onChange, refSubcategoryIds } = this.props;
    const oldIndex = refSubcategoryIds.indexOf(item.id);
    if (oldIndex === 0) {
      return;
    }
    const newRefSubcategoryIds = [...refSubcategoryIds];
    newRefSubcategoryIds.splice(oldIndex, 1);
    newRefSubcategoryIds.splice(oldIndex - 1, 0, item.id);
    onChange(newRefSubcategoryIds);
  }

  onMoveDown(item: ProductSubcategory) {
    const { onChange, refSubcategoryIds } = this.props;
    const oldIndex = refSubcategoryIds.indexOf(item.id);
    if (oldIndex === refSubcategoryIds.length - 1) {
      return;
    }
    const newRefSubcategoryIds = [...refSubcategoryIds];
    newRefSubcategoryIds.splice(oldIndex, 1);
    newRefSubcategoryIds.splice(oldIndex + 1, 0, item.id);
    onChange(newRefSubcategoryIds);
  }

  onDelete(item: ProductSubcategory) {
    const { onChange, refSubcategoryIds } = this.props;
    const newRefSubcategoryIds = refSubcategoryIds.filter((x) => x !== item.id);
    onChange(newRefSubcategoryIds);
  }

  async onRefCategorySelectChange(refCategoryId: string) {
    this.setState({ refCategoryId });
  }

  async onRefSubcategorySelectChange(refSubcategoryId: string) {
    this.setState({ refSubcategoryId });
  }

  // render helpers

  renderList() {
    const { subcategories, refSubcategoryIds } = this.props;
    const items = refSubcategoryIds.map((x) => subcategories.find((y) => y.id === x));
    return (
      <List
        columns={this.getColumns()}
        buttons={this.getButtons()}
        onGetItemClassName={(item) => this.getSubcategoryRefClassName(item)}
        items={items}
      />
    );
  }

  renderTop() {
    const { isLoading } = this.props;
    const { refCategoryId, refSubcategoryId } = this.state;
    return (
      <div className="row mb-3">
        {this.renderRefCategorySelect(refCategoryId)}
        {this.renderRefSubcategorySelect(refSubcategoryId)}
        <div className="col-2">
          <Button
            className="w-100"
            type="primary"
            text="Добавить"
            disabled={refSubcategoryId === '' || isLoading}
            onClick={() => this.onAdd()}
          />
        </div>
      </div>
    );
  }

  renderRefCategorySelect(refCategoryId: string) {
    const { isLoading, subcategoryId } = this.props;
    const options = this.getRefCategoryOptions();
    return (
      <div className="col-5">
        <select
          className="form-control"
          value={refCategoryId}
          disabled={!subcategoryId || isLoading}
          onChange={(e) => this.onRefCategorySelectChange(e.target.value)}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.title}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderRefSubcategorySelect(refSubcategoryId: string) {
    const { isLoading } = this.props;
    const { refCategoryId } = this.state;
    const options = this.getRefSubcategoryOptions();
    return (
      <div className="col-5">
        <select
          className="form-control"
          value={refSubcategoryId}
          disabled={refCategoryId === '' || isLoading}
          onChange={(e) => this.onRefSubcategorySelectChange(e.target.value)}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.title}
            </option>
          ))}
        </select>
      </div>
    );
  }

  // other helpers

  getRefCategoryOptions() {
    const { categories } = this.props;
    return [
      { value: '', title: 'Выбрать категорию', disabled: true },
      ...categories.map((x) => ({ value: String(x.id), title: x.name, disabled: false })),
    ];
  }

  getRefSubcategoryOptions() {
    const { subcategoryId, subcategories } = this.props;
    const { refCategoryId } = this.state;
    const filteredSubcategories = subcategories.filter(
      (x) =>
        subcategoryId &&
        x.id !== subcategoryId &&
        String(x.category_id) === refCategoryId &&
        !this.hasRefSubcategory(x),
    );
    return [
      { value: '', title: 'Выбрать подкатегорию', disabled: true },
      ...filteredSubcategories.map((x) => ({ value: String(x.id), title: x.name, disabled: false })),
    ];
  }

  hasRefSubcategory(subcategory: ProductSubcategory) {
    const { refSubcategoryIds } = this.props;
    return refSubcategoryIds.find((x) => x === subcategory.id) !== undefined;
  }

  getColumns() {
    const columns = [];
    columns.push({
      name: 'Категория',
      value: (item: ProductSubcategory) => item.category_name,
    });
    columns.push({
      name: 'Подкатегория',
      value: (item: ProductSubcategory) => item.name,
    });
    return columns;
  }

  getButtons() {
    return [
      {
        icon: 'arrow-up',
        action: (refSubcategory: ProductSubcategory) => this.onMoveUp(refSubcategory),
      },
      {
        icon: 'arrow-down',
        action: (refSubcategory: ProductSubcategory) => this.onMoveDown(refSubcategory),
      },
      {
        icon: 'times',
        action: (refSubcategory: ProductSubcategory) => this.onDelete(refSubcategory),
      },
    ];
  }

  getSubcategoryRefClassName(item: ProductSubcategory) {
    return item.is_hidden ? 'text-black-50' : '';
  }
}
