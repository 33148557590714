import React from 'react';
import { App } from '../../app';
import { config } from '../../config';
import { DeliveryType, Order, OrderStatus, OrderType, PaymentType } from '../../sdk/order.sdk';
import { getPeriodFilterOptions, filterItemsByPeriod } from '../../services/filter.service';
import { List } from '../List';
import { MOrder } from '../modals/MOrder';
import { Select } from '../Select';

import {
  formatDate,
  formatDeliveryType,
  formatDeliveryTypeShort,
  formatMoney,
  formatPaymentType,
  formatPaymentTypeShort,
  formatTime,
  formatPaymentStatus,
} from '../../services/fmt.service';

interface P {
  app: App;
  match: any;
}

interface S {
  items: Order[];
  search: string;
  periodFilter: string;
  deliveryFilter: string;
  paymentFilter: string;
  page: number;
}

export class POrders extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      search: '',
      periodFilter: 'all',
      deliveryFilter: 'all',
      paymentFilter: 'all',
      page: 1,
    };
  }

  render() {
    return (
      <div className="POrders">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Заказы', undefined, config.tabs.orders);
    app.updateUI();
    await this.refreshData();
  }

  async componentDidUpdate(prevProps: P) {
    const orderType = this.getOrderType();
    const prevOrderType = prevProps.match.params.type;
    if (orderType !== prevOrderType) {
      this.setState({ items: [], search: '', periodFilter: 'all', deliveryFilter: 'all', paymentFilter: 'all' });
      await this.refreshData();
    }
  }

  // event handlers

  onSearchChange(e: any) {
    this.setState({ search: e.target.value, page: 1 });
  }

  onPeriodFilterChange(value: string) {
    this.setState({ periodFilter: value, page: 1 });
  }

  onDeliveryFilterChange(value: string) {
    this.setState({ deliveryFilter: value, page: 1 });
  }

  onPaymentFilterChange(value: string) {
    this.setState({ paymentFilter: value, page: 1 });
  }

  onGetItemMenu(order: Order) {
    const { app } = this.props;
    const orderType = this.getOrderType();
    return [
      {
        name: 'Открыть',
        action: () => this.runPrimaryAction(order),
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg =
            orderType === OrderType.regular
              ? `Удалить заказ # ${order.display_id}?`
              : `Удалить коммерческое предложение # ${order.display_id}?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await app.getSdk().deleteOrder(order.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(order: Order) {
    await this.runPrimaryAction(order);
  }

  onPageChange(page: number) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    const orderType = this.getOrderType();
    return (
      <div className="d-flex mb-3">
        <input
          className="form-control"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
        {this.renderPeriodFilter()}
        {this.renderDeliveryFilter()}
        {orderType === OrderType.regular && this.renderPaymentFilter()}
      </div>
    );
  }

  renderPeriodFilter() {
    const { items } = this.state;
    return (
      <Select
        items={getPeriodFilterOptions(items, 'sent_at')}
        className="ml-3"
        value={this.state.periodFilter}
        onChange={(value) => this.onPeriodFilterChange(value)}
      />
    );
  }

  renderDeliveryFilter() {
    return (
      <Select
        items={this.getDeliveryFilterOptions()}
        className="ml-3"
        value={this.state.deliveryFilter}
        onChange={(value) => this.onDeliveryFilterChange(value)}
      />
    );
  }

  renderPaymentFilter() {
    const orderType = this.getOrderType();
    return (
      <Select
        items={this.getPaymentFilterOptions()}
        className="ml-3"
        value={this.state.paymentFilter}
        disabled={orderType === OrderType.cp}
        onChange={(value) => this.onPaymentFilterChange(value)}
      />
    );
  }

  renderList() {
    const { items, search, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={this.getFilteredItems(items)}
        search={search}
        pageNumber={page}
        pageSize={config.itemsPerPage}
        onGetItemMenu={(item) => this.onGetItemMenu(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(x) => this.onPageChange(x)}
      />
    );
  }

  // other helpers

  getColumns() {
    const orderType = this.getOrderType();
    const columns = [
      {
        name: '#',
        value: (item: Order) => item.display_id,
        headClassName: 'w-100px',
      },
      {
        name: 'Дата',
        value: (item: Order) => formatDate(item.sent_at),
        headClassName: 'w-120px',
      },
      {
        name: 'Время',
        value: (item: Order) => formatTime(item.sent_at),
        headClassName: 'w-100px',
      },
      {
        name: 'Клиент',
        value: (item: Order) => (orderType === OrderType.regular ? item.name : item.company_name),
      },
      {
        name: 'Сумма',
        value: (item: Order) => formatMoney(item.total, true, true),
        headClassName: 'w-140px',
      },
      {
        name: 'Доставка',
        value: (item: Order) => formatDeliveryTypeShort(item.delivery_type),
        headClassName: 'w-180px',
      },
    ];
    if (orderType === OrderType.regular) {
      columns.push({
        name: 'Оплата',
        value: (item: Order) => formatPaymentTypeShort(item.payment_type),
        headClassName: 'w-140px',
      });
      columns.push({
        name: 'Статус оплаты',
        value: (item: Order) => formatPaymentStatus(item.payment_status),
        headClassName: 'w-140px',
      });
    }
    return columns;
  }

  getFilteredItems(items: Order[]) {
    const { periodFilter } = this.state;
    let result = items;
    result = filterItemsByPeriod(periodFilter, items, 'sent_at');
    result = this.getDeliveryFilteredItems(result);
    result = this.getPaymentFilteredItems(result);
    return result;
  }

  getDeliveryFilteredItems(items: Order[]) {
    if (this.state.deliveryFilter === 'all') {
      return items;
    }
    return items.filter((item) => String(item.delivery_type) === this.state.deliveryFilter);
  }

  getPaymentFilteredItems(items: Order[]) {
    if (this.state.paymentFilter === 'all') {
      return items;
    }
    return items.filter((item) => String(item.payment_type) === this.state.paymentFilter);
  }

  getDeliveryFilterOptions() {
    return [
      { value: 'all', title: 'Все способы доставки' },
      ...Object.keys(DeliveryType).map((x) => ({ value: x, title: formatDeliveryType(x as DeliveryType) })),
    ];
  }

  getPaymentFilterOptions() {
    return [
      { value: 'all', title: 'Все способы оплаты' },
      ...Object.keys(PaymentType).map((x) => ({ value: x, title: formatPaymentType(x as PaymentType) })),
    ];
  }

  getOrderType() {
    const { match } = this.props;
    return match.params.type;
  }

  async runPrimaryAction(order: Order) {
    const { app } = this.props;
    const orderWithText = await app.getSdk().getOrder(order.id);
    await app.showModal(MOrder, { order: orderWithText });
    await this.refreshData();
  }

  async refreshData() {
    const { app } = this.props;
    const orderType = this.getOrderType();
    try {
      const items = await app.getSdk().getOrders({ type: orderType, status: OrderStatus.sent });
      this.setState({ items });
    } catch (err) {
      app.handleError(err);
    }
  }
}
