import { config } from '../config';

export interface Api {
  get(path: string, params?: KeyValue): Promise<KeyValue>;
  post(path: string, params?: KeyValue): Promise<KeyValue>;
  put(path: string, params?: KeyValue): Promise<KeyValue>;
  patch(path: string, params?: KeyValue): Promise<KeyValue>;
  delete(path: string, params?: KeyValue): Promise<KeyValue>;
  upload(path: string, file: File): Promise<KeyValue>;
}

interface KeyValue {
  [key: string]: any;
}

interface RequestOptions {
  method: string;
  path: string;
  query?: KeyValue;
  token?: string;
  data?: KeyValue;
  formData?: FormData;
}

export function createApi(token?: string): Api {
  return {
    get(path, params) {
      return sendRequest({ token, path, method: 'GET', query: params });
    },
    post(path, params) {
      return sendRequest({ token, path, method: 'POST', data: params });
    },
    put(path, params) {
      return sendRequest({ token, path, method: 'PUT', data: params });
    },
    patch(path, params) {
      return sendRequest({ token, path, method: 'PATCH', data: params });
    },
    delete(path, params) {
      return sendRequest({ token, path, method: 'DELETE', data: params });
    },
    upload(path, file) {
      const formData = new FormData();
      formData.append('file', file);
      return sendRequest({ token, path, method: 'POST', formData });
    },
  };
}

async function sendRequest(options: RequestOptions): Promise<KeyValue> {
  const url = getUrl(options.path, options.query);
  const init: any = { method: options.method, headers: {} };
  if (options.token) {
    init.headers['X-Token'] = options.token;
  }
  if (options.data) {
    init.headers['Content-Type'] = 'application/json';
    init.body = JSON.stringify(options.data);
  }
  if (options.formData) {
    init.body = options.formData;
  }
  const response = await fetch(url, init);
  return processResponse(response);
}

function getUrl(path: string, query?: KeyValue) {
  const url = config.api + path;
  if (!query) {
    return url;
  }
  const pairs = Object.keys(query)
    .filter((key) => query[key] != null)
    .map((key) => `${key}=${encodeURIComponent(query[key])}`);
  if (pairs.length === 0) {
    return url;
  }
  const queryPart = pairs.join('&');
  return `${url}?${queryPart}`;
}

async function processResponse(response: Response): Promise<KeyValue> {
  const data = await getResponseData(response);
  if (response.status !== 200) {
    const error = Error(`API Error ${response.status}`);
    (error as any).status = response.status;
    (error as any).data = data;
    throw error;
  }
  return data;
}

async function getResponseData(response: Response): Promise<KeyValue> {
  const text = await response.text();
  if (!text) {
    return {};
  }
  const first = text.charAt(0);
  if (first !== '{') {
    return { text };
  }
  return JSON.parse(text);
}
