import validator from 'validator';
import { ImageDimensions } from '../lib/utils.lib';

export function checkImageDimensions(fileDimensions: ImageDimensions, validDimensions: ImageDimensions[]) {
  const validSizesList = validDimensions.map((x) => `${x.width}x${x.height}`).join(', ');
  for (const dimentions of validDimensions) {
    if (fileDimensions.width === dimentions.width && fileDimensions.height === dimentions.height) {
      return undefined;
    }
  }
  return (
    `Ошибка загрузки изображения.\n` +
    `Размер изображения: ${fileDimensions.width}x${fileDimensions.height}\n` +
    `Ожидаемые размеры: ${validSizesList}`
  );
}

export function isValidUrl(url: string) {
  const isValidFullUrl = validator.isURL(url, {
    protocols: ['http', 'https'],
    require_protocol: true,
  });
  if (isValidFullUrl) {
    return true;
  }
  const isValidShortUrl = validator.isURL(url, {
    require_host: false,
    host_whitelist: [],
  });
  return isValidShortUrl;
}

export function isValidHref(href: string) {
  if (isValidUrl(href)) {
    return true;
  }
  const isValidTel = href.match(/^tel:\+?\d+$/);
  if (isValidTel) {
    return true;
  }
  const isValidEmail = href.match(/^mailto:\S+@\S+\.\S+$/);
  return isValidEmail;
}
