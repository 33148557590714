import React from 'react';
import { App } from '../../app';
import { Banner } from '../../sdk/banner.sdk';
import { Button } from '../Button';
import { List } from '../List';
import { MBanner } from '../modals/MBanner';
import { config } from '../../config';

interface P {
  app: App;
}

interface S {
  items: Banner[];
  search: string;
}

export class PBanners extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      search: '',
    };
  }

  render() {
    return (
      <div className="PBanners">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Статический контент', undefined, config.tabs.static);
    app.updateUI();
    await this.refreshData();
  }

  // event handlers

  async onAdd() {
    const { app } = this.props;
    await app.showModal(MBanner);
    await this.refreshData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(banner: Banner) {
    const { app } = this.props;
    return [
      {
        name: 'Редактировать',
        action: () => this.runPrimaryAction(banner),
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить баннер "${banner.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            const sdk = app.getSdk();
            await sdk.deleteBanner(banner.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(banner: Banner) {
    await this.runPrimaryAction(banner);
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        buttons={this.getMoveButtons()}
        onGetItemMenu={(item) => this.onGetItemMenu(item)}
        onGetItemClassName={(item) => this.getItemClassName(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'Название',
        value: (item: Banner) => item.name,
      },
    ];
  }

  getMoveButtons() {
    const { app } = this.props;
    const sdk = app.getSdk();
    return [
      {
        icon: 'arrow-up',
        action: async (banner: Banner) => {
          try {
            await sdk.moveBannerUp(banner.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
      {
        icon: 'arrow-down',
        action: async (banner: Banner) => {
          try {
            await sdk.moveBannerDown(banner.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  getItemClassName(banner: Banner) {
    return banner.is_hidden ? 'text-black-50' : '';
  }

  async runPrimaryAction(banner: Banner) {
    const { app } = this.props;
    await app.showModal(MBanner, { banner });
    await this.refreshData();
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const items = await app.getSdk().getBanners();
      this.setState({ items });
    } catch (err) {
      app.handleError(err);
    }
  }
}
