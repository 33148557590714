import pkg from '../package.json';
import { ExecutionTime } from './sdk/order.sdk';
import { PriceScale } from './sdk/product.sdk';

const uniqueConstraintErrors: { [key: string]: string } = {
  account_email_key: 'Администратор с указанным email уже существует',
  article_category_name_key: 'Категория с указанным названием уже существует',
  article_name_key: 'Статья с указанным названием уже существует',
  article_slug_key: 'Статья с указанным ЧПУ уже существует',
  city_region_name_key: 'В данном регионе уже существует город с указанным названием',
  product_category_name_key: 'Категория с указанным названием уже существует',
  product_category_slug_key: 'Категория с указанным ЧПУ уже существует',
  product_subcategory_category_id_name_key: 'Подкатегория с указанным названием уже существует',
  product_subcategory_category_id_slug_key: 'Подкатегория с указанным ЧПУ уже существует',
  product_subcategory_id_name_key: 'Товар с указанным названием уже существует',
  product_tab_code_key: 'Вкладка с указанным кодом уже существует',
  static_page_name_key: 'Страница с указанным названием уже существует',
  static_page_slug_key: 'Страница с указанным ЧПУ уже существует',
  transport_company_name_key: 'Транспортная компания с указанным названием уже существует',
  transport_company_warehouse_transport_company_id_city_id_key:
    'Для данного города уже существует склад с указанной транспортной компанией',
};

export const config = {
  version: pkg.version,
  api: getApiBaseUrl(),
  front: getFrontBaseUrl(),
  fs: 'https://fs.cranbee.pro/gravirovshik',
  globalSearchLimit: 100,
  homePageId: 1,
  yandexMapsUrl: '//api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=773d760c-3274-4584-bb33-b34064bd96d0',
  itemsPerPage: 100,
  holidayFirstYear: 2020,
  messages: {
    invalidForm: 'Пожалуйста, исправьте неверно заполненные поля',
  },
  units: ['шт.', 'уп.', 'боб.', 'кг', 'м'],
  executionTime: [ExecutionTime.d3, ExecutionTime.d2, ExecutionTime.d1, ExecutionTime.h1],
  priceScales: [
    {
      code: PriceScale.scale_100,
      name: '1 ... 100',
      values: [1, 2, 3, 5, 10, 20, 25, 50, 100],
    },
    {
      code: PriceScale.scale_1000,
      name: '1 ... 1 000',
      values: [1, 25, 50, 100, 200, 250, 300, 500, 1000],
    },
    {
      code: PriceScale.scale_10000,
      name: '1 ... 10 000',
      values: [1, 100, 250, 500, 1000, 3000, 5000, 10000],
    },
  ],
  tabs: {
    delivery: [
      {
        title: 'По Москве и Области',
        url: '/delivery/cities/mo',
      },
      {
        title: 'По России',
        url: '/delivery/cities/ru',
      },
      {
        title: 'В Казахстан',
        url: '/delivery/cities/kz',
      },
      {
        title: 'Транспортные компании',
        url: '/delivery/companies',
      },
    ],
    orders: [
      {
        title: 'Покупки',
        url: '/orders/regular',
      },
      {
        title: 'КП',
        url: '/orders/cp',
      },
    ],
    requests: [
      {
        title: 'Заказ звонка',
        url: '/requests/callback',
      },
      //{
      //  title: 'Обратная связь',
      //  url: '/requests/feedback',
      //},
      {
        title: 'Отзывы',
        url: '/requests/reviews',
      },
    ],
    static: [
      {
        title: 'Статические страницы',
        url: '/static/pages',
      },
      {
        title: 'Баннеры',
        url: '/static/banners',
      },
    ],
    settings: [
      {
        title: 'Параметры',
        url: '/settings/params',
      },
      {
        title: 'Администраторы',
        url: '/settings/accounts',
      },
      {
        title: 'Редиректы',
        url: '/settings/redirects',
      },
      {
        title: 'Вкладки',
        url: '/settings/tabs',
      },
      {
        title: 'Выходные',
        url: '/settings/holidays',
      },
      //{
      //  title: 'YML',
      //  url: '/settings/yml',
      //},
    ],
  },
  validImageDimensions: {
    banner: [{ width: 1138, height: 245 }],
    gmt: [{ width: 300, height: 300 }],
    news: [{ width: 320, height: 320 }],
    product: [
      { width: 519, height: 360 },
      { width: 1038, height: 720 },
    ],
  },

  uniqueConstraintErrors,
};

// private

function getApiBaseUrl() {
  // if (window.location.hostname === 'admin.gravirovshik.ru') {
  //   return 'https://api.gravirovshik.ru';
  // }
  // return 'https://gravirovshik-api.cranbee.pro';
  return 'https://api.gravirovshik.ru';
}

function getFrontBaseUrl() {
  // if (window.location.hostname === 'admin.gravirovshik.ru') {
  //   return 'https://www.gravirovshik.ru';
  // }
  // return 'https://gravirovshik-front.cranbee.pro';
  return 'https://www.gravirovshik.ru';
}
