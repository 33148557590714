import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { App, Modal } from '../app';
import { cn } from '../lib/utils.lib';
import { PAccounts } from './pages/PAccounts';
import { PArticleCategories } from './pages/PArticleCategories';
import { PArticles } from './pages/PArticles';
import { PBanners } from './pages/PBanners';
import { PCallbacks } from './pages/PCallbacks';
import { PCities } from './pages/PCities';
import { PFeedbacks } from './pages/PFeedbacks';
import { PLogin } from './pages/PLogin';
import { PNews } from './pages/PNews';
import { POrders } from './pages/POrders';
import { PProductCategories } from './pages/PProductCategories';
import { PProducts } from './pages/PProducts';
import { PProductSubcategories } from './pages/PProductSubcategories';
import { PSearch } from './pages/PSearch';
import { PSettings } from './pages/PSettings';
import { PStaticPages } from './pages/PStaticPages';
import { PTransportCompanies } from './pages/PTransportCompanies';
import { PWarehouses } from './pages/PWarehouses';
import { PYml } from './pages/PYml';
import { PageWrapper } from './PageWrapper';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';
import { PRedirects } from './pages/PRedirects';
import { PProductTabs } from './pages/PProductTabs';
import { PHolidays } from './pages/PHolidays';
import { PRerviews } from './pages/PReviews';

interface P {
  app: App;
}

export class Root extends React.Component<P> {
  constructor(props: P) {
    super(props);
    props.app.setRootComponent(this);
  }

  render() {
    const { app } = this.props;
    return (
      <Router history={app.getHistory()}>
        <Switch>
          {createAppRoute(app, PLogin, '/login')}
          <Auth app={app} />
        </Switch>
      </Router>
    );
  }
}

function Auth({ app }: P) {
  if (!app.getUser()) {
    return <Redirect to="/login" />;
  }
  return (
    <div className="d-flex h-100">
      <Sidebar app={app} />
      <div className="d-flex flex-column flex-fill">
        <Topbar app={app} />
        <PageWrapper app={app}>
          <Switch>
            {createAppRoute(app, PProductCategories, '/catalog')}
            {createAppRoute(app, PProductSubcategories, '/catalog/:categoryId/subcategories')}
            {createAppRoute(app, PProducts, '/catalog/:categoryId/subcategories/:subcategoryId/products')}
            {createAppRoute(app, POrders, '/orders/:type')}
            {createAppRoute(app, PCallbacks, '/requests/callback')}
            {createAppRoute(app, PFeedbacks, '/requests/feedback')}
            {createAppRoute(app, PRerviews, '/requests/reviews')}
            {createAppRoute(app, PCities, '/delivery/cities/:region')}
            {createAppRoute(app, PWarehouses, '/delivery/cities/:region/:cityId/warehouses')}
            {createAppRoute(app, PTransportCompanies, '/delivery/companies')}
            {createAppRoute(app, PNews, '/news')}
            {createAppRoute(app, PArticleCategories, '/articles')}
            {createAppRoute(app, PArticles, '/articles/:categoryId')}
            {createAppRoute(app, PStaticPages, '/static/pages')}
            {createAppRoute(app, PBanners, '/static/banners')}
            {createAppRoute(app, PSearch, '/search')}
            {createAppRoute(app, PSettings, '/settings/params')}
            {createAppRoute(app, PAccounts, '/settings/accounts')}
            {createAppRoute(app, PRedirects, '/settings/redirects')}
            {createAppRoute(app, PProductTabs, '/settings/tabs')}
            {createAppRoute(app, PHolidays, '/settings/holidays')}
            {createAppRoute(app, PYml, '/settings/yml')}
            {createAppRoute(app, RefirectToHome, '*')}
          </Switch>
        </PageWrapper>
        {renderModals(app)}
      </div>
    </div>
  );
}

function RefirectToHome() {
  return <Redirect to={'/catalog'} />;
}

function createAppRoute(app: App, component: any, path: any) {
  const render = (props: any) => React.createElement(component, { app, match: props.match });
  return <Route path={path} exact render={render} />;
}

function renderModals(app: App) {
  const modals = app.getModals();
  const n = modals.length;
  if (n === 0) {
    return null;
  }
  return (
    <div>
      {modals.map((m, i) => renderModal(m, i === n - 1))}
      <div key="backdrop" className="modal-backdrop show"></div>
    </div>
  );
}

function renderModal(modal: Modal, isVisible: boolean) {
  const { component, props, key } = modal;
  const className = cn('modal', isVisible ? 'd-block' : '');
  return (
    <div key={key} className={className}>
      {React.createElement(component, props)}
    </div>
  );
}
