import { Api } from '../services/api.service';

export interface City {
  id: number;
  name: string;
  region: Region;
  delivery_cost: number | null;
  free_from: number | null;
}

export interface TransportCompany {
  id: number;
  name: string;
}

export interface TransportCompanyWarehouse {
  id: number;
  details: TransportCompanyWarehouseDetails;
  transport_company_id: number;
  transport_company_name: string;
  city_id: number;
  city_name: string;
}

export enum Region {
  mc = 'mc',
  mo = 'mo',
  ru = 'ru',
  kz = 'kz',
}

export enum DeliverySource {
  gr = 'gr',
  c6v = 'c6v',
  v3 = 'v3',
}

export interface TransportCompanyWarehouseDetails {
  delivery_time?: string;
  delivery_cost?: number;
  address?: string;
  phones?: string;
  map?: { lat: number; lng: number; zoom: number };
}

export interface DeliverySdk {
  getCities(query?: CityQuery): Promise<City[]>;
  getCity(id: number): Promise<City>;
  createCity(data: CreateCityData): Promise<City>;
  updateCity(id: number, data: UpdateCityData): Promise<City>;
  deleteCity(id: number): Promise<void>;
  getTransportCompanies(): Promise<TransportCompany[]>;
  getTransportCompany(id: number): Promise<TransportCompany>;
  createTransportCompany(data: CreateTransportCompanyData): Promise<TransportCompany>;
  updateTransportCompany(id: number, data: UpdateTransportCompanyData): Promise<TransportCompany>;
  deleteTransportCompany(id: number): Promise<void>;
  getWarehouses(query?: TransportCompanyWarehouseQuery): Promise<TransportCompanyWarehouse[]>;
  getWarehouse(id: number): Promise<TransportCompanyWarehouse>;
  createWarehouse(data: CreateTransportCompanyWarehouseData): Promise<TransportCompanyWarehouse>;
  updateWarehouse(id: number, data: UpdateTransportCompanyWarehouseData): Promise<TransportCompanyWarehouse>;
  deleteWarehouse(id: number): Promise<void>;
}

interface CreateCityData {
  name: string;
  region: Region;
  delivery_cost?: number | null;
  free_from?: number | null;
}

interface UpdateCityData {
  name?: string;
  delivery_cost?: number | null;
  free_from?: number | null;
}

interface CreateTransportCompanyData {
  name: string;
}

interface UpdateTransportCompanyData {
  name?: string;
}

interface CreateTransportCompanyWarehouseData {
  transport_company_id: number;
  city_id: number;
  details?: TransportCompanyWarehouseDetails;
}

interface UpdateTransportCompanyWarehouseData {
  details?: TransportCompanyWarehouseDetails;
}

interface CityQuery {
  region?: Region;
}

interface TransportCompanyWarehouseQuery {
  transport_company_id?: number;
  city_id?: number;
}

export function createDeliverySdk(api: Api): DeliverySdk {
  return {
    async getCities(query) {
      const data = await api.get('/delivery/city', query);
      return data.cities;
    },
    async getCity(id) {
      const data = await api.get(`/delivery/city/${id}`);
      return data.city;
    },
    async createCity(data) {
      const result = await api.post('/delivery/city', data);
      return result.city;
    },
    async updateCity(id, data) {
      const result = await api.patch(`/delivery/city/${id}`, data);
      return result.city;
    },
    async deleteCity(id: number) {
      await api.delete(`/delivery/city/${id}`);
    },
    async getTransportCompanies() {
      const data = await api.get('/delivery/transport_company');
      return data.transport_companies;
    },
    async getTransportCompany(id) {
      const data = await api.get(`/delivery/transport_company/${id}`);
      return data.transport_company;
    },
    async createTransportCompany(data) {
      const result = await api.post('/delivery/transport_company', data);
      return result.transport_company;
    },
    async updateTransportCompany(id, data) {
      const result = await api.patch(`/delivery/transport_company/${id}`, data);
      return result.transport_company;
    },
    async deleteTransportCompany(id: number) {
      await api.delete(`/delivery/transport_company/${id}`);
    },
    async getWarehouses(query) {
      const data = await api.get('/delivery/warehouse', query);
      return data.warehouses;
    },
    async getWarehouse(id) {
      const data = await api.get(`/delivery/warehouse/${id}`);
      return data.warehouse;
    },
    async createWarehouse(data) {
      const result = await api.post('/delivery/warehouse', data);
      return result.warehouse;
    },
    async updateWarehouse(id, data) {
      const result = await api.patch(`/delivery/warehouse/${id}`, data);
      return result.warehouse;
    },
    async deleteWarehouse(id: number) {
      await api.delete(`/delivery/warehouse/${id}`);
    },
  };
}
