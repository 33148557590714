import React from 'react';

import { App } from '../../app';
import { formatDate, formatTime } from '../../services/fmt.service';
import { getPeriodFilterOptions, filterItemsByPeriod } from '../../services/filter.service';
import { List } from '../List';
import { Select } from '../Select';
import { config } from '../../config';
import { Feedback } from '../../sdk/feedback.sdk';
import { MFeedback } from '../modals/MFeedback';

interface P {
  app: App;
}

interface S {
  items: Feedback[];
  search: string;
  filter: string;
  page: number;
}

export class PFeedbacks extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      search: '',
      filter: 'all',
      page: 1,
    };
  }

  render() {
    return (
      <div className="PFeedbacks">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Заявки', undefined, config.tabs.requests);
    app.updateUI();
    await this.refreshData();
  }

  // event handlers

  onSearchChange(e: any) {
    this.setState({ search: e.target.value, page: 1 });
  }

  onFilterChange(value: string) {
    this.setState({ filter: value, page: 1 });
  }

  onGetItemMenu(feedback: Feedback) {
    const { app } = this.props;
    return [
      {
        name: 'Открыть',
        action: () => this.runPrimaryAction(feedback),
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить сообщение обратной связи # ${feedback.id}?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await app.getSdk().deleteFeedback(feedback.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(feedback: Feedback) {
    await this.runPrimaryAction(feedback);
  }

  onPageChange(page: number) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <input
          className="form-control"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
        {this.renderFilter()}
      </div>
    );
  }

  renderFilter() {
    const { items } = this.state;
    return (
      <Select
        items={getPeriodFilterOptions(items, 'created_at')}
        className="ml-3"
        value={this.state.filter}
        onChange={(value) => this.onFilterChange(value)}
      />
    );
  }

  renderList() {
    const { items, search, filter, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={filterItemsByPeriod(filter, items, 'created_at')}
        search={search}
        pageNumber={page}
        pageSize={config.itemsPerPage}
        onGetItemMenu={(item) => this.onGetItemMenu(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(x) => this.onPageChange(x)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: '#',
        value: (item: Feedback) => item.id,
        headClassName: 'w-100px',
      },
      {
        name: 'Дата',
        value: (item: Feedback) => formatDate(item.created_at),
        headClassName: 'w-120px',
      },
      {
        name: 'Время',
        value: (item: Feedback) => formatTime(item.created_at),
        headClassName: 'w-100px',
      },
      {
        name: 'Имя',
        value: (item: Feedback) => item.name,
      },
      {
        name: 'Email',
        value: (item: Feedback) => item.email,
      },
    ];
  }

  async runPrimaryAction(feedback: Feedback) {
    const { app } = this.props;
    await app.showModal(MFeedback, { feedback });
    await this.refreshData();
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const items = await app.getSdk().getFeedbacks();
      this.setState({ items });
    } catch (err) {
      app.handleError(err);
    }
  }
}
