import { config } from '../config';
import { Api } from '../services/api.service';

export enum SearchObjectType {
  article = 'article',
  articleCategory = 'article_category',
  news = 'news',
  product = 'product',
  productCategory = 'product_category',
  productSubcategory = 'product_subcategory',
  review = 'review',
  staticPage = 'static_page',
}

export interface SearchResultItem {
  object_id: number;
  object_type: SearchObjectType;
  object_title: string;
}

export interface SearchPageSdk {
  getSearchResults(q: string): Promise<SearchResultItem[]>;
}

export function createSearchPageSdk(api: Api): SearchPageSdk {
  return {
    async getSearchResults(q: string) {
      const data = await api.get('/search/mega', { q, limit: config.globalSearchLimit });
      return data.results;
    },
  };
}
