import marked from 'marked';
import { esc, replace } from './utils.lib';
import { getVideoIframeUrl } from './video.lib';

interface Options {
  getImageUrl: (mediaName: string, name?: string) => string;
  lazyLoading?: boolean;
}

export function renderGmToHtml(input: string, options: Options) {
  const renderer = new marked.Renderer();
  renderer.heading = (text) => `<h2>${text}</h2>`;
  renderer.link = (href, _title, text) => (href === text ? href : `<a href="${href}">${text}</a>`);
  let output = input;
  output = esc(output);
  output = wrapTagsWithLineBreaks(output);
  output = marked(output, { renderer });
  output = processRow(output);
  output = processImageInLink(output, options);
  output = processImage(output, options);
  output = processVideo(output, options);
  output = processCard(output, options);
  output = processTable(output);
  return output.trim();
}

// private

function wrapTagsWithLineBreaks(input: string) {
  let output = input;
  output = wrapWithLineBreaks(output, '[row]');
  output = wrapWithLineBreaks(output, '[/row]');
  output = wrapWithLineBreaks(output, /\[image[^\]]+?\](?!\]\()/g);
  output = wrapWithLineBreaks(output, /\[\[image[^\]]+?\]\]\([^)]+?\)/g);
  output = wrapWithLineBreaks(output, /\[video[^\]]+?\](?!\]\()/g);
  output = wrapWithLineBreaks(output, /\[card (.+?)\]/g);
  output = wrapWithLineBreaks(output, '[/card]');
  return output;
}

function processRow(input: string) {
  const regexp = /<p>\[row\]<\/p>([\s\S]*?)<p>\[\/row]<\/p>/g;
  return input.replace(regexp, (_match, content) => renderRow(content));
}

function processImageInLink(input: string, options: Options) {
  const regexp = /<p><a href="([^"]+?)">[\s]*?\[image \| (\S+?)(?: \| (.+?))?\][\s]*?<\/a><\/p>/g;
  return input.replace(regexp, (_match, href, name, alt) => renderImageInLink(href, name, alt || '', options));
}

function processImage(input: string, options: Options) {
  const regexp = /<p>\[image \| (\S+?)(?: \| (.+?))?\]<\/p>/g;
  return input.replace(regexp, (_match, name, alt) => renderImage(name, alt || '', options));
}

function processVideo(input: string, options: Options) {
  const regexp = /<p>\[video \| (\S+?)\]<\/p>/g;
  return input.replace(regexp, (_match, source) => {
    const videoUrl = getVideoIframeUrl(source);
    if (!videoUrl) {
      return '<div class="GmVideo"><div class="GmVideoStub"></div></div>';
    }
    return options.lazyLoading
      ? `<div class="GmVideo"><div class="GmVideoStub" data-src="${videoUrl}"></div></div>`
      : `<div class="GmVideo"><iframe src="${videoUrl}"></iframe></div>`;
  });
}

function processCard(input: string, options: Options) {
  const regexp = /<p>\[card \| (.+?)(?: \| (.+?))?\]<\/p>([\s\S]*?)<p>\[\/card]<\/p>/g;
  return input.replace(regexp, (_match, title, imageName, content) => renderCard(title, imageName, content, options));
}

function wrapWithLineBreaks(input: string, search: string | RegExp) {
  if (typeof search === 'string') {
    return replace(input, search, `\n\n${search}\n\n`);
  }
  return input.replace(search, (match) => `\n\n${match}\n\n`);
}

function renderRow(content: string) {
  return `<div class="GmRow">${content}</div>`;
}

function renderImageInLink(href: string, name: string, alt: string, options: Options) {
  const cleanAlt = alt.trim();
  const imageUrl = options.getImageUrl(name, cleanAlt);
  const image = `<img src="${imageUrl}" alt="${cleanAlt}">`;
  return `<div class="GmImage"><a href="${href}">${image}</a></div>`;
}

function renderImage(name: string, alt: string, options: Options) {
  const cleanAlt = alt.trim();
  const imageUrl = options.getImageUrl(name, cleanAlt);
  return `<div class="GmImage"><img src="${imageUrl}" alt="${cleanAlt}"></div>`;
}

function renderCard(title: string, imageName: string, content: string, options: Options) {
  const cleanTitle = title.trim();
  const imageUrl = imageName ? options.getImageUrl(imageName, cleanTitle) : '';
  const cardImage = imageUrl ? `<div class="GmCard_image"><img src="${imageUrl}" alt="${esc(cleanTitle)}"/></div>` : '';
  const cardTitle = cleanTitle !== '-' ? `<div class="GmCard_title">${cleanTitle}</div>` : '';
  const cardContent = `<div class="GmCard_content">${content || ''}</div>`;
  return `<div class="GmCard">${cardImage}<div class="GmCard_main">${cardTitle}${cardContent}</div></div>`;
}

function processTable(input: string) {
  const regexp = /<table>[\s\S]+<\/table>/g;
  return input.replace(regexp, (match) => `<div class="GmTable">${match}</div>`);
}
