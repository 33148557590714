import React from 'react';

import { App } from '../../app';
import { ArticleCategory } from '../../sdk/article.sdk';
import { Button } from '../Button';
import { List } from '../List';
import { MArticleCategory } from '../modals/MArticleCategory';

interface P {
  app: App;
}

interface S {
  items: ArticleCategory[];
  search: string;
}

export class PArticleCategories extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      search: '',
    };
  }

  render() {
    return (
      <div className="PArticleCategories">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Статьи');
    app.updateUI();
    await this.refreshData();
  }

  // event handlers

  async onAdd() {
    const { app } = this.props;
    await app.showModal(MArticleCategory);
    await this.refreshData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(category: ArticleCategory) {
    const { app } = this.props;
    return [
      {
        name: 'Перейти к статьям',
        action: async () => {
          await this.runPrimaryAction(category);
        },
      },
      {
        name: 'Редактировать',
        action: async () => {
          await app.showModal(MArticleCategory, { category });
          await this.refreshData();
        },
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить категорию "${category.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            const sdk = app.getSdk();
            await sdk.deleteArticleCategory(category.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(category: ArticleCategory) {
    await this.runPrimaryAction(category);
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        buttons={this.getMoveButtons()}
        onGetItemMenu={(item) => this.onGetItemMenu(item)}
        onGetItemClassName={(item) => this.getItemClassName(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'Категория',
        value: (item: ArticleCategory) => item.name,
      },
    ];
  }

  getMoveButtons() {
    const { app } = this.props;
    const sdk = app.getSdk();
    return [
      {
        icon: 'arrow-up',
        action: async (category: ArticleCategory) => {
          try {
            await sdk.moveArticleCategoryUp(category.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
      {
        icon: 'arrow-down',
        action: async (category: ArticleCategory) => {
          try {
            await sdk.moveArticleCategoryDown(category.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  getItemClassName(category: ArticleCategory) {
    return category.is_hidden ? 'text-black-50' : '';
  }

  async runPrimaryAction(category: ArticleCategory) {
    const { app } = this.props;
    app.saveToCache('category', category);
    app.getHistory().push(`/articles/${category.id}`);
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const items = await app.getSdk().getArticleCategories();
      this.setState({ items });
    } catch (err) {
      app.handleError(err);
    }
  }
}
