import React from 'react';
import { formatFileName } from '../../services/fmt.service';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { Image } from '../ImageList';
import { ModalHeader } from '../ModalHeader';

interface P {
  image: Image;
  close: (image?: Image) => void;
}

interface S {
  isLoading: boolean;
  form: Form;
}

export class MImageEdit extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(this.props.image),
    };
  }

  render() {
    return (
      <div className="MImageEdit modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onSave() {
    const { close, image } = this.props;
    const { form } = this.state;
    const newImage = { ...image };
    newImage.alt = form.getValue('alt');
    newImage.caption = form.getValue('caption');
    close(newImage);
  }

  // render helpers

  renderHeader() {
    const { close, image } = this.props;
    const { form } = this.state;
    const title = formatFileName(image.media_name, form.getValue('alt') || 'image');
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form } = this.state;
    return (
      <div className="modal-body">
        <FormGroup type="text" name="alt" label="ALT" form={form} onChange={(x) => this.onFormChange(x)} />
        <FormGroup
          type="text"
          name="caption"
          label="Поясняющий текст"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(image: Image) {
    if (!image) {
      return createForm({});
    }
    return createForm({ alt: image.alt, caption: image.caption });
  }
}
