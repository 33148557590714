import { Api } from '../services/api.service';

export interface ExternalReviewSdk {
  invalidateExternalReviews(): Promise<void>;
}

export function createExternalReviewSdk(api: Api): ExternalReviewSdk {
  return {
    async invalidateExternalReviews() {
      const data = await api.post('/external_review/invalidate');
      return data.reviews;
    },
  };
}
