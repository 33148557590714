import React from 'react';
import ymaps from 'ymaps';

import { App } from '../../app';
import { config } from '../../config';
import { City, TransportCompany, TransportCompanyWarehouse } from '../../sdk/delivery.sdk';
import { formatMoney } from '../../services/fmt.service';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

import './MWarehouse.css';

interface P {
  app: App;
  city: City;
  companies: TransportCompany[];
  warehouse?: TransportCompanyWarehouse;
  close: (warehouse?: TransportCompanyWarehouse) => void;
}

interface S {
  isLoading: boolean;
  form: Form;
}

const DEFAULT_ZOOM = 11;

export class MWarehouse extends React.Component<P, S> {
  ymapsApi?: any;
  map?: any;

  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.warehouse),
    };
  }

  render() {
    return (
      <div className="MWarehouse modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    this.ymapsApi = await ymaps.load(config.yandexMapsUrl);
    await this.initMap();
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onFindAddress() {
    const { city } = this.props;
    const { form } = this.state;
    const address = `${city.name}, ${form.getValue('address')}`;
    const geoObjectAddress = await this.findGeoObjectByRequest(address);
    const searchPrecision = geoObjectAddress.properties.get('metaDataProperty.GeocoderMetaData.precision');
    if (searchPrecision === 'other') {
      alert('Адрес не найден');
      return;
    }
    const coords = geoObjectAddress.geometry.getCoordinates();
    this.map.setCenter(coords, 16);
    const placemark = this.map.geoObjects.get(0);
    placemark.geometry.setCoordinates(coords);
  }

  async onSave() {
    const { app, close, warehouse } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('transport_company');
    form = form.validateMoney('delivery_cost');
    if (form.hasError()) {
      this.setState({ form });
      alert(config.messages.invalidForm);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const sdk = app.getSdk();
      const pd = this.getPostData(form);
      const freshWarehouse = await (warehouse ? sdk.updateWarehouse(warehouse.id, pd) : sdk.createWarehouse(pd));
      close(freshWarehouse);
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, city, warehouse } = this.props;
    const title = warehouse
      ? `Склад компании "${warehouse.transport_company_name}" (${city.name})`
      : `Новый склад (${city.name})`;
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { warehouse } = this.props;
    const { form } = this.state;
    return (
      <div className="modal-body">
        <div className="row">
          <div className="col-6">
            <FormGroup
              type="select"
              name="transport_company"
              label="Транспортная компания"
              required
              disabled={Boolean(warehouse)}
              form={form}
              options={this.getTransportCompaniesOptions()}
              onChange={(x) => this.onFormChange(x)}
            />
            <FormGroup
              type="text"
              name="delivery_time"
              label="Срок доставки"
              form={form}
              onChange={(x) => this.onFormChange(x)}
            />
            <FormGroup
              type="text"
              name="delivery_cost"
              label="Стоимость доставки, ₽"
              form={form}
              onChange={(x) => this.onFormChange(x)}
            />
            <div className="position-relative">
              <FormGroup
                type="text"
                className="MWarehouse_address"
                name="address"
                label="Адрес"
                form={form}
                onChange={(x) => this.onFormChange(x)}
              />
              <Button
                type="link"
                className="position-absolute MWarehouse_addressFindButton"
                icon="bullseye"
                title="Найти на карте"
                onClick={() => this.onFindAddress()}
              />
            </div>
            <FormGroup type="text" name="phones" label="Телефоны" form={form} onChange={(x) => this.onFormChange(x)} />
          </div>
          <div id="yandex-map" className="col-6"></div>
        </div>
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(warehouse?: TransportCompanyWarehouse) {
    const { city } = this.props;
    if (!warehouse) {
      return createForm({ city: city.name });
    }
    return createForm({
      city: city.name,
      transport_company: warehouse.transport_company_id,
      delivery_time: warehouse.details.delivery_time,
      delivery_cost: formatMoney(warehouse.details.delivery_cost),
      address: warehouse.details.address,
      phones: warehouse.details.phones,
    });
  }

  getTransportCompaniesOptions() {
    const { companies } = this.props;
    return [
      { value: '', title: 'Выбрать', disabled: true },
      ...companies.map((x) => ({ value: String(x.id), title: x.name })),
    ];
  }

  async initMap() {
    const initMapState = await this.getInitMapState();
    this.map = new this.ymapsApi.Map('yandex-map', initMapState, { suppressMapOpenBlock: true });
    const placemark = new this.ymapsApi.Placemark(initMapState.center, undefined, { draggable: true });
    this.map.geoObjects.add(placemark);
  }

  async getInitMapState() {
    const { city, warehouse } = this.props;
    if (warehouse && warehouse.details.map) {
      return {
        center: [warehouse.details.map.lat, warehouse.details.map.lng],
        zoom: warehouse.details.map.zoom,
        controls: ['zoomControl'],
      };
    }
    const geoObjectCity = await this.findGeoObjectByRequest(city.name);
    return {
      center: geoObjectCity.geometry.getCoordinates(),
      zoom: DEFAULT_ZOOM,
      controls: ['zoomControl'],
    };
  }

  async findGeoObjectByRequest(request: string) {
    const geocode = await this.ymapsApi.geocode(request);
    return geocode.geoObjects.get(0);
  }

  getPostData(form: Form) {
    const { city } = this.props;
    const placemark = this.map.geoObjects.get(0);
    let detailsMap;
    if (placemark) {
      const coords = placemark.geometry.getCoordinates();
      detailsMap = {
        lat: coords[0],
        lng: coords[1],
        zoom: this.map.getZoom(),
      };
    }
    return {
      transport_company_id: form.getNumericValue('transport_company')!,
      city_id: city.id,
      details: {
        delivery_time: form.getValue('delivery_time') || undefined,
        delivery_cost: form.getMoneyValue('delivery_cost'),
        address: form.getValue('address') || undefined,
        phones: form.getValue('phones') || undefined,
        map: detailsMap,
      },
    };
  }
}
