import { Api } from '../services/api.service';
import { Account } from './account.sdk';

export interface AuthSdk {
  getUser(): Promise<Account>;
  setUser(data: SetUserData): Promise<string>;
}

interface SetUserData {
  email: string;
  password: string;
}

export function createAuthSdk(api: Api): AuthSdk {
  return {
    async getUser() {
      const data = await api.get('/auth');
      return data.user as Account;
    },
    async setUser(data) {
      const result = await api.post('/auth', data);
      return result.token as string;
    },
  };
}
