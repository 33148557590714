import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import * as icons from '@fortawesome/free-solid-svg-icons';

import { createApp } from './app';
import { config } from './config';
import { Root } from './components/Root';

import './style.css';

library.add(icons.faAddressCard);
library.add(icons.faArrowDown);
library.add(icons.faArrowUp);
library.add(icons.faBars);
library.add(icons.faBold);
library.add(icons.faBullhorn);
library.add(icons.faBullseye);
library.add(icons.faCheck);
library.add(icons.faChevronLeft);
library.add(icons.faCog);
library.add(icons.faEye);
library.add(icons.faEyeSlash);
library.add(icons.faFileAlt);
library.add(icons.faHeading);
library.add(icons.faImage);
library.add(icons.faItalic);
library.add(icons.faLink);
library.add(icons.faListOl);
library.add(icons.faListUl);
library.add(icons.faNewspaper);
library.add(icons.faPen);
library.add(icons.faPhone);
library.add(icons.faPlus);
library.add(icons.faSearch);
library.add(icons.faShoppingCart);
library.add(icons.faSyncAlt);
library.add(icons.faTable);
library.add(icons.faTh);
library.add(icons.faTimes);
library.add(icons.faTrash);
library.add(icons.faTruck);
library.add(icons.faVideo);

const app = createApp();
window.document.title = `Гравировщик ${config.version}`;
ReactDOM.render(<Root app={app} />, document.getElementById('root'));
