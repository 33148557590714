import { Api } from '../services/api.service';

export interface NewsItem {
  id: number;
  title: string;
  date: string;
  text: string;
  is_major: boolean;
  is_hidden: boolean;
  image_id: number | null;
  image_name: string | null;
  image_size: number | null;
}

export interface NewsSdk {
  getNewsItems(): Promise<NewsItem[]>;
  getNewsItem(id: number): Promise<NewsItem>;
  createNewsItem(data: CreateNewsItemData): Promise<NewsItem>;
  updateNewsItem(id: number, data: UpdateNewsItemData): Promise<NewsItem>;
  deleteNewsItem(id: number): Promise<void>;
  updateNewsItemImage(id: number, data: UpdateNewsItemData): Promise<NewsItem>;
  deleteNewsItemImage(id: number): Promise<NewsItem>;
}

interface CreateNewsItemData {
  title: string;
  date: string;
  text?: string;
  is_major?: boolean;
  is_hidden?: boolean;
}

interface UpdateNewsItemData {
  title?: string;
  date?: string;
  text?: string;
  is_major?: boolean;
  is_hidden?: boolean;
  media_id?: number;
  media_hash?: string;
}

export function createNewsSdk(api: Api): NewsSdk {
  return {
    async getNewsItems() {
      const data = await api.get('/news');
      return data.news_items;
    },
    async getNewsItem(id) {
      const data = await api.get(`/news/${id}`);
      return data.news_item;
    },
    async createNewsItem(data) {
      const result = await api.post('/news', data);
      return result.news_item;
    },
    async updateNewsItem(id, data) {
      const result = await api.patch(`/news/${id}`, data);
      return result.news_item;
    },
    async deleteNewsItem(id) {
      await api.delete(`/news/${id}`);
    },
    async updateNewsItemImage(id, data) {
      const result = await api.put(`/news/${id}/image`, data);
      return result.news_item;
    },
    async deleteNewsItemImage(id) {
      const result = await api.delete(`/news/${id}/image`);
      return result.news_item;
    },
  };
}
