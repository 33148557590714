import { Api } from '../services/api.service';

export interface Order {
  id: number;
  display_id: number | null;
  name: string;
  company_name: string;
  type: OrderType;
  delivery_type: DeliveryType;
  payment_type: PaymentType | null;
  payment_status: PaymentStatus | null;
  total: number;
  sent_at: string;
  text?: string;
  payment_url?: string;
}

export enum OrderStatus {
  cart = 'cart',
  sent = 'sent',
}

export enum OrderType {
  regular = 'regular',
  cp = 'cp',
}

export enum DeliveryType {
  pickup = 'pickup',
  express = 'express',
  mc = 'mc',
  mo = 'mo',
  ru = 'ru',
  kz = 'kz',
}

export enum PaymentType {
  cash = 'cash',
  online = 'online',
  transfer = 'transfer',
}

export enum PaymentStatus {
  review = 'review',
  ready = 'ready',
  paid = 'paid',
}

export enum ExecutionTime {
  d3 = 'd3',
  d2 = 'd2',
  d1 = 'd1',
  h1 = 'h1',
}

interface OrderQuery {
  status?: OrderStatus;
  type?: OrderType;
}

export interface OrderSdk {
  getOrders(query?: OrderQuery): Promise<Order[]>;
  getOrder(id: number): Promise<Order>;
  deleteOrder(id: number): Promise<void>;
}

export function createOrderSdk(api: Api): OrderSdk {
  return {
    async getOrders(query) {
      const data = await api.get('/order', query);
      return data.orders;
    },
    async getOrder(id) {
      const data = await api.get(`/order/${id}`);
      return data.order;
    },
    async deleteOrder(id) {
      await api.delete(`/order/${id}`);
    },
  };
}
