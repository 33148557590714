import { Api } from '../services/api.service';

export interface Obj {
  key: string;
  value: { [key: string]: any };
}

export interface ObjSdk {
  getObj(key: string): Promise<Obj>;
  updateObj(key: string, data: UpdateObjData): Promise<Obj>;
}

interface UpdateObjData {
  value: { [key: string]: any };
}

export function createObjSdk(api: Api): ObjSdk {
  return {
    async getObj(key: string) {
      const data = await api.get(`/obj/${key}`);
      return data.obj;
    },
    async updateObj(key: string, data: UpdateObjData) {
      const result = await api.patch(`/obj/${key}`, data);
      return result.obj;
    },
  };
}
