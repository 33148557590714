import React from 'react';
import { App } from '../../app';
import { config } from '../../config';
import { Obj } from '../../sdk/obj.sdk';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';

import './PYml.css';

interface P {
  app: App;
}

interface S {
  isEditMode: boolean;
  isLoading: boolean;
  form: Form;
  yml?: Obj;
}

export class PYml extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isEditMode: false,
      isLoading: false,
      form: this.initForm(),
    };
  }

  render() {
    return (
      <div className="PYml">
        {this.renderTextarea()}
        {this.renderBottom()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Настройки', undefined, config.tabs.settings);
    app.updateUI();
    await this.refreshData();
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  onEdit(isEditMode: boolean) {
    this.setState({ isEditMode });
  }

  async onSave() {
    const { app } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    this.setState({ isLoading: true });
    try {
      await app.getSdk().updateObj('yml', { value: { content: form.getValue('yml') } });
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
      return;
    }
    this.setState({ isLoading: false, isEditMode: false });
  }

  // render helpers

  renderTextarea() {
    const { form, isEditMode, isLoading } = this.state;
    return (
      <FormGroup
        type="textarea"
        name="yml"
        disabled={!isEditMode || isLoading}
        monospace
        form={form}
        onChange={(x) => this.onFormChange(x)}
      />
    );
  }

  renderBottom() {
    const { isEditMode, isLoading } = this.state;
    return (
      <div className="d-flex">
        {!isEditMode && (
          <Button type="primary" text="Редактировать" disabled={isLoading} onClick={() => this.onEdit(true)} />
        )}
        {isEditMode && (
          <React.Fragment>
            <Button
              type="secondary"
              className="ml-auto"
              text="Отмена"
              disabled={isLoading}
              onClick={() => this.onEdit(false)}
            />
            <Button
              type="success"
              className="ml-2"
              text="Сохранить"
              disabled={isLoading}
              onClick={() => this.onSave()}
            />
          </React.Fragment>
        )}
      </div>
    );
  }

  // other helpers

  initForm(yml?: Obj) {
    if (!yml) {
      return createForm();
    }
    return createForm({ yml: yml.value.content });
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const yml = await app.getSdk().getObj('yml');
      this.setState({ yml, form: this.initForm(yml) });
    } catch (err) {
      app.handleError(err);
    }
  }
}
