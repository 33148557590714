export function getPositionBoundaries(text: string, splitter: string | RegExp, posStart: number, posEnd?: number) {
  if (posEnd !== undefined && posStart !== posEnd) {
    return { boundaryStart: posStart, boundaryEnd: posEnd };
  }
  const splits = text.split(splitter);
  let posCurrent = 0;
  for (const split of splits) {
    if (posCurrent + split.length >= posStart) {
      return { boundaryStart: posCurrent, boundaryEnd: posCurrent + split.length };
    }
    posCurrent += split.length + 1;
  }
  return { boundaryStart: 0, boundaryEnd: 0 };
}

export function getTextLinesWithBoundaries(text: string, posStart: number, posEnd: number) {
  const lines = [];
  let boundaryStart = -1;
  let boundaryEnd = -1;
  const splits = text.split('\n');
  let posCurrent = 0;
  for (const split of splits) {
    if (posCurrent + split.length >= posStart) {
      if (boundaryStart === -1) {
        boundaryStart = posCurrent;
      }
      lines.push(split);
    }
    if (posCurrent + split.length >= posEnd) {
      boundaryEnd = posCurrent + split.length;
      break;
    }
    posCurrent += split.length + 1;
  }
  return { lines, boundaryStart, boundaryEnd };
}

export function getTextParts(text: string, posStart: number, posEnd: number) {
  const selected = text.slice(posStart, posEnd);
  const before = text.slice(0, posStart);
  const after = text.slice(posEnd);
  return { selected, before, after };
}
