import React from 'react';

import { App } from '../../app';
import { config } from '../../config';
import { createForm, Form } from '../../services/form.service';
import { Article, ArticleCategory } from '../../sdk/article.sdk';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { ModalNav } from '../ModalNav';
import { GmEditor } from '../GmEditor';

import './MArticle.css';

interface P {
  app: App;
  categoryId?: number;
  categories: ArticleCategory[];
  article?: Article;
  close: (article?: Article) => void;
}

interface S {
  isLoading: boolean;
  form: Form;
  nav: string;
  isEditorPreview: boolean;
}

const CHEKS = [{ name: 'hidden', title: 'Временно скрыть' }];

const NAV_GENERAL = 'general';
const NAV_TEXT = 'text';
const NAV_SEO = 'seo';

const NAVS = [
  { code: NAV_GENERAL, title: 'Основное' },
  { code: NAV_TEXT, title: 'Текст' },
  { code: NAV_SEO, title: 'SEO' },
];

export class MArticle extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.article),
      nav: NAV_GENERAL,
      isEditorPreview: false,
    };
  }

  render() {
    return (
      <div className="MArticle modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderNav()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  onNavChange(nav: string) {
    this.setState({ nav });
  }

  onEditorChange(text: string) {
    const { form } = this.state;
    const newForm = form.setValue('text', text);
    this.setState({ form: newForm });
  }

  onEditorPreview(isEditorPreview: boolean) {
    this.setState({ isEditorPreview });
  }

  async onSave() {
    const { app, close, article } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('name');
    form = form.validateRequired('slug');
    form = form.validateSlug('slug');
    form = form.validateUrl('redirect_url');
    if (form.hasError()) {
      this.setState({ form, nav: NAV_GENERAL });
      alert(config.messages.invalidForm);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const sdk = app.getSdk();
      const pd = this.getPostData(form);
      const freshArticle = await (article ? sdk.updateArticle(article.id, pd) : sdk.createArticle(pd));
      close(freshArticle);
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, article } = this.props;
    const title = article ? article.name : 'Новая статья';
    return <ModalHeader title={title} close={close} />;
  }

  renderNav() {
    const { nav } = this.state;
    return <ModalNav items={NAVS} nav={nav} onChange={(x) => this.onNavChange(x)} />;
  }

  renderBody() {
    return <div className="modal-body">{this.renderContent()}</div>;
  }

  renderContent() {
    switch (this.state.nav) {
      case NAV_GENERAL:
        return this.renderGeneral();
      case NAV_TEXT:
        return this.renderText();
      case NAV_SEO:
        return this.renderSeo();
      default:
        return null;
    }
  }

  renderGeneral() {
    const { form } = this.state;
    const { article } = this.props;
    return (
      <div className="row">
        <div className="col-8">
          <FormGroup
            type="select"
            name="category"
            label="Категория"
            required
            disabled={!Boolean(article)}
            form={form}
            options={this.getCategoriesOptions()}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="text"
            name="name"
            label="Название"
            required
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup type="text" name="slug" label="ЧПУ" required form={form} onChange={(x) => this.onFormChange(x)} />
          <FormGroup
            type="text"
            name="short_name"
            label="Краткое название (для списка)"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="text"
            name="redirect_url"
            label="Редирект (URL)"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="col-4">
          <FormGroup
            type="checks"
            name="check"
            label="Параметры"
            form={form}
            checks={CHEKS}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
      </div>
    );
  }

  renderText() {
    const { app } = this.props;
    const { form, isEditorPreview, isLoading } = this.state;
    return (
      <GmEditor
        app={app}
        text={form.getValue('text')}
        disabled={isLoading}
        isPreview={isEditorPreview}
        onChange={(x) => this.onEditorChange(x)}
        onPreview={(x) => this.onEditorPreview(x)}
      />
    );
  }

  renderSeo() {
    const { form } = this.state;
    return (
      <div className="MArticle_seo">
        <div className="row">
          <FormGroup
            type="text"
            className="col"
            name="seo_title"
            label="Тег Title"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="text"
            className="col"
            name="seo_h1"
            label="Тег H1"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <FormGroup
          type="textarea"
          className="m-0"
          name="seo_description"
          label="Тег Meta"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(article?: Article) {
    const { categoryId } = this.props;
    if (!article) {
      return createForm({ category: categoryId });
    }
    return createForm({
      category: article.category_id,
      name: article.name,
      slug: article.slug,
      text: article.text,
      redirect_url: article.redirect_url,
      check_hidden: article.is_hidden ? 'on' : '',
      short_name: article.details.short_name,
      seo_h1: article.details.seo_h1,
      seo_title: article.details.seo_title,
      seo_description: article.details.seo_description,
    });
  }

  getCategoriesOptions() {
    const { categories } = this.props;
    return [...categories.map((x) => ({ value: String(x.id), title: x.name }))];
  }

  getPostData(form: Form) {
    return {
      category_id: form.getNumericValue('category')!,
      name: form.getValue('name'),
      slug: form.getValue('slug'),
      text: form.getValue('text'),
      redirect_url: form.getValue('redirect_url'),
      is_hidden: form.getValue('check_hidden') === 'on',
      details: {
        short_name: form.getValue('short_name') || undefined,
        seo_title: form.getValue('seo_title') || undefined,
        seo_h1: form.getValue('seo_h1') || undefined,
        seo_description: form.getValue('seo_description') || undefined,
      },
    };
  }
}
