import { Api } from '../services/api.service';

export interface Feedback {
  id: number;
  name: string;
  email: string;
  text: string;
  created_at: string;
}

export interface FeedbackSdk {
  getFeedbacks(): Promise<Feedback[]>;
  deleteFeedback(id: number): Promise<void>;
}

export function createFeedbackSdk(api: Api): FeedbackSdk {
  return {
    async getFeedbacks() {
      const data = await api.get('/feedback');
      return data.feedbacks;
    },
    async deleteFeedback(id) {
      await api.delete(`/feedback/${id}`);
    },
  };
}
