import React from 'react';
import { cn } from '../lib/utils.lib';

interface P {
  type: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link';
  text: string;
  items: string[];
  className?: string;
  disabled?: boolean;
  right?: boolean;
  onSelect?: (index: number) => void;
}

export class Dropdown extends React.Component<P> {
  render() {
    const { type, text, items, className, disabled, right } = this.props;
    const dropdownClassName = cn(className, 'dropdown');
    const menuClassName = cn('dropdown-menu', right && 'dropdown-menu-right');
    return (
      <div className={dropdownClassName}>
        <button className={`btn btn-${type} dropdown-toggle`} type="button" disabled={disabled} data-toggle="dropdown">
          {text}{' '}
        </button>
        <div className={menuClassName}>{items.map((item, index) => this.renderItem(item, index))}</div>
      </div>
    );
  }

  // event handlers

  onItemClick(index: number) {
    if (this.props.onSelect) {
      this.props.onSelect(index);
    }
  }

  // render helpers

  renderItem(item: string, index: number) {
    return (
      <button key={index} className="dropdown-item" onClick={() => this.onItemClick(index)}>
        {item}
      </button>
    );
  }
}
