import React from 'react';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { config } from '../../config';

interface P {
  url?: string;
  text?: string;
  close: (result?: string) => void;
}

interface S {
  form: Form;
}

export class MGmtLink extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = { form: createForm({ url: props.url, text: props.text }) };
  }

  render() {
    return (
      <div className="MGmtLink modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  onAdd() {
    const { close } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('url');
    form = form.validateRequired('text');
    form = form.validateHref('url');
    if (form.hasError()) {
      this.setState({ form });
      alert(config.messages.invalidForm);
      return;
    }
    const url = form.getValue('url');
    const text = form.getValue('text');
    close(`[${text}](${url})`);
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    return <ModalHeader title="Ссылка" close={close} />;
  }

  renderBody() {
    const { form } = this.state;
    return (
      <div className="modal-body">
        <FormGroup type="text" name="url" label="URL" form={form} required onChange={(x) => this.onFormChange(x)} />
        <FormGroup type="text" name="text" label="Текст" form={form} required onChange={(x) => this.onFormChange(x)} />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button type="success" text="Добавить" onClick={() => this.onAdd()} />
      </div>
    );
  }
}
