import { getYear } from 'date-fns/esm';
import React from 'react';

import { App } from '../../app';
import { config } from '../../config';
import { NewsItem } from '../../sdk/news.sdk';
import { formatDate } from '../../services/fmt.service';
import { Button } from '../Button';
import { List } from '../List';
import { MNews } from '../modals/MNews';
import { Select } from '../Select';

interface P {
  app: App;
}

interface S {
  items: NewsItem[];
  search: string;
  yearFilter?: number;
  page: number;
}

export class PNews extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      search: '',
      page: 1,
    };
  }

  render() {
    return (
      <div className="PNews">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Новости');
    app.updateUI();
    await this.refreshData();
  }

  // event handlers

  async onAdd() {
    const { app } = this.props;
    await app.showModal(MNews);
    await this.refreshData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value, page: 1 });
  }

  onFilterChange(value: string) {
    this.setState({ yearFilter: Number(value) || undefined, page: 1 });
  }

  onGetItemMenu(newsItem: NewsItem) {
    const { app } = this.props;
    return [
      {
        name: 'Редактировать',
        action: () => this.runPrimaryAction(newsItem),
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить новость "${newsItem.title}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            const sdk = app.getSdk();
            await sdk.deleteNewsItem(newsItem.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(newsItem: NewsItem) {
    await this.runPrimaryAction(newsItem);
  }

  onPageChange(page: number) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
        {this.renderFilter()}
      </div>
    );
  }

  renderFilter() {
    return (
      <Select
        items={this.getFilterOptions()}
        className="ml-3"
        value={String(this.state.yearFilter)}
        onChange={(value) => this.onFilterChange(value)}
      />
    );
  }

  renderList() {
    const { items, search, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={this.getFilteredItems(items)}
        search={search}
        pageNumber={page}
        pageSize={config.itemsPerPage}
        onGetItemMenu={(item) => this.onGetItemMenu(item)}
        onGetItemClassName={(item) => this.getItemClassName(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(x) => this.onPageChange(x)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'Дата',
        value: (item: NewsItem) => formatDate(item.date),
        headClassName: 'w-150px',
      },
      {
        name: 'Заголовок',
        value: (item: NewsItem) => item.title,
      },
    ];
  }

  getFilteredItems(items: NewsItem[]) {
    const { yearFilter } = this.state;
    if (!yearFilter) {
      return items;
    }
    return items.filter((item) => getYear(new Date(item.date)) === yearFilter);
  }

  getFilterOptions() {
    const { items } = this.state;
    const uniqueValues = new Set<number>();
    for (const x of items) {
      uniqueValues.add(getYear(new Date(x.date)));
    }
    const sortedValues = Array.from(uniqueValues).sort((a, b) => b - a);
    const filterOptions = sortedValues.map((x) => ({ value: String(x), title: String(x) }));
    return [{ value: '', title: 'За всё время' }, ...filterOptions];
  }

  getItemClassName(newsItem: NewsItem) {
    return newsItem.is_hidden ? 'text-black-50' : '';
  }

  async runPrimaryAction(newsItem: NewsItem) {
    const { app } = this.props;
    await app.showModal(MNews, { newsItem });
    await this.refreshData();
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const items = await app.getSdk().getNewsItems();
      this.setState({ items });
    } catch (err) {
      app.handleError(err);
    }
  }
}
