import React from 'react';

export interface LabelValue {
  label: string;
  value?: string | number | null | undefined;
  url?: string;
}

interface P {
  title?: string;
  items: LabelValue[];
}

export class InfoBlock extends React.Component<P> {
  render() {
    const { title, items } = this.props;
    return (
      <React.Fragment>
        {title && <h4 className="text-black-50">{title}</h4>}
        <dl className="row">{items.map((item) => this.renderItem(item))}</dl>
        <hr />
      </React.Fragment>
    );
  }

  // render helpers

  renderItem(item: LabelValue) {
    return (
      <React.Fragment key={item.label}>
        <dt className="col-sm-4">{item.label}</dt>
        <dd className="col-sm-8">
          {item.url && (
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.url}
            </a>
          )}
          {!item.url && <span>{item.value || '—'}</span>}
        </dd>
      </React.Fragment>
    );
  }
}
