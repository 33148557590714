import { Api } from '../services/api.service';

export interface Holiday {
  id: number;
  date: string;
}

export interface HolidaySdk {
  getHolidays(query: HolidayQuery): Promise<Holiday[]>;
  createHoliday(date: string): Promise<Holiday>;
  deleteHoliday(date: string): Promise<void>;
  autofillHolidays(year: number): Promise<Holiday[]>;
}

interface HolidayQuery {
  year?: number;
}

export function createHolidaySdk(api: Api): HolidaySdk {
  return {
    async getHolidays(query) {
      const data = await api.get('/holiday', query);
      return data.holidays;
    },
    async createHoliday(date) {
      const result = await api.put(`/holiday/${date}`, { value: true });
      return result.holiday;
    },
    async deleteHoliday(date) {
      await api.put(`/holiday/${date}`, { value: false });
    },
    async autofillHolidays(year) {
      const data = await api.post('/holiday/autofill', { year });
      return data.holidays;
    },
  };
}
