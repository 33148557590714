import React from 'react';
import { App } from '../../app';
import { config } from '../../config';
import { getImageDimensions, resetFileInput } from '../../lib/utils.lib';
import { Banner } from '../../sdk/banner.sdk';
import { createForm, Form } from '../../services/form.service';
import { checkImageDimensions } from '../../services/validate.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

import './MBanner.css';

interface P {
  app: App;
  banner?: Banner;
  close: (banner?: Banner) => void;
}

interface S {
  isLoading: boolean;
  isUploading: boolean;
  form: Form;
  image: { id: number; name: string; hash?: string } | null;
}

const CHEKS = [{ name: 'hidden', title: 'Временно скрыть' }];

export class MBanner extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      isUploading: false,
      form: this.initForm(props.banner),
      image: this.initImage(props.banner),
    };
  }

  render() {
    return (
      <div className="MBanner modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onFileChange(e: any) {
    const { app } = this.props;
    const image = e.target.files[0] as File;
    const imageDimension = await getImageDimensions(image);
    const error = checkImageDimensions(imageDimension, config.validImageDimensions.banner);
    if (error) {
      resetFileInput('MBanner_fileInput');
      alert(error);
      return;
    }
    this.setState({ isUploading: true });
    try {
      const sdk = app.getSdk();
      const { id, hash, name } = await sdk.uploadMedia(image);
      this.setState({
        image: { id, hash, name },
        isUploading: false,
      });
    } catch (err) {
      this.setState({ isUploading: false });
      app.handleError(err);
    }
  }

  async onSave() {
    const { app, close, banner } = this.props;
    let { form } = this.state;
    const { image } = this.state;
    form = form.trimValues();
    form = form.validateRequired('name');
    form = form.validateUrl('url');
    if (form.hasError()) {
      this.setState({ form });
      alert(config.messages.invalidForm);
      return;
    }
    if (!image) {
      alert(`Выберите изображение`);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const sdk = app.getSdk();
      const pd = this.getPostData(form);
      const freshBanner = await (banner
        ? sdk.updateBanner(banner.id, pd)
        : sdk.createBanner({ ...pd, media_id: image.id, media_hash: image.hash! }));
      close(freshBanner);
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, banner } = this.props;
    const title = banner ? banner.name : ' Новый баннер';
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form, image } = this.state;
    return (
      <div className="modal-body">
        <div className="row">
          <div className="col-8">
            <FormGroup
              type="text"
              name="name"
              label="Название"
              required
              form={form}
              onChange={(x) => this.onFormChange(x)}
            />
            <FormGroup type="text" name="url" label="Ссылка" form={form} onChange={(x) => this.onFormChange(x)} />
            <div>
              <label className="label">Изображение *</label>
              {image ? this.renderPhotoImage() : this.renderPhotoInput()}
            </div>
          </div>
          <div className="col-4">
            <FormGroup
              type="checks"
              name="check"
              label="Параметры"
              form={form}
              checks={CHEKS}
              onChange={(x) => this.onFormChange(x)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderPhotoInput() {
    const { isUploading } = this.state;
    return (
      <div className="form-group">
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input"
            id="MBanner_fileInput"
            accept="image/*"
            onChange={(e) => this.onFileChange(e)}
          />
          <label className="custom-file-label" htmlFor="MBanner_fileInput">
            {isUploading ? 'Загрузка...' : 'Загрузить изображение'}
          </label>
        </div>
      </div>
    );
  }

  renderPhotoImage() {
    const { app } = this.props;
    const { image } = this.state;
    if (!image) {
      return;
    }
    const url = app.getImageUrl(image.name);
    return (
      <div>
        <img src={url} alt="" className="MBanner_image border img-fluid" />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading, isUploading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading || isUploading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading || isUploading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(banner?: Banner) {
    if (!banner) {
      return createForm();
    }
    return createForm({
      name: banner.name,
      url: banner.url,
      check_hidden: banner.is_hidden ? 'on' : '',
    });
  }

  initImage(banner?: Banner) {
    if (!banner) {
      return null;
    }
    return { id: banner.media_id, name: banner.media_name };
  }

  getPostData(form: Form) {
    return {
      name: form.getValue('name'),
      url: form.getValue('url'),
      is_hidden: form.getValue('check_hidden') === 'on',
    };
  }
}
