import React from 'react';
import { cn } from '../lib/utils.lib';
import { Form } from '../services/form.service';
import { formatExecutionTime, formatNumber } from '../services/fmt.service';
import { config } from '../config';
import { PriceType, PriceScale } from '../sdk/product.sdk';

import './PriceTable.css';

interface P {
  form: Form;
  unit: string;
  priceScale: PriceScale;
  priceType: PriceType;
  onChange?: (form: Form) => void;
}

export class PriceTable extends React.Component<P> {
  render() {
    return <div className="PriceTable">{this.renderTable()}</div>;
  }

  // event handlers

  onChange(name: string, value: string) {
    const { form, onChange } = this.props;
    if (!onChange) {
      return;
    }
    const newForm = form.setValue(name, value).resetError(name);
    onChange(newForm);
  }

  // render helpers

  renderTable() {
    const { unit, priceType } = this.props;
    return (
      <table className="table table-bordered table-sm">
        <thead>
          <tr>
            <th>{priceType === PriceType.by_qty ? `Количество, ${unit}` : 'Срок выполнения'}</th>
            <th>Цена, ₽</th>
          </tr>
        </thead>
        <tbody>
          {priceType === PriceType.by_qty
            ? this.getPriceScale().map((x) => this.renderRow(`price_${x}`, formatNumber(x)))
            : config.executionTime.map((x) => this.renderRow(`price_${x}`, formatExecutionTime(x)))}
        </tbody>
      </table>
    );
  }

  renderRow(formKey: string, value: string) {
    const { form } = this.props;
    return (
      <tr key={value}>
        <td>{value}</td>
        <td className={this.getInputCellClassName(formKey)}>
          <input
            type="text"
            className="form-control-sm"
            value={form.getValue(formKey)}
            onChange={(e) => this.onChange(formKey, e.target.value)}
          />
        </td>
      </tr>
    );
  }

  // other helpers

  getInputCellClassName(name: string) {
    const { form } = this.props;
    return cn('p-0', form.hasError(name) ? 'table-danger' : '');
  }

  getPriceScale() {
    const { priceScale } = this.props;
    return config.priceScales.find((x) => x.code === priceScale)!.values;
  }
}
