import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import { App } from '../app';
import { cn } from '../lib/utils.lib';

import './Sidebar.css';

interface P {
  app: App;
}

export class Sidebar extends React.Component<P> {
  render() {
    return (
      <div className="Sidebar">
        <React.Fragment>
          {this.renderItem('/catalog', 'th')}
          {this.renderItem('/orders/regular', 'shopping-cart')}
          {this.renderItem('/requests/callback', 'phone')}
          {this.renderItem('/delivery/cities/mo', 'truck')}
          {this.renderItem('/news', 'bullhorn')}
          {this.renderItem('/articles', 'newspaper')}
          {this.renderItem('/static/pages', 'file-alt')}
          {this.renderItem('/search', 'search')}
          {this.renderItem('/settings/params', 'cog')}
        </React.Fragment>
      </div>
    );
  }

  // render helpers

  renderItem(url: string, icon: IconProp) {
    const parts = url.split('/');
    const prefix = parts.slice(0, 2).join('/');
    const isActive = window.location.pathname.startsWith(prefix);
    const className = cn('Sidebar_item', isActive && 'Sidebar_item_active');
    return (
      <Link to={url} className={className}>
        <FontAwesomeIcon icon={icon} size="2x" />
      </Link>
    );
  }
}
