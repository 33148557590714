export function getVideoIframeUrl(url: string) {
  if (url.match(/https:\/\/(www\.)?youtube|youtu\.be/)) {
    return getYoutubeIframeUrl(url);
  } else if (url.match(/https:\/\/(player\.)?vimeo\.com/)) {
    return getVimeoIframeUrl(url);
  } else {
    return '';
  }
}

// private

function getYoutubeIframeUrl(url: string) {
  if (url.match('embed')) {
    return url;
  }
  const splits = url.split(/v\/|v=|youtu\.be\//);
  if (splits.length === 1) {
    return '';
  }
  const id = splits[1].split(/[?&]/)[0];
  return `https://www.youtube.com/embed/${id}`;
}

function getVimeoIframeUrl(url: string) {
  if (url.match('player.vimeo')) {
    return url;
  }
  const splits = url.split(/https:\/\/vimeo\.com\//);
  if (splits.length === 1) {
    return '';
  }
  const id = splits[1].split(/[?&]/)[0];
  return `https://player.vimeo.com/video/${id}`;
}
