import React from 'react';

import { App } from '../../app';
import { sleep } from '../../lib/utils.lib';
import { Article, ArticleCategory } from '../../sdk/article.sdk';
import { Button } from '../Button';
import { List } from '../List';
import { MArticle } from '../modals/MArticle';

interface P {
  app: App;
  match: any;
}

interface S {
  items: Article[];
  category: ArticleCategory;
  categories: ArticleCategory[];
  search: string;
}

export class PArticles extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      category: props.app.loadFromCache('category'),
      categories: [],
      search: '',
    };
  }

  render() {
    return (
      <div className="PArticles">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    this.setupPage();
    await this.refreshData();
    await sleep();
    this.setupPage();
  }

  // event handlers

  async onAdd() {
    const { app } = this.props;
    const { categories } = this.state;
    const categoryId = this.getCategoryId();
    await app.showModal(MArticle, { categoryId, categories });
    await this.refreshData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(article: Article) {
    const { app } = this.props;
    return [
      {
        name: 'Редактировать',
        action: async () => this.runPrimaryAction(article),
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить статью "${article.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            const sdk = app.getSdk();
            await sdk.deleteArticle(article.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(article: Article) {
    await this.runPrimaryAction(article);
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        buttons={this.getMoveButtons()}
        onGetItemMenu={(item) => this.onGetItemMenu(item)}
        onGetItemClassName={(item) => this.getItemClassName(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'Название',
        value: (item: Article) => item.name,
      },
    ];
  }

  getMoveButtons() {
    const { app } = this.props;
    const sdk = app.getSdk();
    return [
      {
        icon: 'arrow-up',
        action: async (article: Article) => {
          try {
            await sdk.moveArticleUp(article.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
      {
        icon: 'arrow-down',
        action: async (article: Article) => {
          try {
            await sdk.moveArticleDown(article.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  getItemClassName(article: Article) {
    return article.is_hidden ? 'text-black-50' : '';
  }

  async runPrimaryAction(article: Article) {
    const { app } = this.props;
    const { categories } = this.state;
    await app.showModal(MArticle, { article, categories });
    await this.refreshData();
  }

  getCategoryId() {
    const { match } = this.props;
    return Number(match.params.categoryId);
  }

  setupPage() {
    const { app } = this.props;
    const { category } = this.state;
    const url = '/articles';
    app.setupPage(category.name, url);
    app.updateUI();
  }

  async refreshData() {
    const { app } = this.props;
    const categoryId = this.getCategoryId();
    const sdk = app.getSdk();
    try {
      const items = await sdk.getArticles({ category_id: categoryId });
      const category = await sdk.getArticleCategory(categoryId);
      const categories = await sdk.getArticleCategories();
      this.setState({ items, category, categories });
    } catch (err) {
      app.handleError(err);
    }
  }
}
