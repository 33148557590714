import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { App } from '../app';
import { renderGmToHtml } from '../lib/gm.lib';
import { cn } from '../lib/utils.lib';
import { getPositionBoundaries, getTextLinesWithBoundaries, getTextParts } from '../services/gm.service';
import { isValidUrl } from '../services/validate.service';
import { MGmtCard } from './modals/MGmtCard';
import { MGmtImage } from './modals/MGmtImage';
import { MGmtLink } from './modals/MGmtLink';
import { MPrompt } from './modals/MPrompt';

import './GmEditor.css';

interface P {
  app: App;
  className?: string;
  disabled?: boolean;
  text: string;
  isPreview?: boolean;
  onChange: (text: string) => void;
  onPreview: (isPreview: boolean) => void;
}

interface Tool {
  icon: IconProp;
  title: string;
  action?: (params: {
    app: App;
    text: string;
    posStart: number;
    posEnd: number;
  }) => Promise<{ before: string; selected: string; after: string }>;
}

const TOOLS: Tool[] = [
  {
    icon: 'heading',
    title: 'Заголовок',
    action: async ({ text, posStart }) => {
      const { boundaryStart, boundaryEnd } = getPositionBoundaries(text, '\n', posStart);
      const { before, selected, after } = getTextParts(text, boundaryStart, boundaryEnd);
      const result = selected.startsWith('# ') ? selected.slice(2) : `# ${selected}`;
      return {
        before,
        selected: result,
        after,
      };
    },
  },
  {
    icon: 'bold',
    title: 'Полужирный',
    action: async ({ text, posStart, posEnd }) => {
      const { boundaryStart, boundaryEnd } = getPositionBoundaries(text, /(?![а-яА-Я])(?:\W)/, posStart, posEnd);
      const { before, selected, after } = getTextParts(text, boundaryStart, boundaryEnd);
      if (before.endsWith('**') && after.startsWith('**')) {
        return {
          before: before.slice(0, -2),
          selected,
          after: after.slice(2),
        };
      }
      if (selected.startsWith('**') && selected.endsWith('**')) {
        return {
          before,
          selected: selected.slice(2, -2),
          after,
        };
      }
      return {
        before: `${before}**`,
        selected,
        after: `**${after}`,
      };
    },
  },
  {
    icon: 'italic',
    title: 'Курсив',
    action: async ({ text, posStart, posEnd }) => {
      const { boundaryStart, boundaryEnd } = getPositionBoundaries(text, /(?![а-яА-Я])(?:\W)/, posStart, posEnd);
      const { before, selected, after } = getTextParts(text, boundaryStart, boundaryEnd);
      if (before.endsWith('***') && after.startsWith('***')) {
        return {
          before: before.slice(0, -1),
          selected,
          after: after.slice(1),
        };
      }
      if (before.endsWith('**') && after.startsWith('**')) {
        return {
          before: `${before}*`,
          selected,
          after: `*${after}`,
        };
      }
      if (before.endsWith('*') && after.startsWith('*')) {
        return {
          before: before.slice(0, -1),
          selected,
          after: after.slice(1),
        };
      }
      if (selected.startsWith('***') && selected.endsWith('***')) {
        return {
          before,
          selected: selected.slice(1, -1),
          after,
        };
      }
      if (selected.startsWith('**') && selected.endsWith('**')) {
        return {
          before: `${before}*`,
          selected,
          after: `*${after}`,
        };
      }
      if (selected.startsWith('*') && selected.endsWith('*')) {
        return {
          before,
          selected: selected.slice(1, -1),
          after,
        };
      }
      return {
        before: `${before}*`,
        selected,
        after: `*${after}`,
      };
    },
  },
  {
    icon: 'list-ol',
    title: 'Нумерованный список',
    action: async ({ text, posStart, posEnd }) => {
      const { lines, boundaryStart, boundaryEnd } = getTextLinesWithBoundaries(text, posStart, posEnd);
      const { before, after } = getTextParts(text, boundaryStart, boundaryEnd);
      const isOl = lines.every((x) => /^\s*\d+\.\s/.test(x));
      const selected = lines.map((x, index) => (isOl ? x.slice(x.indexOf('.') + 2) : `${index + 1}. ${x}`)).join('\n');
      return {
        before,
        selected,
        after,
      };
    },
  },
  {
    icon: 'list-ul',
    title: 'Ненумерованный список',
    action: async ({ text, posStart, posEnd }) => {
      const { lines, boundaryStart, boundaryEnd } = getTextLinesWithBoundaries(text, posStart, posEnd);
      const { before, after } = getTextParts(text, boundaryStart, boundaryEnd);
      const isUl = lines.every((x) => /^\s*-\s/.test(x));
      const selected = lines.map((x) => (isUl ? x.slice(x.indexOf('-') + 2) : `- ${x}`)).join('\n');
      return {
        before,
        selected,
        after,
      };
    },
  },
  {
    icon: 'link',
    title: 'Ссылка',
    action: async ({ app, text, posStart, posEnd }) => {
      const { before, selected, after } = getTextParts(text, posStart, posEnd);
      const result = await app.showModal(MGmtLink, {
        url: isValidUrl(selected) ? selected : '',
        text: !isValidUrl(selected) ? selected : '',
      });
      return {
        before,
        selected: result || selected,
        after,
      };
    },
  },
  {
    icon: 'image',
    title: 'Картинка',
    action: async ({ app, text, posStart, posEnd }) => {
      const { before, selected, after } = getTextParts(text, posStart, posEnd);
      const result = await app.showModal(MGmtImage, { alt: selected });
      return {
        before,
        selected: result || selected,
        after,
      };
    },
  },
  {
    icon: 'video',
    title: 'Видео',
    action: async ({ app, text, posStart, posEnd }) => {
      const { before, selected, after } = getTextParts(text, posStart, posEnd);
      const result = await app.showModal(MPrompt, {
        title: 'Видео',
        buttonText: 'Добавить',
        label: 'URL',
        defaultValue: isValidUrl(selected) ? selected : '',
        isRequired: true,
        isUrl: true,
      });
      return {
        before,
        selected: result ? `[video | ${result}]` : selected,
        after,
      };
    },
  },
  {
    icon: 'table',
    title: 'Таблица',
    action: async ({ text, posStart }) => {
      const { before, after } = getTextParts(text, posStart, posStart);
      const result = '\n| Характеристика | Значение |\n| - | - |\n|  |  |\n';
      return {
        before: before + result.slice(0, -6),
        selected: '',
        after: result.slice(-6) + after,
      };
    },
  },
  {
    icon: 'address-card',
    title: 'Карточка',
    action: async ({ app, text, posStart }) => {
      const { before, after } = getTextParts(text, posStart, posStart);
      const result = await app.showModal(MGmtCard);
      if (!result) {
        return {
          before: before,
          selected: '',
          after: after,
        };
      }
      return {
        before: before + result.slice(0, -7),
        selected: '',
        after: result.slice(-8) + after,
      };
    },
  },
];

export class GmEditor extends React.Component<P> {
  refTextarea = React.createRef<HTMLTextAreaElement>();

  render() {
    const { className } = this.props;
    return (
      <div className={cn('GmEditor', className)}>
        {this.renderToolbar()}
        {this.renderContent()}
      </div>
    );
  }

  // event handlers

  onChange(text: string) {
    const { onChange } = this.props;
    onChange(text);
  }

  onPreview() {
    const { isPreview, onPreview } = this.props;
    onPreview(!isPreview);
  }

  async onAction(item: Tool) {
    const { app, text, onChange } = this.props;
    const textarea = this.refTextarea.current!;
    const posStart = textarea.selectionStart;
    const posEnd = textarea.selectionEnd;
    if (item.action) {
      const { before, selected, after } = await item.action({ app, text, posStart, posEnd });
      onChange(before + selected + after);
      const scrollTop = textarea.scrollTop;
      textarea.focus();
      textarea.setSelectionRange(before.length, before.length + selected.length);
      textarea.scrollTop = scrollTop;
    }
  }

  // render helpers

  renderToolbar() {
    return (
      <div className="GmEditor_toolbar">
        {TOOLS.map((x) => this.renderToolButton(x))}
        {this.renderPreviewButton()}
      </div>
    );
  }

  renderToolButton(item: Tool) {
    const { disabled, isPreview } = this.props;
    return (
      <button
        className="GmEditor_button"
        type="button"
        title={item.title}
        key={String(item.icon)}
        disabled={disabled || isPreview}
        onClick={() => this.onAction(item)}
      >
        <FontAwesomeIcon icon={item.icon} />
      </button>
    );
  }

  renderPreviewButton() {
    const { isPreview, disabled } = this.props;
    return (
      <button
        key="eye"
        type="button"
        title="Предпросмотр"
        disabled={disabled}
        className={cn('GmEditor_button', isPreview && '_active')}
        onClick={() => this.onPreview()}
      >
        <FontAwesomeIcon icon={isPreview ? 'eye-slash' : 'eye'} />
      </button>
    );
  }

  renderContent() {
    const { isPreview } = this.props;
    return isPreview ? this.renderPreview() : this.renderTextarea();
  }

  renderTextarea() {
    const { text, disabled } = this.props;
    return (
      <textarea
        ref={this.refTextarea}
        className="GmEditor_textarea"
        value={text}
        disabled={disabled}
        onChange={(e) => this.onChange(e.target.value)}
      />
    );
  }

  renderPreview() {
    const { text, app } = this.props;
    const html = {
      __html: renderGmToHtml(text, { getImageUrl: (name) => app.getImageUrl(name) }),
    };
    return <div className="GmEditor_preview" dangerouslySetInnerHTML={html} />;
  }
}
