import React from 'react';

import { App } from '../app';
import { cn } from '../lib/utils.lib';
import { formatBytes, formatFileName } from '../services/fmt.service';
import { Button } from './Button';
import { Image } from './ImageList';
import { MImageEdit } from './modals/MProductImage';

import './ImageListItem.css';

interface P {
  app: App;
  image: Image;
  index: number;
  className?: string;
  onDelete: (image: Image) => void;
  onEdit: (image: Image) => void;
  onSetPrimary: (image: Image) => void;
  onDragStart: (image: Image) => void;
  onDragEnter: (image: Image) => void;
  onDragEnd: (image: Image) => void;
  onMouseOver: (image: Image) => void;
}

export class ImageListItem extends React.Component<P> {
  render() {
    const { className } = this.props;
    const imageClassName = cn('ImageListItem', 'position-relative', className);
    return (
      <div className={imageClassName}>
        {this.renderCard()}
        {this.renderButtons()}
      </div>
    );
  }

  // event handlers

  async onRename() {
    const { app, image, onEdit } = this.props;
    const newImage = await app.showModal(MImageEdit, { image });
    if (newImage) {
      onEdit(newImage);
    }
  }

  onDelete() {
    const { onDelete, image } = this.props;
    onDelete(image);
  }

  onSetPrimary() {
    const { onSetPrimary, image } = this.props;
    onSetPrimary(image);
  }

  onDragStart() {
    const { onDragStart, image } = this.props;
    onDragStart(image);
  }

  onDragEnter() {
    const { onDragEnter, image } = this.props;
    onDragEnter(image);
  }

  onDragEnd() {
    const { onDragEnd, image } = this.props;
    onDragEnd(image);
  }

  onMouseOver() {
    const { onMouseOver, image } = this.props;
    onMouseOver(image);
  }

  // render helpers

  renderCard() {
    const { image } = this.props;
    return (
      <div
        className={cn('card', image.is_primary && 'border-primary')}
        draggable
        onDragStart={() => this.onDragStart()}
        onDragEnter={() => this.onDragEnter()}
        onDragEnd={() => this.onDragEnd()}
        onMouseOver={() => this.onMouseOver()}
      >
        {this.renderImage()}
        {this.renderBody()}
      </div>
    );
  }

  renderImage() {
    const { app, image } = this.props;
    const url = app.getImageUrl(image.media_name);
    return <img src={url} alt="" draggable={false} />;
  }

  renderBody() {
    const { image } = this.props;
    return (
      <div className="card-body p-2 text-center small">
        <strong>
          {image.dimensions}
          {' · '}
          {formatBytes(image.media_size)}
        </strong>
        <p className="card-text mb-0 text-truncate">{formatFileName(image.media_name, image.alt || 'image')}</p>
        <p className={cn('card-text mb-0 text-truncate', !image.caption && 'text-black-50')}>
          {image.caption || 'Не заполнено'}
        </p>
      </div>
    );
  }

  renderButtons() {
    const { image } = this.props;
    return (
      <div className="position-absolute ImageListItem_buttons">
        <Button type="primary" disabled={image.is_primary} icon="check" onClick={() => this.onSetPrimary()} />
        <Button type="success" icon="pen" onClick={() => this.onRename()} />
        <Button type="danger" icon="trash" onClick={() => this.onDelete()} />
      </div>
    );
  }
}
