import React from 'react';

import { App } from '../../app';
import { debounce } from '../../lib/utils.lib';
import { SearchObjectType, SearchResultItem } from '../../sdk/search.sdk';
import { List } from '../List';
import { MArticle } from '../modals/MArticle';
import { MArticleCategory } from '../modals/MArticleCategory';
import { MNews } from '../modals/MNews';
import { MProduct } from '../modals/MProduct';
import { MProductCategory } from '../modals/MProductCategory';
import { MProductSubcategory } from '../modals/MProductSubcategory';
import { MReview } from '../modals/MReview';
import { MStaticPage } from '../modals/MStaticPage';

interface P {
  app: App;
}

interface S {
  items: SearchResultItem[];
  searchQuery: string;
}

export class PSearch extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      searchQuery: '',
    };
  }

  render() {
    return (
      <div className="PSearch">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Поиск');
    app.updateUI();
  }

  // event handlers

  onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ searchQuery: e.target.value });
    if (e.target.value.length < 2) {
      this.setState({ items: [] });
      return;
    }
    this.search();
  }

  async onItemSelect(searchResultItem: SearchResultItem) {
    await this.runPrimaryAction(searchResultItem);
    this.search();
  }

  // render helpers

  renderTop() {
    const { searchQuery } = this.state;
    return (
      <div className="d-flex mb-3">
        <input
          className="form-control"
          type="text"
          value={searchQuery}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items } = this.state;
    return <List columns={this.getColumns()} items={items} onItemSelect={(item) => this.onItemSelect(item)} />;
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'Тип',
        headClassName: 'w-250px',
        value: (item: SearchResultItem) => this.getObjectTypeName(item.object_type),
      },
      {
        name: 'Название',
        value: (item: SearchResultItem) => item.object_title,
      },
    ];
  }

  async runPrimaryAction(searchResultItem: SearchResultItem) {
    const { app } = this.props;
    const sdk = app.getSdk();
    try {
      switch (searchResultItem.object_type) {
        case SearchObjectType.article: {
          const article = await sdk.getArticle(searchResultItem.object_id);
          const categories = await sdk.getArticleCategories();
          await app.showModal(MArticle, { article, categories });
          break;
        }
        case SearchObjectType.articleCategory: {
          const category = await sdk.getArticleCategory(searchResultItem.object_id);
          await app.showModal(MArticleCategory, { category });
          break;
        }
        case SearchObjectType.news: {
          const newsItem = await sdk.getNewsItem(searchResultItem.object_id);
          await app.showModal(MNews, { newsItem });
          break;
        }
        case SearchObjectType.product: {
          const product = await sdk.getProduct(searchResultItem.object_id);
          const subcategory = await sdk.getProductSubcategory(product.subcategory_id);
          await app.showModal(MProduct, { subcategory, product });
          break;
        }
        case SearchObjectType.productCategory: {
          const category = await sdk.getProductCategory(searchResultItem.object_id);
          await app.showModal(MProductCategory, { category });
          break;
        }
        case SearchObjectType.productSubcategory: {
          const subcategory = await sdk.getProductSubcategory(searchResultItem.object_id);
          const categories = await sdk.getProductCategories();
          const productTabs = await sdk.getProductTabs();
          await app.showModal(MProductSubcategory, {
            categoryId: subcategory.id,
            categories,
            productTabs,
            subcategory,
          });
          break;
        }
        case SearchObjectType.review: {
          const review = await sdk.getReview(searchResultItem.object_id);
          await app.showModal(MReview, { review });
          break;
        }
        case SearchObjectType.staticPage: {
          const staticPage = await app.getSdk().getStaticPage(searchResultItem.object_id);
          await app.showModal(MStaticPage, { staticPage });
          break;
        }
        default:
          return null;
      }
    } catch (err) {
      app.handleError(err);
    }
  }

  search = debounce(400, async () => {
    const { app } = this.props;
    const { searchQuery } = this.state;
    try {
      const items = await app.getSdk().getSearchResults(searchQuery);
      this.setState({ items });
    } catch (err) {
      app.handleError(err);
    }
  });

  getObjectTypeName(type: SearchObjectType) {
    switch (type) {
      case SearchObjectType.article:
        return 'Статья';
      case SearchObjectType.articleCategory:
        return 'Категория статей';
      case SearchObjectType.news:
        return 'Новость';
      case SearchObjectType.product:
        return 'Товар';
      case SearchObjectType.productCategory:
        return 'Категория товаров';
      case SearchObjectType.productSubcategory:
        return 'Подкатегория товаров';
      case SearchObjectType.review:
        return 'Отзыв';
      case SearchObjectType.staticPage:
        return 'Статическая страница';
      default:
        return '?';
    }
  }
}
