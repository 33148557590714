import React from 'react';
import { cn } from '../lib/utils.lib';

interface P {
  items: { value: string; title: string }[];
  value: string;
  disabled?: boolean;
  className?: string;
  onChange?: (value: string) => void;
}

export class Select extends React.Component<P> {
  render() {
    const { value, items, className, disabled } = this.props;
    return (
      <select
        className={cn(className, 'form-control')}
        value={value}
        disabled={disabled}
        onChange={(e) => this.onChange(e)}
      >
        {items.map((option) => (
          <option key={option.value} value={option.value}>
            {option.title}
          </option>
        ))}
      </select>
    );
  }

  // event handlers

  onChange(e: any) {
    const { onChange } = this.props;
    const value = e.target.value;
    if (onChange) {
      onChange(value);
    }
  }
}
