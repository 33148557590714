import React from 'react';

interface P {
  title: string;
  close: () => void;
}

export class ModalHeader extends React.Component<P> {
  render() {
    const { title, close } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }
}
