import React from 'react';

import { App } from '../../app';
import { config } from '../../config';
import { Review, ReviewStatus } from '../../sdk/review.sdk';
import { filterItemsByPeriod, getPeriodFilterOptions } from '../../services/filter.service';
import { formatDate, formatReviewStatus } from '../../services/fmt.service';
import { List } from '../List';
import { MReview } from '../modals/MReview';
import { Select } from '../Select';

interface P {
  app: App;
}

interface S {
  items: Review[];
  search: string;
  dateFilter: string;
  statusFilter: string;
  page: number;
}

export class PRerviews extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      search: '',
      dateFilter: 'all',
      statusFilter: 'all',
      page: 1,
    };
  }

  render() {
    return (
      <div className="PReviews">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Заявки', undefined, config.tabs.requests);
    app.updateUI();
    await this.refreshData();
  }

  // event handlers

  onSearchChange(e: any) {
    this.setState({ search: e.target.value, page: 1 });
  }

  onDateFilterChange(value: string) {
    this.setState({ dateFilter: value, page: 1 });
  }

  onStatusFilterChange(value: string) {
    this.setState({ statusFilter: value, page: 1 });
  }

  onGetItemMenu(review: Review) {
    const { app } = this.props;
    return [
      {
        name: 'Открыть',
        action: () => this.runPrimaryAction(review),
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить отзыв # ${review.id}?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await app.getSdk().deleteReview(review.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(review: Review) {
    await this.runPrimaryAction(review);
  }

  onPageChange(page: number) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <input
          className="form-control"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
        {this.renderStatusFilter()}
        {this.renderDateFilter()}
      </div>
    );
  }

  renderStatusFilter() {
    return (
      <Select
        items={this.getStatusFilterOptions()}
        className="ml-3"
        value={this.state.statusFilter}
        onChange={(value) => this.onStatusFilterChange(value)}
      />
    );
  }

  renderDateFilter() {
    const { items } = this.state;
    return (
      <Select
        items={getPeriodFilterOptions(items, 'date')}
        className="ml-3"
        value={this.state.dateFilter}
        onChange={(value) => this.onDateFilterChange(value)}
      />
    );
  }

  renderList() {
    const { items, search, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={this.getFilteredItems(items)}
        search={search}
        pageNumber={page}
        pageSize={config.itemsPerPage}
        onGetItemMenu={(item) => this.onGetItemMenu(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(x) => this.onPageChange(x)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: '#',
        value: (item: Review) => item.id,
        headClassName: 'w-100px',
      },
      {
        name: 'Дата',
        value: (item: Review) => formatDate(item.date),
        headClassName: 'w-150px',
      },
      {
        name: 'Статус',
        value: (item: Review) => formatReviewStatus(item.status),
        headClassName: 'w-150px',
      },
      {
        name: 'Рейтинг',
        value: (item: Review) => item.rating,
        headClassName: 'w-150px',
      },
      {
        name: 'Продукт',
        value: (item: Review) => item.product_subcategory_name,
      },
    ];
  }

  getStatusFilterOptions() {
    return [
      { value: 'all', title: 'Все статусы' },
      { value: ReviewStatus.new, title: 'Новые' },
      { value: ReviewStatus.published, title: 'Опубликованные' },
    ];
  }

  getFilteredItems(items: Review[]) {
    const { dateFilter } = this.state;
    let result = items;
    result = filterItemsByPeriod(dateFilter, items, 'date');
    result = this.getStatusFilteredItems(result);
    return result;
  }

  getStatusFilteredItems(items: Review[]) {
    if (this.state.statusFilter === 'all') {
      return items;
    }
    return items.filter((item) => item.status === this.state.statusFilter);
  }

  async runPrimaryAction(review: Review) {
    const { app } = this.props;
    await app.showModal(MReview, { review });
    await this.refreshData();
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const items = await app.getSdk().getReviews();
      this.setState({ items });
    } catch (err) {
      app.handleError(err);
    }
  }
}
