import React from 'react';

import { App } from '../../app';
import { config } from '../../config';
import { Redirect } from '../../sdk/redirect.sdk';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

interface P {
  app: App;
  redirect?: Redirect;
  close: (redirect?: Redirect) => void;
}

interface S {
  isLoading: boolean;
  form: Form;
}

export class MRedirect extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.redirect),
    };
  }

  render() {
    return (
      <div className="MRedirect modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    let newForm = form;
    const urlFrom = form.getValue('url_from');
    const urlTo = form.getValue('url_to');
    newForm = newForm.setValue('url_from', urlFrom.replace(config.front, ''));
    newForm = newForm.setValue('url_to', urlTo.replace(config.front, ''));
    this.setState({ form: newForm });
  }

  async onSave() {
    const { app, close, redirect } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('url_from');
    form = form.validateRequired('url_to');
    form = form.validateUrlPath('url_from');
    form = form.validateUrl('url_to');
    if (form.hasError()) {
      this.setState({ form });
      alert(config.messages.invalidForm);
      return;
    }
    if (form.getValue('url_from') === form.getValue('url_to')) {
      alert('Ошибка. Введены одинаковые URL.');
      return;
    }
    this.setState({ isLoading: true });
    try {
      const sdk = app.getSdk();
      const pd = this.getPostData(form);
      const freshRedirect = await (redirect ? sdk.updateRedirect(redirect.id, pd) : sdk.createRedirect(pd));
      close(freshRedirect);
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, redirect } = this.props;
    const title = redirect ? `Редирект` : 'Новый редирект';
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { redirect } = this.props;
    const { form } = this.state;
    return (
      <div className="modal-body">
        <FormGroup
          type="text"
          name="url_from"
          label="Откуда"
          required
          disabled={Boolean(redirect)}
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
        <FormGroup type="text" name="url_to" label="Куда" required form={form} onChange={(x) => this.onFormChange(x)} />
        <FormGroup
          type="select"
          name="status"
          label="Статус"
          form={form}
          options={this.getStatusOptions()}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(redirect?: Redirect) {
    if (!redirect) {
      return createForm({ status: '301' });
    }
    return createForm({
      url_from: redirect.url_from,
      url_to: redirect.url_to,
      status: String(redirect.status),
    });
  }

  getStatusOptions() {
    return [
      { value: '301', title: '301 Moved Permanently' },
      { value: '302', title: '302 Found' },
    ];
  }

  getPostData(form: Form) {
    return {
      url_from: form.getValue('url_from'),
      url_to: form.getValue('url_to'),
      status: form.getNumericValue('status')!,
    };
  }
}
