import React from 'react';
import { App } from '../../app';
import { config } from '../../config';
import { getImageDimensions, resetFileInput } from '../../lib/utils.lib';
import { createForm, Form } from '../../services/form.service';
import { checkImageDimensions } from '../../services/validate.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

interface P {
  app: App;
  close: (result?: string) => void;
}

interface S {
  isUploading: boolean;
  image?: File;
  form: Form;
}

export class MGmtCard extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isUploading: false,
      form: createForm(),
    };
  }

  render() {
    return (
      <div className="MGmtCard modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onFileChange(e: any) {
    const image = e.target.files[0] as File;
    const imageDimension = await getImageDimensions(image);
    const error = checkImageDimensions(imageDimension, config.validImageDimensions.gmt);
    if (error) {
      resetFileInput('MGmtCard_fileInput');
      alert(error);
      return;
    }
    this.setState({ image });
  }

  async onAdd() {
    const { app, close } = this.props;
    const { image, form } = this.state;
    this.setState({ isUploading: true });
    try {
      const title = form.getValue('title') || '-';
      if (image) {
        const { name } = await app.getSdk().uploadMedia(image);
        return close(`[card | ${title} | ${name}]\n[/card]`);
      }
      close(`[card | ${title}]\n[/card]`);
    } catch (err) {
      this.setState({ isUploading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    return <ModalHeader title="Карточка" close={close} />;
  }

  renderBody() {
    const { isUploading, image, form } = this.state;
    return (
      <div className="modal-body">
        <div className="form-group">
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="MGmtCard_fileInput"
              accept="image/*"
              disabled={isUploading}
              onChange={(e) => this.onFileChange(e)}
            />
            <label className="custom-file-label" htmlFor="MGmtCard_fileInput">
              {image ? image.name : 'Загрузить изображение'}
            </label>
          </div>
        </div>
        <FormGroup
          type="text"
          name="title"
          label="Заголовок"
          form={form}
          disabled={isUploading}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isUploading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isUploading} onClick={() => close()} />
        <Button type="success" text="Добавить" disabled={isUploading} onClick={() => this.onAdd()} />
      </div>
    );
  }
}
