import { Api } from '../services/api.service';

export interface Redirect {
  id: number;
  url_from: string;
  url_to: string;
  status: number;
  created_at: string;
}

export interface RedirectSdk {
  getRedirects(): Promise<Redirect[]>;
  getRedirect(id: number): Promise<Redirect>;
  createRedirect(data: CreateRedirectData): Promise<Redirect>;
  updateRedirect(id: number, data: UpdateRedirectData): Promise<Redirect>;
  deleteRedirect(id: number): Promise<void>;
}

interface CreateRedirectData {
  url_from: string;
  url_to: string;
  status: number;
}

interface UpdateRedirectData {
  url_to?: string;
  status?: number;
}

export function createRedirectSdk(api: Api): RedirectSdk {
  return {
    async getRedirects() {
      const data = await api.get('/redirect');
      return data.redirects;
    },
    async getRedirect(id) {
      const data = await api.get(`/redirect/${id}`);
      return data.redirect;
    },
    async createRedirect(data) {
      const result = await api.post('/redirect', data);
      return result.redirect;
    },
    async updateRedirect(id, data) {
      const result = await api.patch(`/redirect/${id}`, data);
      return result.redirect;
    },
    async deleteRedirect(id) {
      await api.delete(`/redirect/${id}`);
    },
  };
}
