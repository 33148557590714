export interface ImageDimensions {
  width: number;
  height: number;
}

export function esc(input: string) {
  let output = input;
  output = replace(output, '&', '&amp;');
  output = replace(output, '<', '&lt;');
  output = replace(output, '>', '&gt;');
  output = replace(output, '"', '&quot;');
  output = replace(output, "'", '&#39;');
  output = replace(output, '`', '&#96;');
  return output;
}

export function generateSlug(value: string) {
  return value
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-|-$/g, '');
}

export function getImageDimensions(file: File) {
  return new Promise<ImageDimensions>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result as string;
      image.onload = () => {
        resolve({ width: image.width, height: image.height });
      };
    };
  });
}

export function cn(...parts: any[]) {
  return parts.filter((p) => p).join(' ');
}

export function pluralize(value: number, w1: string, w2: string, w5: string, appendNumber = true) {
  const v1 = value % 10; // 123 % 10 = 3
  const v2 = value % 100; // 123 % 100 = 23
  let w = w2;
  if (v1 >= 5 || v1 === 0 || (v2 >= 11 && v2 <= 19)) {
    w = w5;
  } else if (v1 === 1) {
    w = w1;
  }
  return appendNumber ? `${value} ${w}` : w;
}

export function replace(str: string, search: string, replacement: string) {
  return str.split(search).join(replacement);
}

export function resetFileInput(id: string) {
  const fileInput = document.getElementById(id) as HTMLInputElement;
  fileInput.value = '';
}

export function sleep(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function debounce(wait: number, func: () => void) {
  let timeoutId: NodeJS.Timeout | undefined;
  return () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(func, wait);
  };
}
