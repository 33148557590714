import React from 'react';
import { App } from '../../app';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

interface P {
  app: App;
  alt: string;
  close: (result?: string) => void;
}

interface S {
  isUploading: boolean;
  image?: File;
  form: Form;
}

export class MGmtImage extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isUploading: false,
      form: createForm({ alt: props.alt }),
    };
  }

  render() {
    return (
      <div className="MGmtImage modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  onFileChange(e: any) {
    const image = e.target.files[0] as File;
    this.setState({ image });
  }

  async onAdd() {
    const { app, close } = this.props;
    const { image, form } = this.state;
    if (!image) {
      alert('Выберите изображение');
      return;
    }
    this.setState({ isUploading: true });
    try {
      const { name } = await app.getSdk().uploadMedia(image);
      const alt = form.getValue('alt');
      close(alt ? `[image | ${name} | ${alt}]` : `[image | ${name}]`);
    } catch (err) {
      this.setState({ isUploading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    return <ModalHeader title="Изображение" close={close} />;
  }

  renderBody() {
    const { isUploading, image, form } = this.state;
    return (
      <div className="modal-body">
        <div className="form-group">
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="MGmtImage_fileInput"
              accept="image/*"
              disabled={isUploading}
              onChange={(e) => this.onFileChange(e)}
            />
            <label className="custom-file-label" htmlFor="MGmtImage_fileInput">
              {image ? image.name : 'Загрузить изображение'}
            </label>
          </div>
        </div>
        <FormGroup
          type="text"
          name="alt"
          label="Название (ALT)"
          form={form}
          disabled={isUploading}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isUploading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isUploading} onClick={() => close()} />
        <Button type="success" text="Добавить" disabled={isUploading} onClick={() => this.onAdd()} />
      </div>
    );
  }
}
