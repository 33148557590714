/* eslint-disable no-useless-computed-key */

const RU_EN: { [key: string]: string } = {
  ['а']: 'a',
  ['б']: 'b',
  ['в']: 'v',
  ['г']: 'g',
  ['д']: 'd',
  ['е']: 'e',
  ['ё']: 'e',
  ['ж']: 'zh',
  ['з']: 'z',
  ['и']: 'i',
  ['й']: 'i',
  ['к']: 'k',
  ['л']: 'l',
  ['м']: 'm',
  ['н']: 'n',
  ['о']: 'o',
  ['п']: 'p',
  ['р']: 'r',
  ['с']: 's',
  ['т']: 't',
  ['у']: 'u',
  ['ф']: 'f',
  ['х']: 'kh',
  ['ц']: 'ts',
  ['ч']: 'ch',
  ['ш']: 'sh',
  ['щ']: 'shch',
  ['ъ']: 'ie',
  ['ы']: 'y',
  ['ь']: '',
  ['э']: 'e',
  ['ю']: 'iu',
  ['я']: 'ia',
};

export function translit(value: string) {
  const arr = [];
  for (const char of value) {
    const newChar = translitChar(char);
    arr.push(newChar);
  }
  return arr.join('');
}

// private

function translitChar(char: string) {
  if (RU_EN[char] !== undefined) {
    return RU_EN[char];
  }
  if (RU_EN[char.toLowerCase()] !== undefined) {
    return RU_EN[char.toLowerCase()].replace(/./, (x) => x.toUpperCase());
  }
  return char;
}
