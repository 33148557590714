import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { App } from '../app';
import { cn } from '../lib/utils.lib';
import { ImageListItem } from './ImageListItem';

import './ImageList.css';

interface P {
  images: Image[];
  app: App;
  isUploading: boolean;
  onChange: (images: Image[]) => void;
  onAdd: (files: FileList) => void;
}

interface S {
  isDrag: boolean;
}

export interface Image {
  alt: string;
  caption: string;
  dimensions: string;
  media_id: number;
  media_name: string;
  media_hash: string;
  media_size: number;
  is_primary: boolean;
}

export class ImageList extends React.Component<P, S> {
  dragImage?: Image;

  constructor(props: P) {
    super(props);
    this.state = {
      isDrag: false,
    };
  }

  render() {
    return <div className="ImageList">{this.renderList()}</div>;
  }

  // event handlers

  async onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { onAdd } = this.props;
    const files = e.target.files;
    if (!files) {
      return;
    }
    onAdd(files);
  }

  onDragStart(image: Image) {
    this.dragImage = image;
    this.setState({ isDrag: true });
  }

  async onDragEnd() {
    this.dragImage = undefined;
  }

  onDragEnter(image: Image) {
    const { images, onChange } = this.props;
    if (!this.dragImage) {
      return;
    }
    const indexTo = images.indexOf(image);
    const indexFrom = images.indexOf(this.dragImage);
    if (indexTo === -1 || indexFrom === -1) {
      return;
    }
    const newList = [...images];
    const movedImage = newList.splice(indexFrom, 1)[0];
    newList.splice(indexTo, 0, movedImage);
    onChange(newList);
  }

  onMouseOver() {
    const { isDrag } = this.state;
    if (!this.dragImage && isDrag) {
      this.setState({ isDrag: false });
    }
  }

  onDelete(image: Image) {
    const { images, onChange } = this.props;
    const newList = images.filter((x) => x.media_id !== image.media_id);
    if (image.is_primary && newList.length > 0) {
      newList[0].is_primary = true;
    }
    onChange(newList);
  }

  onEdit(image: Image) {
    const { images, onChange } = this.props;
    const newList = [...images];
    const imageWithNewName = newList.find((x) => x.media_id === image.media_id);
    if (imageWithNewName) {
      imageWithNewName.alt = image.alt;
      imageWithNewName.caption = image.caption;
    }
    onChange(newList);
  }

  onSetPrimary(image: Image) {
    const { images, onChange } = this.props;
    const newList = [...images];
    newList.forEach((x) => (x.is_primary = x.media_id === image.media_id));
    onChange(newList);
  }

  // render helpers

  renderList() {
    const { isUploading } = this.props;
    const { isDrag } = this.state;
    return (
      <div className={cn('ImageList_container', isDrag && '_drag')}>
        {this.renderImages()}
        {isUploading ? this.renderLoad() : this.renderAdd()}
      </div>
    );
  }

  renderImages() {
    const { images } = this.props;
    return images.map((item, index) => this.renderImage(item, index));
  }

  renderImage(image: Image, index: number) {
    const { app } = this.props;
    return (
      <ImageListItem
        app={app}
        className="ImageList_item"
        key={image.media_name}
        index={index}
        image={image}
        onDelete={(x) => this.onDelete(x)}
        onEdit={(item) => this.onEdit(item)}
        onSetPrimary={(x) => this.onSetPrimary(x)}
        onDragStart={(x) => this.onDragStart(x)}
        onDragEnter={(x) => this.onDragEnter(x)}
        onDragEnd={() => this.onDragEnd()}
        onMouseOver={() => this.onMouseOver()}
      />
    );
  }

  renderLoad() {
    return (
      <div className="card ImageList_item">
        <div className="card-body ImageList_spinner">
          <div className="spinner-border text-primary" />
        </div>
      </div>
    );
  }

  renderAdd() {
    return (
      <div className="card ImageList_item">
        <div className="card-body">
          <label className="ImageList_input" htmlFor="imageInput">
            <input type="file" className="d-none" id="imageInput" multiple onChange={(e) => this.onFileChange(e)} />
            <FontAwesomeIcon icon="plus" size="3x" />
          </label>
        </div>
      </div>
    );
  }
}
