import { Api } from '../services/api.service';

export interface Media {
  id: number;
  name: string;
  hash: string;
}

export interface MediaSdk {
  uploadMedia(file: File): Promise<Media>;
}

export function createMediaSdk(api: Api): MediaSdk {
  return {
    async uploadMedia(file) {
      const { id, hash, name } = await api.upload('/media', file);
      return { id, hash, name };
    },
  };
}
