import React from 'react';

import { App } from '../../app';
import { config } from '../../config';
import { pluralize } from '../../lib/utils.lib';
import { DeliverySource } from '../../sdk/delivery.sdk';
import { Settings } from '../../sdk/settings.sdk';
import { formatList, formatMoney } from '../../services/fmt.service';
import { clearWebCache } from '../../services/webcache.service';
import { Button } from '../Button';
import { InfoBlock, LabelValue } from '../InfoBlock';
import { MSettings } from '../modals/MSettings';

interface P {
  app: App;
}

interface S {
  settings: Settings | null;
}

export class PSettings extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      settings: null,
    };
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Настройки', undefined, config.tabs.settings);
    app.updateUI();
    await this.refreshData();
  }

  render() {
    const { settings } = this.state;
    if (!settings) {
      return null;
    }
    return (
      <div className="PSettings">
        {this.renderList()}
        {this.renderBottom()}
      </div>
    );
  }

  // event handlers

  async onEdit() {
    const { app } = this.props;
    const { settings } = this.state;
    await app.showModal(MSettings, { settings });
    await this.refreshData();
  }

  async onClearCache() {
    const { app } = this.props;
    const sdk = app.getSdk();
    try {
      const [size] = await Promise.all([clearWebCache(), sdk.invalidateExternalReviews()]);
      const pl1 = pluralize(size, 'удалён', 'удалено', 'удалено', false);
      const pl2 = pluralize(size, 'объект', 'объекта', 'объектов');
      alert(size ? `Кэш очищен, ${pl1} ${pl2}` : 'Кэш был пуст');
    } catch (err) {
      app.handleError(err);
    }
  }

  // render helpers

  renderList() {
    return (
      <React.Fragment>
        <InfoBlock title="Доставка" items={this.getRowsDelivery()} />
        <InfoBlock title="Уведомления" items={this.getRowsEmails()} />
        <InfoBlock title="Прочее" items={this.getRowsOther()} />
      </React.Fragment>
    );
  }

  renderBottom() {
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Редактировать" onClick={() => this.onEdit()} />
        <Button className="ml-2" type="secondary" text="Очистить кэш" onClick={() => this.onClearCache()} />
      </div>
    );
  }

  // other helpers

  getRowsDelivery(): LabelValue[] {
    const { settings } = this.state;
    if (!settings) {
      return [];
    }
    const values = settings.values;
    return [
      { label: 'Стоимость срочной  доставки', value: formatMoney(values.delivery_express_cost, false, true) },
      { label: 'Срок срочной доставки ', value: values.delivery_express_time },
      { label: 'Стоимость доставки по Москве', value: formatMoney(values.delivery_mc_cost, false, true) },
      { label: 'Срок доставки по Москве', value: values.delivery_mc_time },
      {
        label: 'Сумма заказа для бесплатной доставки по Москве',
        value: formatMoney(values.delivery_mc_free_from, false, true),
      },
      { label: 'Срок доставки по МО', value: values.delivery_mo_time },
      {
        label: 'Сумма заказа для бесплатной доставки по МО',
        value: formatMoney(values.delivery_mo_free_from, false, true),
      },
      { label: 'Калькулятор доставки по Роcсии', value: this.getDeliverySourceTitle(values.delivery_source) },
    ];
  }

  getRowsEmails(): LabelValue[] {
    const { settings } = this.state;
    if (!settings) {
      return [];
    }
    return [
      { label: 'Email для заказов', value: formatList(settings.values.emails_ord) },
      { label: 'Email для КП', value: formatList(settings.values.emails_cp) },
      { label: 'Email для заказов обратного звонка', value: formatList(settings.values.emails_callback) },
      { label: 'Email для новых отзывов', value: formatList(settings.values.emails_review) },
    ];
  }

  getRowsOther(): LabelValue[] {
    const { settings } = this.state;
    if (!settings) {
      return [];
    }
    return [
      { label: 'Минимальная сумма заказа', value: formatMoney(settings.values.ord_min_product_cost, false, true) },
      { label: 'Акционная страница', value: settings.values.sale_page_title },
      { label: 'Яндекс.Маркет', url: `${config.front}/yml.xml` },
      { label: 'Гугл.Маркет', url: `${config.front}/gmerchant.xml` },
    ];
  }

  getDeliverySourceTitle(value: string) {
    switch (value) {
      case DeliverySource.gr:
        return 'Гравировщик';
      case DeliverySource.c6v:
        return 'С6V';
      case DeliverySource.v3:
        return 'V3';
    }
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const settings = await app.getSdk().getSettings();
      this.setState({ settings });
    } catch (err) {
      app.handleError(err);
    }
  }
}
