import React from 'react';
import { App } from '../../app';
import { createForm, Form } from '../../services/form.service';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { Review, ReviewStatus } from '../../sdk/review.sdk';
import { Button } from '../Button';
import { config } from '../../config';

interface P {
  app: App;
  review: Review;
  close: (review?: Review) => void;
}

interface S {
  form: Form;
  isLoading: boolean;
}

export class MReview extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.review),
    };
  }

  render() {
    return (
      <div className="MReview modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  async onChangeStatus(status: ReviewStatus) {
    const { app, review, close } = this.props;
    try {
      const sdk = app.getSdk();
      const freshReview = await sdk.updateReview(review.id, { status });
      close(freshReview);
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onSave() {
    const { app, review, close } = this.props;
    let { form } = this.state;
    form = form.resetErrors();
    form = form.trimValues();
    form = form.validateRequired('name');
    form = form.validateRequired('text');
    form = form.validateRequired('date');
    form = form.validateDate('date');
    form = form.validateInteger('rating', 1, 5);
    if (form.hasError()) {
      this.setState({ form });
      alert(config.messages.invalidForm);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const sdk = app.getSdk();
      const pd = this.getPostData(form);
      const freshReview = await sdk.updateReview(review.id, pd);
      close(freshReview);
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, review } = this.props;
    const title = `Отзыв # ${review.id} – ${review.product_subcategory_name}`;
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form } = this.state;
    return (
      <div className="modal-body">
        <div className="row">
          <FormGroup
            className="col-6"
            type="date"
            name="date"
            label="Дата"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col-6"
            type="select"
            name="rating"
            label="Рейтинг"
            form={form}
            options={[1, 2, 3, 4, 5].map((x) => ({ value: String(x), title: String(x) }))}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <FormGroup type="text" name="name" label="Имя" form={form} onChange={(x) => this.onFormChange(x)} />
        <FormGroup type="textarea" name="text" label="Текст" form={form} onChange={(x) => this.onFormChange(x)} />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer d-flex justify-content-between">
        <Button
          disabled={isLoading}
          type={this.isPublished() ? 'warning' : 'primary'}
          text={this.isPublished() ? 'Снять с публикации' : 'Опубликовать'}
          onClick={() => this.onChangeStatus(this.isPublished() ? ReviewStatus.new : ReviewStatus.published)}
        />
        <div className="">
          <Button className="mr-2" type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
          <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
        </div>
      </div>
    );
  }

  // other helpers

  isPublished() {
    const { review } = this.props;
    return review.status === ReviewStatus.published;
  }

  getPostData(form: Form) {
    return {
      date: form.getValue('date'),
      name: form.getValue('name'),
      text: form.getValue('text'),
      rating: form.getNumericValue('rating'),
      status: form.getValue('status') as ReviewStatus,
    };
  }

  initForm(review: Review) {
    return createForm({
      date: review.date,
      product: review.product_subcategory_name,
      name: review.name,
      text: review.text,
      rating: review.rating,
      status: review.status,
    });
  }
}
