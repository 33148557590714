import React from 'react';
import { App } from '../../app';
import { ProductSubcategory, Product, PriceType } from '../../sdk/product.sdk';
import { Button } from '../Button';
import { List } from '../List';
import { sleep } from '../../lib/utils.lib';
import { MProduct } from '../modals/MProduct';
import { formatMoney, formatExecutionTime, formatNumber } from '../../services/fmt.service';
import { config } from '../../config';

interface P {
  app: App;
  match: any;
}

interface S {
  items: Product[];
  subcategory?: ProductSubcategory;
  search: string;
}

export class PProducts extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      subcategory: props.app.loadFromCache('subcategory'),
      search: '',
    };
  }

  render() {
    return (
      <div className="PProducts">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    this.setupPage();
    await this.refreshData();
    await sleep();
    this.setupPage();
  }

  // event handlers

  async onAdd() {
    const { app } = this.props;
    const { subcategory } = this.state;
    if (!subcategory) {
      return;
    }
    await app.showModal(MProduct, { subcategory });
    await this.refreshData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(product: Product) {
    const { app } = this.props;
    return [
      {
        name: 'Редактировать',
        action: async () => this.runPrimaryAction(product),
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить товар "${product.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            const sdk = app.getSdk();
            await sdk.deleteProduct(product.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(product: Product) {
    await this.runPrimaryAction(product);
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        buttons={this.getMoveButtons()}
        onGetItemMenu={(item) => this.onGetItemMenu(item)}
        onGetItemClassName={(item) => this.getItemClassName(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
      />
    );
  }

  // other helpers

  getColumns() {
    const { subcategory } = this.state;
    if (!subcategory) {
      return [];
    }
    const columns = [];
    columns.push({
      name: 'Товар',
      value: (item: Product) => (
        <div className="List_cellFlex">
          {item.name}
          {Boolean(item.color_name) && (
            <span className="List_colorBox" style={{ backgroundColor: `#${item.color_value}` }} />
          )}
        </div>
      ),
    });
    if (subcategory.price_type === PriceType.by_qty) {
      for (const qty of this.getPriceScale(subcategory)) {
        if (this.hasPriceByQty(qty)) {
          columns.push({
            name: `${formatNumber(qty)} ${subcategory.unit}`,
            headClassName: 'w-100px',
            value: (item: Product) => formatMoney(item.prices_by_qty[qty], true, true),
          });
        }
      }
    } else if (subcategory.price_type === PriceType.by_execution_time) {
      for (const x of config.executionTime) {
        columns.push({
          name: formatExecutionTime(x),
          headClassName: 'w-150px',
          value: (item: Product) => formatMoney(item.prices_by_execution_time[x], true, true),
        });
      }
    } else {
      columns.push({
        name: 'Цена',
        headClassName: 'w-150px',
        value: (item: Product) => formatMoney(item.price, true, true),
      });
    }
    return columns;
  }

  hasPriceByQty(qty: number) {
    const { items } = this.state;
    for (const item of items) {
      if (item.prices_by_qty[qty]) {
        return true;
      }
    }
    return false;
  }

  getMoveButtons() {
    const { app } = this.props;
    const sdk = app.getSdk();
    return [
      {
        icon: 'arrow-up',
        action: async (product: Product) => {
          try {
            await sdk.moveProductUp(product.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
      {
        icon: 'arrow-down',
        action: async (product: Product) => {
          try {
            await sdk.moveProductDown(product.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  getItemClassName(product: Product) {
    return product.is_hidden ? 'text-black-50' : '';
  }

  async runPrimaryAction(product: Product) {
    const { app } = this.props;
    const { subcategory } = this.state;
    if (!subcategory) {
      return;
    }
    await app.showModal(MProduct, { product, subcategory });
    await this.refreshData();
  }

  getPriceScale(subcategory: ProductSubcategory) {
    return config.priceScales.find((x) => x.code === subcategory.price_scale)!.values;
  }

  getCategoryId() {
    const { match } = this.props;
    return Number(match.params.categoryId);
  }

  getSubcategoryId() {
    const { match } = this.props;
    return Number(match.params.subcategoryId);
  }

  setupPage() {
    const { app } = this.props;
    const { subcategory } = this.state;
    const pageTitle = subcategory ? subcategory.name : '';
    const categoryId = this.getCategoryId();
    const url = `/catalog/${categoryId}/subcategories`;
    app.setupPage(pageTitle, url);
    app.updateUI();
  }

  async refreshData() {
    const { app } = this.props;
    const subcategoryId = this.getSubcategoryId();
    const sdk = app.getSdk();
    try {
      const items = await sdk.getProducts({ subcategory_id: subcategoryId });
      const subcategory = await sdk.getProductSubcategory(subcategoryId);
      this.setState({ items, subcategory });
    } catch (err) {
      app.handleError(err);
    }
  }
}
