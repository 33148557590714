import React from 'react';

import { App } from '../../app';
import { config } from '../../config';
import { Redirect } from '../../sdk/redirect.sdk';
import { formatDate } from '../../services/fmt.service';
import { Button } from '../Button';
import { List } from '../List';
import { MRedirect } from '../modals/MRedirect';

interface P {
  app: App;
}

interface S {
  items: Redirect[];
  search: string;
  page: number;
}

export class PRedirects extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: [],
      search: '',
      page: 1,
    };
  }

  render() {
    return (
      <div className="PRedirects">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('Настройки', undefined, config.tabs.settings);
    app.updateUI();
    await this.refreshData();
  }

  // event handlers

  async onAdd() {
    const { app } = this.props;
    await app.showModal(MRedirect);
    await this.refreshData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value, page: 1 });
  }

  onGetItemMenu(redirect: Redirect) {
    const { app } = this.props;
    return [
      {
        name: 'Редактировать',
        action: () => this.runPrimaryAction(redirect),
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить редирект для "${redirect.url_from}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            const sdk = app.getSdk();
            await sdk.deleteRedirect(redirect.id);
            await this.refreshData();
          } catch (err) {
            app.handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(redirect: Redirect) {
    await this.runPrimaryAction(redirect);
  }

  onPageChange(page: number) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        pageNumber={page}
        pageSize={config.itemsPerPage}
        onGetItemMenu={(item) => this.onGetItemMenu(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(x) => this.onPageChange(x)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'Дата',
        value: (item: Redirect) => formatDate(item.created_at),
        headClassName: 'w-120px',
      },
      {
        name: 'Статус',
        value: (item: Redirect) => item.status,
        headClassName: 'w-120px',
      },
      {
        name: 'Откуда',
        value: (item: Redirect) => item.url_from,
      },
      {
        name: 'Куда',
        value: (item: Redirect) => item.url_to,
      },
    ];
  }

  async runPrimaryAction(redirect: Redirect) {
    const { app } = this.props;
    await app.showModal(MRedirect, { redirect });
    await this.refreshData();
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const items = await app.getSdk().getRedirects();
      this.setState({ items });
    } catch (err) {
      app.handleError(err);
    }
  }
}
