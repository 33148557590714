import React from 'react';
import { App } from '../../app';
import { config } from '../../config';
import { City, Region } from '../../sdk/delivery.sdk';
import { isMo } from '../../services/delivery.service';
import { formatMoney, formatRegion } from '../../services/fmt.service';
import { Form, createForm } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

interface P {
  app: App;
  region: Region;
  city?: City;
  close: (city?: City) => void;
}

interface S {
  isLoading: boolean;
  form: Form;
}

export class MCity extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.city),
    };
  }

  render() {
    return (
      <div className="MCity modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onSave() {
    const { app, close, city } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('name');
    form = form.validateMoney('delivery_cost');
    if (form.hasError()) {
      this.setState({ form });
      alert(config.messages.invalidForm);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const sdk = app.getSdk();
      const pd = this.getPostData(form);
      const freshCity = await (city ? sdk.updateCity(city.id, pd) : sdk.createCity(pd));
      close(freshCity);
    } catch (err) {
      this.setState({ isLoading: false });
      app.handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, city } = this.props;
    const title = city ? city.name : 'Новый город';
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form } = this.state;
    const { region } = this.props;
    return (
      <div className="modal-body">
        <FormGroup type="select" name="region" label="Регион" disabled form={form} options={this.getRegionOptions()} />
        <FormGroup
          type="text"
          name="name"
          label="Название"
          required
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
        {isMo(region) && (
          <FormGroup
            type="text"
            name="delivery_cost"
            label="Стоимость доставки, ₽"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        )}
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(city?: City) {
    const { region } = this.props;
    if (!city) {
      return createForm({ region });
    }
    return createForm({
      name: city.name,
      region: city.region,
      delivery_cost: formatMoney(city.delivery_cost),
    });
  }

  getRegionOptions() {
    return [
      { value: Region.mo, title: formatRegion(Region.mo) },
      { value: Region.ru, title: formatRegion(Region.ru) },
      { value: Region.kz, title: formatRegion(Region.kz) },
    ];
  }

  getPostData(form: Form) {
    return {
      name: form.getValue('name'),
      region: form.getValue('region') as Region,
      delivery_cost: form.getMoneyValue('delivery_cost') || null,
    };
  }
}
