import { addDays, format, getMonth, isSameDay, isSameMonth, startOfMonth, startOfWeek } from 'date-fns';
import React from 'react';

import { cn } from '../lib/utils.lib';
import { Holiday } from '../sdk/holiday.sdk';
import { formatMonth } from '../services/fmt.service';

import './HolidayCalendar.css';

interface P {
  className?: string;
  date: Date;
  holidays: Holiday[];
  onChange?: (day: Date) => void;
}

export class HolidayCalendar extends React.Component<P> {
  render() {
    const { className } = this.props;
    const holidayCalendarClassName = cn(className, 'HolidayCalendar');
    return (
      <div className={holidayCalendarClassName}>
        <table className="table table-bordered table-sm text-center bg-light">
          {this.renderTableHead()}
          {this.renderTableBody()}
        </table>
      </div>
    );
  }

  // event handlers

  async onDayClick(day: Date) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(day);
    }
  }

  // render helpers

  renderTableHead() {
    const { date } = this.props;
    return (
      <thead>
        <tr>
          <th colSpan={7}>{formatMonth(getMonth(date) + 1)}</th>
        </tr>
        <tr>
          <th className="font-weight-normal text-secondary">Пн</th>
          <th className="font-weight-normal text-secondary">Вт</th>
          <th className="font-weight-normal text-secondary">Ср</th>
          <th className="font-weight-normal text-secondary">Чт</th>
          <th className="font-weight-normal text-secondary">Пт</th>
          <th className="font-weight-normal text-secondary">Сб</th>
          <th className="font-weight-normal text-secondary">Вс</th>
        </tr>
      </thead>
    );
  }

  renderTableBody() {
    const weeks = this.getWeeks();
    return (
      <tbody>
        {weeks.map((x, index) => (
          <tr key={index}>{x.map((x) => this.renderDayCell(x))}</tr>
        ))}
      </tbody>
    );
  }

  renderDayCell(day: Date) {
    const { date } = this.props;
    if (!isSameMonth(day, date)) {
      return (
        <td className="_empty" key={day.toString()}>
          {format(day, 'd')}
        </td>
      );
    }
    const isToday = this.isToday(day);
    const isHoliday = this.isHoliday(day);
    const className = cn(isHoliday && 'text-danger', (isHoliday || isToday) && 'font-weight-bold');
    return (
      <td key={day.toString()} className={className} onClick={() => this.onDayClick(day)}>
        {format(day, 'd')}
      </td>
    );
  }

  // other helpers

  getWeeks() {
    const { date } = this.props;
    const weeks = [];
    const firstDay = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
    let currentDay = firstDay;
    let week = [];
    while (weeks.length < 6) {
      week.push(currentDay);
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
      currentDay = addDays(currentDay, 1);
    }
    return weeks;
  }

  isHoliday(day: Date) {
    const { holidays } = this.props;
    return holidays.some((x) => isSameDay(new Date(x.date), day));
  }

  isToday(day: Date) {
    return isSameDay(new Date(), day);
  }
}
