import { Api } from '../services/api.service';

export interface Review {
  id: number;
  product_subcategory_id: number;
  product_subcategory_name: string;
  date: string;
  rating: number;
  status: ReviewStatus;
  name: string;
  text: string;
}

export enum ReviewStatus {
  new = 'new',
  published = 'published',
}

interface ReviewQuery {
  status?: ReviewStatus;
  product_subcategory_id?: number;
}

interface CreateReviewData {
  product_subcategory_id: number;
  rating: number;
  name: string;
  text: string;
}

interface UpdateReviewData {
  date?: string;
  rating?: number;
  status?: ReviewStatus;
  name?: string;
  text?: string;
}

export interface ReviewSdk {
  getReviews(): Promise<Review[]>;
  getReview(id: number): Promise<Review>;
  createReview(data: CreateReviewData): Promise<Review>;
  updateReview(id: number, data: UpdateReviewData): Promise<Review>;
  deleteReview(id: number): Promise<void>;
}

export function createReviewSdk(api: Api): ReviewSdk {
  return {
    async getReviews(query?: ReviewQuery) {
      const data = await api.get('/review', query);
      return data.reviews;
    },
    async getReview(id) {
      const data = await api.get(`/review/${id}`);
      return data.review;
    },
    async createReview(data) {
      const result = await api.post('/review', data);
      return result.review;
    },
    async updateReview(id, data) {
      const result = await api.patch(`/review/${id}`, data);
      return result.review;
    },
    async deleteReview(id) {
      await api.delete(`/review/${id}`);
    },
  };
}
