import { format } from 'date-fns';
import { config } from '../config';
import { translit } from '../lib/translit.lib';
import { generateSlug } from '../lib/utils.lib';
import { Region } from '../sdk/delivery.sdk';
import { DeliveryType, ExecutionTime, PaymentStatus, PaymentType } from '../sdk/order.sdk';
import { PriceType } from '../sdk/product.sdk';
import { ReviewStatus } from '../sdk/review.sdk';

const DELIVERY_TYPE_MAP = {
  pickup: 'Самовывоз из офиса',
  express: 'Срочная доставка по Москве',
  mc: 'Доставка по Москве',
  mo: 'Доставка по Москве и Области',
  ru: 'Доставка по России',
  kz: 'Доставка в Казахстан',
};

const DELIVERY_TYPE_SHORT_MAP = {
  pickup: 'Самовывоз',
  express: 'Срочная',
  mc: 'По Москве',
  mo: 'Москва / Область',
  ru: 'По России',
  kz: 'В Казахстан',
};

const EXECUTION_TIME_MAP = {
  d3: '3 дня',
  d2: '2 дня',
  d1: '1 день',
  h1: '1 час',
};

const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const PAYMENT_STATUS_MAP = {
  review: 'На проверке',
  ready: 'Проверен',
  paid: 'Оплачен',
};

const PAYMENT_TYPE_MAP = {
  cash: 'Наличными при получении заказа',
  online: 'Картами Visa, MasterCard, МИР онлайн',
  transfer: 'Безнал (для юридических лиц)',
};

const PAYMENT_TYPE_SHORT_MAP = {
  cash: 'Наличными',
  online: 'Картой',
  transfer: 'Безнал',
};

const PRICE_TYPE_MAP = {
  simple: 'Фиксированная',
  by_qty: 'Зависит от количества',
  by_execution_time: 'Зависит от времени выполнения',
};

const REGION_MAP = {
  mc: 'Москва',
  mo: 'Москва и Область',
  ru: 'Россия',
  kz: 'Казахстан',
};

const REVIEW_STATUS = {
  new: 'Новый',
  published: 'Опубликован',
};

export function formatBytes(size: number) {
  if (size < 1024) {
    return `${size} B`;
  }
  if (size < Math.pow(1024, 2)) {
    const kBytes = size / 1024;
    return `${kBytes.toFixed(kBytes <= 100 ? 1 : 0)} KB`;
  }
  const mBytes = size / Math.pow(1024, 2);
  return `${mBytes.toFixed(mBytes <= 100 ? 1 : 0)} MB`;
}

export function formatDate(date: string | null | undefined) {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'dd.MM.yyyy');
}

export function formatDeliveryType(deliveryType: DeliveryType) {
  return DELIVERY_TYPE_MAP[deliveryType];
}

export function formatDeliveryTypeShort(deliveryType: DeliveryType) {
  return DELIVERY_TYPE_SHORT_MAP[deliveryType];
}

export function formatExecutionTime(executionTime: ExecutionTime) {
  return EXECUTION_TIME_MAP[executionTime];
}

export function formatFileName(mediaName: string, title: string) {
  const slug = generateSlug(translit(title));
  return `${slug}.${mediaName.split('.').pop()}`;
}

export function formatList(list: string[]) {
  return list.join(', ');
}

export function formatMoney(value: number | null | undefined, withKop = false, withSign = false) {
  if (value == null) {
    return '';
  }
  return formatNumber(value / 100, withKop ? 2 : 0) + (withSign ? ' ₽' : '');
}

export function formatMonth(month: number) {
  return MONTHS[month - 1];
}

export function formatNumber(value: number | null | undefined, fractionDigits = 0) {
  if (value == null) {
    return '';
  }
  const isNegative = value < 0;
  const absValue = Math.abs(value);
  const src = String(fractionDigits > 0 ? Math.trunc(absValue) : Math.round(absValue));
  let dst = '';
  const n = src.length;
  for (let i = 0; i < n; i += 1) {
    if (i % 3 === 0 && i !== 0) {
      dst = ' ' + dst;
    }
    dst = src.charAt(n - i - 1) + dst;
  }
  if (fractionDigits > 0) {
    const fraction = absValue - Math.trunc(absValue);
    dst += fraction.toFixed(fractionDigits).substr(1);
  }
  return isNegative ? `-${dst}` : dst;
}

export function formatPaymentStatus(paymentStatus: PaymentStatus | null) {
  if (!paymentStatus) {
    return '';
  }
  return PAYMENT_STATUS_MAP[paymentStatus];
}

export function formatPaymentType(paymentType: PaymentType | null) {
  if (!paymentType) {
    return '';
  }
  return PAYMENT_TYPE_MAP[paymentType];
}

export function formatPaymentTypeShort(paymentType: PaymentType | null) {
  if (!paymentType) {
    return '';
  }
  return PAYMENT_TYPE_SHORT_MAP[paymentType];
}

export function formatPhone(phone: string) {
  if (!phone) {
    return '';
  }
  return `+7 (${phone.substr(2, 3)}) ${phone.substr(5, 3)}-${phone.substr(8, 2)}-${phone.substr(10, 2)}`;
}

export function formatPriceType(type: PriceType) {
  return PRICE_TYPE_MAP[type];
}

export function formatRegion(region: Region) {
  return REGION_MAP[region];
}

export function formatReviewStatus(status: ReviewStatus) {
  return REVIEW_STATUS[status];
}

export function formatTime(date: string | null | undefined) {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'HH:mm');
}

export function formatUniqueConstraintError(constraint: string) {
  return config.uniqueConstraintErrors[constraint] || 'Нарушено условие уникальности';
}
