import React from 'react';
import { Form } from '../services/form.service';
import { cn } from '../lib/utils.lib';

import './FormGroup.css';

export interface Option {
  title: string;
  value: string;
  disabled?: boolean;
}

export interface Check {
  name: string;
  title: string;
  disabled?: boolean;
}

interface P {
  type: 'text' | 'password' | 'textarea' | 'select' | 'checks' | 'date' | 'time' | 'number' | 'color';
  name: string;
  label?: string;
  form: Form;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  monospace?: boolean;
  options?: Option[];
  checks?: Check[];
  min?: number | string;
  max?: number | string;
  onChange?: (form: Form) => void;
}

export class FormGroup extends React.Component<P> {
  render() {
    const { name, className } = this.props;
    const fgClassName = cn(className, 'form-group');
    return (
      <div className={fgClassName} key={name}>
        {this.renderControl()}
        {this.renderError()}
      </div>
    );
  }

  // event handlers

  onChange(name: string, value: string) {
    const { form, type, onChange } = this.props;
    if (!onChange) {
      return;
    }
    if (type === 'color' && value) {
      if (value.match(/^[0-9a-f]{1,6}$/i) === null) {
        return;
      }
      value = value.toLowerCase();
    }
    const newForm = form.setValue(name, value).resetError(name);
    onChange(newForm);
  }

  // render helpers

  renderControl() {
    switch (this.props.type) {
      case 'text':
      case 'number':
      case 'password':
      case 'date':
      case 'time':
        return this.renderInput();
      case 'textarea':
        return this.renderTextarea();
      case 'select':
        return this.renderSelect();
      case 'checks':
        return this.renderChecks();
      case 'color':
        return this.renderColor();
      default:
        return null;
    }
  }

  renderError() {
    const { form, name } = this.props;
    return form.hasError(name) && <div className="invalid-feedback">{form.getError(name)}</div>;
  }

  renderLabel() {
    const { label, required } = this.props;
    return <label htmlFor={this.getId()}>{required ? label + ' *' : label}</label>;
  }

  renderInput() {
    const { type, name, label, form, readOnly, disabled, placeholder, min, max } = this.props;
    const className = cn('form-control', form.hasError(name) ? 'is-invalid' : '');
    return (
      <React.Fragment>
        {label && this.renderLabel()}
        <input
          className={className}
          type={type}
          id={this.getId()}
          value={form.getValue(name)}
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
          min={min}
          max={max}
          autoComplete="off"
          onChange={(e) => this.onChange(name, e.target.value)}
        />
      </React.Fragment>
    );
  }

  renderTextarea() {
    const { name, form, label, readOnly, disabled, placeholder, rows, monospace } = this.props;
    const className = cn('form-control', form.hasError(name) && 'is-invalid', monospace && 'text-monospace');
    return (
      <React.Fragment>
        {label && this.renderLabel()}
        <textarea
          className={className}
          id={this.getId()}
          value={form.getValue(name)}
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
          rows={rows || 5}
          onChange={(e) => this.onChange(name, e.target.value)}
        />
      </React.Fragment>
    );
  }

  renderSelect() {
    const { name, form, label, disabled } = this.props;
    const className = cn('form-control', form.hasError(name) ? 'is-invalid' : '');
    return (
      <React.Fragment>
        {label && this.renderLabel()}
        <select
          className={className}
          id={this.getId()}
          value={form.getValue(name)}
          disabled={disabled}
          onChange={(e) => this.onChange(name, e.target.value)}
        >
          {this.renderSelectOptions()}
        </select>
      </React.Fragment>
    );
  }

  renderSelectOptions() {
    return (this.props.options || []).map((option) => (
      <option key={option.value} value={option.value} disabled={option.disabled}>
        {option.title}
      </option>
    ));
  }

  renderChecks() {
    const { checks, label } = this.props;
    return (
      <React.Fragment>
        {label && <div className="mb-2">{label}</div>}
        {(checks || []).map((x) => this.renderCheck(x))}
      </React.Fragment>
    );
  }

  renderCheck(check: Check) {
    const { name, form, disabled } = this.props;
    const fullName = `${name}_${check.name}`;
    const checked = form.getValue(fullName) === 'on';
    return (
      <div className="form-check" key={check.name}>
        <label className="form-check-label">
          <input
            className="form-check-input"
            type="checkbox"
            checked={checked}
            disabled={disabled || check.disabled}
            onChange={() => this.onChange(fullName, checked ? '' : 'on')}
          />
          {check.title}
        </label>
      </div>
    );
  }

  renderColor() {
    const { name, label, form, readOnly, disabled, placeholder } = this.props;
    const errorClassName = form.hasError(name) ? 'is-invalid' : '';
    const groupClassName = cn('input-group FormGroup_inputColor', errorClassName);
    const controlClassName = cn('form-control', errorClassName);
    const value = form.getValue(name);
    const colorIconColor = /^[0-9a-f]{6}$/i.test(value) ? value : 'ffffff';
    return (
      <React.Fragment>
        {label && this.renderLabel()}
        <div className={groupClassName}>
          <div className="input-group-prepend">
            <div className="input-group-text">#</div>
          </div>
          <div className="FormGroup_inputColorIcon" style={{ backgroundColor: `#${colorIconColor}` }} />
          <input
            className={controlClassName}
            type="text"
            id={this.getId()}
            value={value}
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={disabled}
            onChange={(e) => this.onChange(name, e.target.value)}
          />
        </div>
      </React.Fragment>
    );
  }

  // other helpers

  private getId() {
    return `fc_${this.props.name}`;
  }
}
