import { getMonth, getYear } from 'date-fns';
import { formatMonth } from './fmt.service';

export function getPeriodFilterOptions(items: any[], field: string) {
  const periods: { year: number; month: number }[] = [];
  for (const item of items) {
    const date = new Date(item[field]);
    const year = getYear(date);
    const month = getMonth(date) + 1;
    if (!periods.some((x) => x.year === year && x.month === month)) {
      periods.push({ year, month });
    }
  }
  const sortedValues = periods.sort((a, b) => b.year - a.year || b.month - a.month);
  const filterOptions = sortedValues.map((x) => ({
    value: `${x.year}/${x.month}`,
    title: `${formatMonth(x.month)} ${x.year}`,
  }));
  return [{ value: 'all', title: 'За всё время' }, ...filterOptions];
}

export function filterItemsByPeriod(filter: string, items: any[], field: string) {
  if (filter === 'all') {
    return items;
  }
  const [year, month] = filter.split('/').map(Number);
  return items.filter((item) => {
    const date = new Date(item[field]);
    return getYear(date) === year && getMonth(date) + 1 === month;
  });
}
